// import request from 'superagent'
import { v4 as uuidv4 } from 'uuid'
import amplitude from 'amplitude-js'

import { REPLII_VERSION } from '../../sdk'
import { setCookie, getCookie } from '../cookieHelper'

const HOST =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_EVENTS_HOST || 'https://events.replii.co'
    : 'http://localhost:3000'

const ANALYTICS_ID_KEY = 'rpl_aid'

// Store user id in a cookie
function storeAnalyticsId(id) {
  setCookie(ANALYTICS_ID_KEY, id)
}

function getOrGenerateAnonId() {
  // Fetch an existing analytics ID
  const aid = getCookie(ANALYTICS_ID_KEY)
  if (aid) return aid

  // generate a temporary analytics id
  return uuidv4()
}

function defaultProps() {
  return {
    url: window.location.href,
    path: window.location.pathname,
    user_agent: navigator.userAgentData,
    host: window.location.hostname,
    widget_version: REPLII_VERSION,
  }
}

export function track(name, userId, properties) {
  // let anonymousId = null
  // if (!userId) {
  //   anonymousId = getOrGenerateAnonId()
  // }

  // How to handle userId, aliasing, etc?
  // storeAnalyticsId(userId || anonymousId)

  // const data = {
  //   user_id: userId,
  //   anonymous_id: anonymousId,
  //   data: Object.assign(defaultProps(), properties || {}),
  // }

  const eventProperties = Object.assign(defaultProps(), properties || {})

  return amplitude.getInstance('replii').logEvent(name, eventProperties)
  // send the event to the serve
  // return request.post(HOST).send(data).accept('json').type('json').withCredentials()
}

export function trackWidgetShown(widget) {
  return track('Widget Shown', null, {
    widget_id: widget.id,
    widgetType: widget.widgetType,
  })
}
