import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import useOnClickOutside from 'react-cool-onclickoutside'
import { ChevronDown } from 'react-feather'

import { PurpleButton } from './Button'
import Disabled from '../shared/Disabled'

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.25);
    opacity: 0;
  }
`

const fadeIn = keyframes`
  to {
    transform: scale(1);
    opacity: 1;
  }

  from {
    transform: scale(0);
    opacity: 0;
  }
`

const ContextMenuWrap = styled.div`
  background-color: white;
  color: var(--color-purple);
  position: absolute;
  display: inline-block;
  top: 50px;
  left: 0px;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.2s ease;
  transition: visibility 0.1s ease;
  z-index: 100;
  border-radius: 4px;
  overflow: hidden;
  min-width: 160px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
`

const ContextItem = styled.li`
  font-size: 13px;
  font-weight: 600;
  padding: 1.3em 1em;
  display: grid;
  grid-template-columns: 14px 1fr;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  color: ${(props) =>
    props.warning ? 'var(--color-input-error-text)' : 'var(--color-purple)'};
  line-height: 1em;
  transition: all 0.5s;

  &:hover {
    background-color: ${(props) =>
      props.warning ? 'var(--color-input-error-text)' : 'var(--color-purple)'};
    color: white;

    svg {
      color: white;
    }
  }

  svg {
    color: ${(props) => (props.warning ? 'var(--color-input-error-text)' : '#7572E8')};
  }
`

export default function ContextMenu({ items, onClickItem, text = 'Actions', disabled }) {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useOnClickOutside(() => setShowMenu(false), { showMenu })

  const toggleMenu = (e) => {
    e.preventDefault()
    setShowMenu(!showMenu)
  }

  const clickedItem = (item, e) => {
    e.preventDefault()
    e.stopPropagation()
    onClickItem(item)
  }

  return (
    <div style={{ position: 'relative' }} onBlur={(e) => e.stopPropagation()}>
      <PurpleButton
        type="button"
        onClick={toggleMenu}
        style={{
          justifyContent: 'space-between',
          padding: '.6em 1.5em',
          fontWeight: 600,
          fontSize: 15,
        }}
      >
        <span style={{ marginRight: 10 }}>Actions</span>
        <ChevronDown style={{ marginRight: -6 }} />
      </PurpleButton>
      <ContextMenuWrap show={showMenu} ref={ref}>
        <Disabled disabled={disabled}>
          <ul style={{ padding: 0, margin: 0 }}>
            {items.map((item) => {
              const { name, title, Icon } = item
              return (
                <ContextItem
                  warning={name === 'delete'}
                  key={name}
                  onClick={clickedItem.bind(null, item)}
                >
                  <Icon size={14} />
                  <span>{title}</span>
                </ContextItem>
              )
            })}
          </ul>
        </Disabled>
      </ContextMenuWrap>
    </div>
  )
}
