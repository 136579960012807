import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'
import dayjs from 'dayjs'
import isempty from 'lodash.isempty'
import { ChevronDown } from 'react-feather'

import { SelectWrap } from '../SimpleSelect'
import DatePicker from './DatePicker'

import './DatePickerInput.scss'

const PLACEHOLDER = 'Pick a Date'

function DatePickerInput({ value, name, onChange, ...props }) {
  const [state, setState] = useState({ value, open: false })
  const { open } = state

  const toggleFieldOpen = () => setState((state) => ({ ...state, open: !state.open }))

  const handleChange = (e, value) => {
    setState((state) => ({ ...state, open: false, value }))
    onChange && onChange(e, { value, name })
  }

  const closePopup = () => setState((state) => ({ ...state, open: false }))

  const formattedValue = isempty(state.value)
    ? PLACEHOLDER
    : dayjs(state.value).format('MM/DD/YY')

  return (
    <div className="field date-picker-input">
      <Popup
        open={open}
        className="date-picker-popup"
        trigger={<Field onClick={toggleFieldOpen} name={name} value={formattedValue} />}
        content={<DatePicker onChange={handleChange} selectedDate={state.value} />}
        on="click"
        onClose={closePopup}
        position="bottom left"
        wide="very"
      />
    </div>
  )
}

DatePickerInput.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
}

const Field = ({ value, ...rest }) => {
  let textClasses = 'text'
  if (value === PLACEHOLDER) {
    textClasses += ' default'
  }
  return (
    <SelectWrap className="date-input" {...rest}>
      <div
        className={textClasses}
        style={{ padding: '15px', fontWeight: '600', lineHeight: '1em' }}
      >
        {value}
      </div>
      <ChevronDown
        style={{
          color: '#76787b',
          marginRight: 10,
          marginTop: 3,
          cursor: 'pointer',
          position: 'absolute',
          right: 0,
          top: 8,
        }}
      />
    </SelectWrap>
  )
}

export default DatePickerInput
