import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, FormField } from '../shared'
import { mutation } from '../../graphql/client'
import { SendTestMessage } from '../../graphql/queries/CampaignMessages'
import { ConnectedPhoneField } from './CampaignMessageForm'
import useLocalStorage from '../../hooks/useLocalStorage'
import { SuccessNotice } from '../shared/Notice'
import { SmallButton } from '../shared/Button'

export default function TestMessageForm({ onSent, campaignMessageId }) {
  const [lastTestNumber, setLastTestNumber] = useLocalStorage('lastTestNumber', null)
  const [showSuccess, setShowSuccess] = useState(false)

  const sendTestMessage = (values) => {
    mutation(SendTestMessage, { campaignMessageId, ...values })
      .then((res) => {
        setLastTestNumber(values.to)
        setShowSuccess(true)
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  return (
    <div
      style={{
        marginTop: '1em',
      }}
    >
      {showSuccess && (
        <SuccessNotice style={{ marginBottom: '1em' }}>
          <p>Message sent successfully.</p>
          <SmallButton text="Close" onClick={onSent} />
        </SuccessNotice>
      )}
      <Formik initialValues={{ to: lastTestNumber || '' }} onSubmit={sendTestMessage}>
        {(props) => {
          return (
            <Form>
              <p>Send a test message to a live number. </p>
              <FormField name="to" label="Test Number">
                <ConnectedPhoneField name="to" />
              </FormField>

              <Button text="Send Test Message" type="submit" />
              <p style={{ marginTop: '1em' }}>
                <em>Note: Messaging charges will be incurred.</em>
              </p>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
