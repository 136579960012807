import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkButton = styled(Link)`
  background-color: var(--color-primary);
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  padding: 10px 40px;
  border-radius: 6px;
  transition: 0.2s all;
  display: inline-block;

  &:hover {
    color: white;
    background-color: var(--color-primary-light);
  }

  &:active {
    color: white;
    background-color: var(--color-primary-dark);
  }
`

export default LinkButton
