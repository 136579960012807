import React from 'react'
import { Loader } from 'react-feather'
import styled from 'styled-components'

export const StyledButton = styled.button`
  letter-spacing: -0.02em;
  background-color: ${(props) =>
    props.color === 'secondary' ? 'var(--color-secondary)' : 'var(--color-primary)'};
  border: none;
  border-radius: 6px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 1em;
  outline: none;
  padding: 1em 2.5em;
  transition: 0.3s all;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:active {
    outline: none;
    background-color: ${(props) =>
      props.color === 'secondary' ? 'var(--color-secondary)' : 'var(--color-primary)'};
    filter: brightness(90%) hue-rotate(-2deg);
  }

  &:hover {
    background-color: ${(props) =>
      props.color === 'secondary' ? 'var(--color-secondary)' : 'var(--color-primary)'};
    filter: brightness(110%) hue-rotate(0deg);
  }

  &.full-width {
    width: 100%;
  }

  &.loading {
    opacity: 0.5;
  }

  &.loading:hover {
    cursor: not-allowed;
  }

  &.loading svg {
    line-height: 1em;
    font-size: 14px;

    @keyframes spinner {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: spinner 0.8s ease-in-out infinite;
  }
`

export const PurpleButton = styled(StyledButton)`
  background-color: #4f5ccc;
  &:hover {
    background-color: #4f5ccc;
    filter: brightness(125%) hue-rotate(0deg);
  }
`
const DEFAULT_ICON_PROPS = {
  style: { marginRight: 4 },
  size: 18,
}

export default function Button({
  text,
  icon,
  iconPosition = 'left',
  iconProps,
  loading,
  fullWidth,
  className,
  onClick,
  ...rest
}) {
  let classes = 'btn'
  if (loading) classes += ' loading'
  if (className) classes += ` ${className}`
  if (fullWidth) classes += ' full-width'
  let Icon = null

  if (icon) {
    Icon = require(`react-feather/dist/icons/${icon}`).default
  }

  const handleClick = (e) => {
    if (loading) {
      e.preventDefault()
      return
    }
    onClick && onClick(e)
  }

  return (
    <StyledButton onClick={handleClick} className={classes} {...rest}>
      {!loading && icon && iconPosition === 'left' && (
        <Icon {...DEFAULT_ICON_PROPS} {...iconProps} />
      )}
      {loading ? (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader size={18} style={{ marginRight: 10, marginLeft: -10 }} /> {text}
        </span>
      ) : (
        <span style={{ padding: '0 4px' }}>{text}</span>
      )}
      {!loading && icon && iconPosition === 'right' && <Icon {...iconProps} />}
    </StyledButton>
  )
}

export const FlatButton = styled(Button)`
  border-radius: 0;
`

export const UppercaseButton = styled(FlatButton)`
  width: 100%;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  font-size: 17px;
  text-transform: uppercase;
`

export const BlueButton = styled(Button)`
  background-color: #5292e8;
`

export const SmallButton = styled(Button)`
  padding: 0.6em 2em;
  font-size: 13px;
`
