import { gql } from 'apollo-boost'

export const CalculateShippingAmount = gql`
  mutation CalculateShippingAmount(
    $state: String!
    $zip: String!
    $orderId: ID!
    $customerId: ID!
  ) {
    calculateShippingAmount(
      input: { state: $state, zip: $zip, orderId: $orderId, customerId: $customerId }
    ) {
      shippingAmount
      totalAmount
      salesTaxAmount
    }
  }
`

export const UpdateCustomerAddress = gql`
  mutation UpdateCustomerAddress(
    $attributes: CustomerAddressInput!
    $id: ID
    $customerId: ID!
    $recipientId: ID!
  ) {
    updateCustomerAddress(
      input: {
        attributes: $attributes
        id: $id
        customerId: $customerId
        recipientId: $recipientId
      }
    ) {
      customerAddress {
        id
        street
        street2
        city
        state
        zip

        externalCustomer {
          id
        }
      }
    }
  }
`

export const UpdateCustomerData = gql`
  mutation UpdateCustomerData(
    $recipientId: ID!
    $campaignMessageId: ID
    $attributes: ExternalCustomerInput!
    $token: String
    $customerId: ID!
    $disclaimer: String
    $adultConsent: String
  ) {
    updateCustomerData(
      input: {
        recipientId: $recipientId
        campaignMessageId: $campaignMessageId
        attributes: $attributes
        token: $token
        customerId: $customerId
        disclaimer: $disclaimer
        adultConsent: $adultConsent
      }
    ) {
      externalCustomer {
        id
        customerAddresses {
          id
        }
      }
    }
  }
`

export const CustomerWidget = gql`
  query CustomerWidget($pathname: String) {
    widget(pathname: $pathname) {
      campaignListId
      bodyHtml
      defaultNumber
      imageUrl
      disclaimer
      listUniqueCode
      emailRequirement
    }
  }
`

export const CollectQuery = gql`
  query Collect($payload: String!, $recipientId: String, $campaignMessageId: String) {
    pendingOrder(
      payload: $payload
      recipientId: $recipientId
      campaignMessageId: $campaignMessageId
    ) {
      shippingTotal
      totalAmount
      subtotal
      quantity
      salesTaxAmount
      dynamicShipping
      id

      externalProduct {
        name
        amount
        id
      }
    }

    publicCampaignMessageId(payload: $payload)

    publicRecipient(id: $recipientId, payload: $payload) {
      publicId
      phone
      email
      name

      customer {
        stripeId
        name
        publicId
        adultConsentRequired
      }

      externalCustomer {
        id
        uid
        provider
        name
        email

        defaultPaymentSource {
          id
          last4
          expMonth
          expYear
          brand
        }

        defaultShippingAddress {
          id
          street
          street2
          city
          state
          zip
        }
      }
    }
  }
`
