import React from 'react'

export default function Flex({ children, style, ...rest }) {
  const mstyle = {
    display: 'flex',
    alignItems: 'center',
    ...style
  }
  return (
    <div style={mstyle} {...rest}>
      {children}
    </div>
  )
}
