import React from 'react'
import times from 'lodash.times'

/**
 * @param {Number} cols number of columns
 * @param {} style
 * @param {} children
 */
export default function Grid({ cols, rows, columnGap, style, children }) {
  const templateCols = cols && times(cols, () => '1fr').join(' ')
  const rowCols = rows && times(rows, () => '1fr').join(' ')

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: templateCols,
    gridColumnGap: columnGap,
    gridTemplateRows: rowCols,
    ...style,
  }

  return <div style={gridStyle}>{children}</div>
}
