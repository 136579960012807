import React from 'react'
import styled from 'styled-components'

const StatTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  white-space: nowrap;
  color: #1b2732;
  display: flex;
`

const StatNum = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: var(--color-purple);
`

const CardWrap = styled.div`
  background-color: #fafbff;
  padding: 1em;
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
`

export default function StatCard({ icon, title, num, subnum }) {
  const Icon = require(`react-feather/dist/icons/${icon}`).default
  return (
    <CardWrap>
      <div>
        {icon && (
          <div
            style={{
              backgroundColor: 'var(--color-purple)',
              padding: '1ch',
              display: 'inline-flex',
              placeContent: 'center',
              borderRadius: 4,
            }}
          >
            <Icon size={20} color="white" />
          </div>
        )}
      </div>
      <div style={{ display: 'grid', rowGap: 6 }}>
        <StatTitle>
          <span>{title}</span>
        </StatTitle>

        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <StatNum>{num}</StatNum>
          {subnum && (
            <div>
              <em>{subnum}</em>
            </div>
          )}
        </div>
      </div>
    </CardWrap>
  )
}
