import React from 'react'
import { PageHeader } from '../shared'

export default function DashboardOverview() {
  return (
    <div>
      <PageHeader
        text="Dashboard Overview"
        subheader="An overview of overall performance across all campaigns"
      />
    </div>
  )
}
