import { createContext, useContext } from 'react'

const AppContext = createContext({
  currentUser: null,
  setCurrentUser: () => {},
  lists: [],
  externalProducts: [],
  darkMode: false,
  integrations: [],
  customer: null,
  campaignSender: null,
  toggleDarkMode: () => {},
  toggleSidebar: () => {},
  expandedSidebar: true,
})

export function useAppContext() {
  return useContext(AppContext)
}

export default AppContext
