import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Clock } from 'react-feather'

import Select from '../Select'

const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
const HOURS = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

const EXP = /([0-9]+):([0-9]+) (AM|PM)/i

export default function TimeField({
  name,
  value,
  onChange,
  error,
  date = null,
  showLabel = true,
  filterPast = false,
  ...props
}) {
  const [state, setState] = useState({ value })
  const times = []

  const now = dayjs()
  HOURS.forEach((hour) => {
    MINUTES.forEach((min) => {
      const d = dayjs(date || undefined)
        .hour(hour)
        .minute(min)
        .second(0)

      if (date && filterPast) {
        if (d.isBefore(now)) return
      }
      times.push(d.format('hh:mm A'))
    })
  })

  const timeOptions = times.map((t) => ({ value: t, label: t, key: t }))

  const handleChange = ({ value }) => {
    if (!value) return
    const md = value.match(EXP)
    let hour = parseInt(md[1])
    const minute = parseInt(md[2])
    const ampm = md[3]

    if (ampm === 'PM') hour += 12
    setState({ value })
    onChange && onChange({ name, value, hour, minute, ampm })
  }

  return (
    <div className="time-field field">
      {showLabel && (
        <label>
          <Clock /> Time
        </label>
      )}
      <Select
        {...props}
        uncontrolled
        name="time"
        options={timeOptions}
        value={state.value}
        onChange={handleChange}
      />
    </div>
  )
}
