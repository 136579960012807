import React from 'react'
import styled from 'styled-components'
import { IconButton } from '../shared'
import { AnimatedLoader } from '../shared/Loader'

export const HeaderCell = styled.th`
  background: #e9eef2;
  border-bottom: 1px solid #b5c1cc;
  color: var(--color-primary);
  padding: 1em;
`

export const Table = styled.table`
  overflow: hidden;
  width: 100%;
`

export const TableCell = styled.td`
  color: #1e1e1e;
  padding: 0.8em 1em;

  &:last-child {
    border-bottom: none;
  }
`

export const TableWrap = styled.div`
  border: 1px solid #b5c1cc;
  border-radius: 6px;
  overflow: hidden;
`

export const PageButtonWrap = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #e3ecf2;
  border: 1px solid #8199ae;
  border-radius: 4px;
  margin-left: auto;

  svg {
    color: #556778;
  }

  button[disabled]:hover {
    background-color: transparent;
  }
`

export const TableRow = styled.tr`
  background-color: white;
  &:nth-child(odd) {
    background-color: #f6fbff;
  }
`

export const TablePreheader = styled.div`
  background: #e9eef2;
  color: #202930;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7em 1em;
  line-height: 1em;
  font-size: 13px;
  border-bottom: 1px solid #c1cdd8;
`

export function PaginatedTable({
  page,
  rows,
  onClickNext,
  onClickPrevious,
  headers,
  more,
  totalCount,
  loading,
  renderRow,
}) {
  return (
    <div>
      <TableWrap>
        <TablePreheader style={{ display: 'flex' }}>
          <div>
            {loading ? <AnimatedLoader /> : `Showing ${rows.length} of ${totalCount}`}
          </div>
          <PageButtonWrap>
            <IconButton
              name="chevron-left"
              onClick={onClickPrevious}
              disabled={page === 1}
            />
            <IconButton name="chevron-right" onClick={onClickNext} disabled={!more} />
          </PageButtonWrap>
        </TablePreheader>
        <Table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              {headers.map((header) => (
                <HeaderCell key={header}>{header}</HeaderCell>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <TableRow key={row.id}>
                {renderRow(row).map((r, j) => (
                  <TableCell key={`cell-${i}-${j}`}>{r}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrap>
    </div>
  )
}
