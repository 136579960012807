import React from 'react'
import { Loader as LoaderIcon } from 'react-feather'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

function Loader({ on = true }) {
  return <div>Loading...</div>
}

const rotation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`

const AnimatedLoaderWrap = styled.div`
  display: inline-block;
  animation: ${rotation} 1s ease-in-out infinite;
`

export const AnimatedLoader = () => {
  return (
    <AnimatedLoaderWrap>
      <LoaderIcon />
    </AnimatedLoaderWrap>
  )
}

Loader.propTypes = {
  on: PropTypes.bool,
}

export default Loader
