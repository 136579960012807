import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IconButton } from '.'

const NoticeWrap = styled.div`
  padding: 1em;
  background: rgba(34, 129, 198, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  color: #263540;
  line-height: 1.6em;
  display: flex;
  align-items: center;
`

export function InfoNotice({ children, icon, style, onClose, showClose }) {
  let Icon
  if (icon) {
    Icon = require(`react-feather/dist/icons/${icon}`).default
  }

  return (
    <NoticeWrap style={style}>
      {showClose && (
        <IconButton
          iconProps={{ size: 16 }}
          name="x-circle"
          onClick={onClose}
          style={{ position: 'absolute', right: 0 }}
        />
      )}
      {icon && <Icon size={24} style={{ marginRight: '1em' }} />}
      <div>{children}</div>
    </NoticeWrap>
  )
}

export function SuccessNotice({ children, style, showClose, onClose, ...rest }) {
  const istyle = {
    ...style,
    backgroundColor: '#99E3AE',
    border: '1px solid rgba(119,177,126)',
    color: 'rgb(32,82,46)',
    position: 'relative',
  }
  return (
    <InfoNotice style={istyle} onClose={onClose} {...rest}>
      {showClose && (
        <IconButton
          iconProps={{ size: 16 }}
          name="x-circle"
          onClick={onClose}
          style={{ position: 'absolute', right: 0 }}
        />
      )}
      {children}
    </InfoNotice>
  )
}

export default InfoNotice
