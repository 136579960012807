import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import dayjs from 'dayjs'

import DatePickerInput from './DatePickerInput'
import TimeField from './TimeField'
import FormNote from '../FormNote'
import Label from '../Label'
import { FormErrors } from '../FormField'

export default function DateTimeInput({
  name,
  label,
  note,
  value,
  filterPast = false,
  ...props
}) {
  const formik = useFormikContext()
  const [field, meta, helpers] = useField({ name, value, ...props })
  const richValue = field.value ? dayjs(field.value) : null
  const [touchedDate, setTouchedDate] = useState(!!richValue)
  const [touchedTime, setTouchedTime] = useState(!!richValue)
  const [datetime, setDatetime] = useState(richValue)

  const changeDate = (e, { value }) => {
    const existing = datetime || dayjs()
    const newDate = existing.date(value.date()).month(value.month()).year(value.year())
    setDatetime(newDate)
    setTouchedDate(true)
    helpers.setValue(newDate.toISOString(), touchedTime)
    touchedTime && formik.submitForm()
  }

  const changeTime = ({ value, hour, minute, ampm }) => {
    const existing = datetime || dayjs()
    const newDate = existing.hour(hour).minute(minute).second(0)
    setTouchedTime(true)
    helpers.setValue(newDate.toDate(), touchedDate)
    setDatetime(newDate)
    touchedDate && formik.submitForm()
  }

  return (
    <div className="field date-time-input">
      <Label>{label}</Label>
      <div className="field-row">
        <div
          className="field-wrap"
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '0.5em' }}
        >
          <DatePickerInput name="date" value={richValue} onChange={changeDate} />
          <TimeField
            name="time"
            value={field.value ? dayjs(field.value).format('hh:mm A') : undefined}
            onChange={changeTime}
            date={datetime}
            showLabel={false}
            filterPast={filterPast}
            placeholder="Pick a Time"
          />
        </div>
        {note && (
          <FormNote className="note" style={{ alignSelf: 'center' }}>
            {note}
          </FormNote>
        )}
      </div>
      {meta.error && <FormErrors>{meta.error}</FormErrors>}
    </div>
  )
}
