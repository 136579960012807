import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Sidebar from '../Nav/Sidebar'

import './DashboardHome.scss'
import IntegrationsHome from '../Integrations/IntegrationsHome'
import ContactsHome from '../Contacts/ContactsHome'
import ContactForm from '../Contacts/ContactForm'
import CampaignListHome from './CampaignList/CampaignListHome'
import CampaignListForm from './CampaignList/CampaignListForm'
import CampaignHome from './Campaign/CampaignHome'
import CampaignForm from './Campaign/CampaignForm'
import CampaignMessageForm from '../CampaignMessage/CampaignMessageForm'
import CampaignDashboard from './Campaign/CampaignDashboard'
import SettingsHome from '../Settings/SettingsHome'
import ShowCampaignList from './CampaignList/ShowCampaignList'
import MessagingSettings from '../Settings/MessagingSettings'
import DashboardOverview from './DashboardOverview'
import UserSettings from '../Settings/UserSettings'
import BillingSettings from '../Settings/BillingSettings'
import OrganizationSettings from '../Settings/OrganizationSettings'
import ViewCampaignMessage from '../CampaignMessage/ViewCampaignMessage'
import ListWidgets from './Widgets/ListWidgets'
import WidgetForm from './Widgets/WidgetForm'
import { useAppContext } from '../../contexts/app'
import IntegrationConfiguration from '../Integrations/IntegrationConfiguration'
import CommerceSettings from '../Settings/CommerceSettings'

export default function DashboardHome({ toggleDarkMode }) {
  const { expandedSidebar } = useAppContext()
  let classes = ['dashboard-home', !expandedSidebar && 'collapsed']
    .filter(Boolean)
    .join(' ')
  return (
    <div className={classes}>
      <Sidebar />
      <div className="main">
        <Switch>
          <Route exact path="/integrations" component={IntegrationsHome} />
          <Route exact path="/dashboard" component={DashboardOverview} />
          <Route exact path="/settings" component={SettingsHome} />
          <Route exact path="/settings/user" component={UserSettings} />
          <Route exact path="/contacts" component={ContactsHome} />
          <Route exact path="/settings/billing" component={BillingSettings} />
          <Route exact path="/settings/commerce" component={CommerceSettings} />
          <Route path="/integrations/:id/configure" component={IntegrationConfiguration} />
          <Route exact path="/settings/organization" component={OrganizationSettings} />
          <Route exact path="/widgets" component={ListWidgets} />
          <Route
            exact
            path={['/widgets/new', '/widgets/:id/edit']}
            component={WidgetForm}
          />
          <Route
            path={['/lists/:listId/contacts/:id/edit', '/lists/:listId/contacts/new']}
            component={ContactForm}
          />
          <Route
            exact
            path={['/lists/new', '/lists/:id/edit']}
            component={CampaignListForm}
          />
          <Route exact path="/lists/:id" component={ShowCampaignList} />
          <Route exact path="/lists" component={CampaignListHome} />
          <Route
            exact
            path={['/campaigns/new', '/campaigns/:id/edit']}
            component={CampaignForm}
          />
          <Route exact path="/campaigns" component={CampaignHome} />
          <Route exact path="/campaigns/:id" component={CampaignDashboard} />
          <Route
            exact
            path={[
              '/campaigns/:campaignId/messages/new',
              '/campaigns/:campaignId/messages/:id/edit',
            ]}
            component={CampaignMessageForm}
          />
          <Route
            path="/campaigns/:campaignId/messages/:id"
            component={ViewCampaignMessage}
          />
          <Route exact path="/settings/messaging" component={MessagingSettings} />
        </Switch>
      </div>
    </div>
  )
}
