import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { useQuery } from '../../graphql/client'
import phoneFrame from './phoneFrame.png'
import {gql} from "apollo-boost";

const PreviewWrap = styled.div`
  width: 375px;
  min-height: 750px;
  background-image: url(${phoneFrame});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 375px 750px;
  padding: 9em 2.4em 0 2.4em;

  .message-image {
    img {
      width: 80%;
      border-radius: 20px;
      max-width: 250px;
    }
  }

  .message-body {
    background-color: #e8e9eb;
    border-radius: 10px;
    color: black;
    font-size: 13px;
    padding: 0.8em 1em;
    margin-right: 4em;
  }
`

const MessageBodyWrap = styled.div`
  max-height: 505px;
  overflow-y: auto;
  display: grid;
  margin-top: 20px;
`

const TemplatePreviewQuery = gql`
query TemplatePreview($body: String!, $recipientId: ID) {
  templatePreview(body: $body, recipientId: $recipientId) {
    result
    errors {
      message
    }
  }
}
`

export default function MessagePreview({ body, imageUrl }) {
  const { loading, data, error } = useQuery(TemplatePreviewQuery, { body })
  if (loading) return "Loading..."
  const errMsg = data?.templatePreview?.errors?.message
  if (errMsg) {
    return <PreviewWrap className='message-preview'>
      {errMsg}
    </PreviewWrap>
    return "data errors"
  }
  if (error) return "Error!"

  const splitBody = data.templatePreview.result.split('\n')
  return (
    <PreviewWrap className="message-preview">
      <MessageBodyWrap>
        {imageUrl ? (
          <div className="message-image">
            <img src={imageUrl} />
          </div>
        ) : null}
        <div className="message-body">
          {splitBody.map((line, i) => (
            <p key={i}>{line}</p>
          ))}
          <p>Text STOP to unsubscribe.</p>
        </div>
      </MessageBodyWrap>
    </PreviewWrap>
  )
}
