import React from 'react'

import { dateString } from '../../lib/dateHelpers'

export default function LastSaved({ time, style, ...rest }) {
  if (!time) return null
  const s = {
    marginLeft: '1em',
    fontStyle: 'italic',
    color: 'rgba(0,0,0,.7)',
    ...style,
  }

  return (
    <span className="last-saved" style={s} {...rest}>
      Last saved at {dateString(time, { format: 'hh:mm:ss A' })}
    </span>
  )
}
