import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { BarChart, Edit } from 'react-feather'
import isempty from 'lodash.isempty'

import PhoneNumber from '../shared/PhoneNumber'
import DateTime from '../shared/DateTime'
import { Loader, PageHeader, StatCard, StatsBar } from '../shared'
import MessagePreview from './MessagePreview'
import {
  FetchDetailedCampaignMessage,
  SentMessages,
} from '../../graphql/queries/CampaignMessages'
import { useQuery, query } from '../../graphql/client'
import { money, percent } from '../../lib/utils'
import { PaginatedTable } from './PaginatedTable'
import SectionHeader from '../shared/SectionHeader'

export default function CampaignMessageLoader() {
  const { id } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page = parseInt(queryParams.get('page') || 1)

  const { data, loading } = useQuery(FetchDetailedCampaignMessage, {
    id,
    page,
  })

  if (loading) return <Loader />

  return <ViewCampaignMessage data={data} initialPage={page} />
}

const BluePanel = styled.div`
  background-color: #fafbff;
  border: 1px solid #ccd1e5;
  padding: 1.2em;
  display: inline-grid;
  row-gap: 0.6em;
  grid-template-columns: [label] minmax(auto, 100px) [value] auto;
`

const SimpleTable = ({ rows }) => {
  return (
    <div>
      <BluePanel>
        {rows.map((row, i) => {
          return (
            <React.Fragment key={`${row[0]}-${row[1]}`}>
              <div style={{ fontWeight: 700 }}>{row[0]}</div>
              <div>{row[1]}</div>
            </React.Fragment>
          )
        })}
      </BluePanel>
    </div>
  )
}

function ViewCampaignMessage({ data, initialPage }) {
  const history = useHistory()
  const { id } = useParams()
  const [page, setPage] = useState(initialPage)
  const [more, setMore] = useState(data.sentMessages.more)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState(data?.sentMessages?.data || [])

  const fetchNextPage = async (incBy) => {
    setLoading(true)
    const newPage = page + incBy
    const data = await query(SentMessages, { campaignMessageId: id, page: newPage })
    setPage(newPage)
    setMore(data.sentMessages.more)
    setMessages(data.sentMessages.data)
    setLoading(false)
    history.push({ pathname: history.location.pathname, search: `?page=${newPage}` })
  }

  const clickNext = () => {
    fetchNextPage(1)
  }

  const clickedPrevious = () => {
    fetchNextPage(-1)
  }

  const {
    numPurchases,
    purchaseRate,
    revenueGenerated,
    subscriberCount,
    linksClicked,
    clickThroughRate,
    unsubscribes,
    campaignId,
    unsubRate,
    status,
    campaignList,
    externalProduct,
    delivered,
    failed,
  } = data.campaignMessage

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 1fr) auto',
        columnGap: '2em',
      }}
    >
      <div
        style={{
          display: 'grid',
          rowGap: '2em',
          gridAutoRows: 'min-content',
        }}
      >
        <PageHeader
          icon={<BarChart />}
          style={{ marginBottom: 0 }}
          text={<Link to="/campaigns">Campaigns</Link>}
          breadcrumb="Campaign Message Dashboard"
        >
          <Link
            to={`/campaigns/${campaignId}/messages/${id}/edit`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Edit size={16} style={{ marginRight: 6 }} />
            Edit
          </Link>
        </PageHeader>
        <div>
          <SectionHeader
            icon="pie-chart"
            text="Deliverability Stats"
            style={{ marginBottom: '1em' }}
          />
          <StatsBar style={{ margin: 0 }} cols={3}>
            <StatCard
              icon="send"
              title="Messages Delivered"
              num={delivered}
              subnum={percent(delivered / subscriberCount)}
            />
            <StatCard
              icon="user-plus"
              title="Subscribers"
              num={subscriberCount}
              subnum=""
            />
            <StatCard
              icon="user-minus"
              title="Unsubscribes"
              num={unsubscribes}
              subnum={percent(unsubRate)}
            />
          </StatsBar>
        </div>
        <div>
          <SectionHeader
            icon="dollar-sign"
            text="Engagement Stats"
            style={{ marginBottom: '1em' }}
          />
          <StatsBar cols={3} style={{ margin: 0 }}>
            <StatCard
              icon="mouse-pointer"
              title="Links Clicked"
              num={linksClicked}
              subnum={percent(clickThroughRate)}
            />
            <StatCard
              icon="shopping-cart"
              title="Purchases Made"
              num={numPurchases}
              subnum={percent(purchaseRate)}
            />
            <StatCard
              icon="dollar-sign"
              title="Revenue Generated"
              num={money(revenueGenerated)}
              subnum=""
            />
          </StatsBar>
        </div>
        <div>
          <SectionHeader
            icon="list"
            text="Message Details"
            style={{ marginBottom: '1em' }}
          />
          <SimpleTable
            rows={[
              ['Status', status],
              ['List', <Link to={`/lists/${campaignList.id}`}>{campaignList.name}</Link>],
              ['Product', externalProduct?.name],
            ]}
          />
        </div>
        <div>
          <SectionHeader icon="send" text="Sent Messages" style={{ marginBottom: '1em' }} />

          <PaginatedTable
            headers={['Contact', 'Status', 'Sent At']}
            onClickNext={clickNext}
            onClickPrevious={clickedPrevious}
            rows={messages}
            totalCount={data.sentMessages.totalCount}
            more={more}
            page={page}
            loading={loading}
            renderRow={(row) => [
              <Link to={`/contacts/edit/${row.recipient.id}`}>
                {row?.recipient?.name || <PhoneNumber phone={row?.recipient?.phone} />}
              </Link>,
              <StatusLabel status={row.status} />,
              <DateTime date={row.createdAt} />,
            ]}
          />
        </div>
      </div>
      <div>
        {!isempty(data.campaignMessage.body) && <MessagePreview
          body={data.campaignMessage.body}
          imageUrl={data.campaignMessage.imageUrl}
        />}
      </div>
    </div>
  )
}

const BaseStatusLabel = styled.span`
  border-radius: 6px;
  font-size: 12px;
  padding: 0.5em 1em;
  color: white;
`

const PendingLabel = styled(BaseStatusLabel)`
  background-color: #f1df7f;
  color: #80701e;
`

const FailedLabel = styled(BaseStatusLabel)`
  background-color: #de5d5d;
`

const SuccessLabel = styled(BaseStatusLabel)`
  background-color: #4ec37d;
`

export function StatusLabel({ status }) {
  switch (status) {
    case 'pending':
      return <PendingLabel>{status}</PendingLabel>
    case 'delivered':
      return <SuccessLabel>{status}</SuccessLabel>
    case 'failed':
      return <FailedLabel>{status}</FailedLabel>
  }
}
