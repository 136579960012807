import { gql } from 'apollo-boost'

export const UpdateUser = gql`
  mutation UpdateUser($attributes: UserInput!) {
    updateUser(input: { attributes: $attributes }) {
      user {
        email
        password
      }
      errors
    }
  }
`
