import styled from 'styled-components'
import PropTypes from 'prop-types'

const Input = styled.input`
  background-color: #fafafa;
  border: ${(props) => (props.error ? '1px solid #a52f2f' : '1px solid #C9CDDA')};
  border-radius: 4px;
  box-shadow: none;
  color: #2d2d2d;
  display: block;
  font-family: Inter, sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1em;
  outline: none;
  padding: 0.7em 1em;
  transition: 0.5s all;
  width: 100%;
  box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0.31);

  &:active,
  &:focus {
  }

  &:focus {
    border: 1px solid #8fa7bd;
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px rgba(68, 155, 236, 0.31);
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #a8a8a8;
    opacity: 1;
  }
`

export default Input

Input.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
}
