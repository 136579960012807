import { gql } from 'apollo-boost'

export const ContactFragment = gql`
  fragment ContactFragment on Recipient {
    id
    name
    phone
    email

    externalCustomer {
      id
      name
      email

      customerAddresses {
        id
        active
        addressType
        street
        street2
        city
        state
        zip
        country
        useAsShipping
      }

      paymentSources {
        id
        last4
        provider
        expMonth
        expYear
        active
        brand
      }
    }
  }
`

export const FetchContacts = gql`
  query Contacts {
    contacts {
      id
      email
      phone
      confirmedAt
    }
  }
`

export const CreateRecipient = gql`
  mutation CreateRecipient(
    $attributes: RecipientInput!
    $listId: ID!
  ) {
    createRecipient(
      input: { attributes: $attributes, listId: $listId }
    ) {
      recipient {
        ...ContactFragment
      }
    }
  }
  ${ContactFragment}
`

export const UpdateRecipient = gql`
  mutation UpdateRecipient(
    $attributes: RecipientInput!
    $listId: ID!
    $id: ID!
  ) {
    updateRecipient(
      input: { attributes: $attributes, listId: $listId, id: $id }
    ) {
      recipient {
        ...ContactFragment
      }
    }
  }
  ${ContactFragment}
`

export const FetchContact = gql`
  query Contact($id: ID!) {
    contact(id: $id) {
      ...ContactFragment
    }
  }
  ${ContactFragment}
`
