import React from 'react'
import { Elements } from '@stripe/react-stripe-js'

import StripeForm from './StripeForm'

export default function PaymentForm({ stripe, recipient, ...rest }) {
  return (
    <div>
      <Elements stripe={stripe}>
        <StripeForm recipient={recipient} {...rest} />
      </Elements>
    </div>
  )
}
