import { gql } from 'apollo-boost'

export const CampaignFragment = gql`
  fragment CampaignFragment on Campaign {
    id
    name
    default
    campaignListId
  }
`

export const FetchCampaigns = gql`
  query Campaigns {
    campaigns {
      ...CampaignFragment
      campaignList {
        id
        name
        default
        recipientCount
      }
    }
    lists {
      id
      name
    }
  }
  ${CampaignFragment}
`

export const FetchCampaign = gql`
  query Campaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignFragment
    }
    lists {
      id
      name
    }
  }
  ${CampaignFragment}
`

export const FetchCampaignWithMessages = gql`
  query Campaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignFragment

      numPurchases
      revenueGenerated
      subscriberCount
      purchaseRate

      campaignMessages {
        id
        sendDate
        body
        imageUrl
        status
        externalProduct {
          id
          name
          amount
        }
      }
    }
  }
  ${CampaignFragment}
`

export const CreateCampaign = gql`
  mutation CreateCampaign($attributes: CampaignInput!) {
    createCampaign(input: { attributes: $attributes }) {
      campaign {
        ...CampaignFragment
      }
    }
  }
  ${CampaignFragment}
`

export const UpdateCampaign = gql`
  mutation UpdateCampaign($id: ID!, $attributes: CampaignInput!) {
    updateCampaign(input: { attributes: $attributes, id: $id }) {
      campaign {
        ...CampaignFragment
      }
    }
  }
  ${CampaignFragment}
`

export const DeleteCampaign = gql`
  mutation DeleteCampaign($id: ID!) {
    deleteCampaign(input: { id: $id }) {
      campaign {
        id
      }
    }
  }
`
