import React, { useState } from 'react'
import { useField } from 'formik'

import { TextArea } from '../shared/TextArea'
import SegmentCounter from '../shared/SegmentCounter'

/**
 * Drop in replacement for TextArea when the field is an SMS message body. Automatically adds segment count
 * @param  {} props
 */
export default function SMSBodyInput(props) {
  const [field, _, helpers] = useField(props.name)
  const [text, setText] = useState(field.value)

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const handleBlur = (e) => {
    e.preventDefault()
    helpers.setValue(text)
  }

  return (
    <TextArea
      {...props}
      {...field}
      value={text}
      onChange={handleChange}
      onBlur={handleBlur}
      belowNote={
        field.value && (
          <div style={{ marginTop: 10 }}>
            <SegmentCounter message={field.value} />
          </div>
        )
      }
    />
  )
}
