import React from 'react'
import styled from 'styled-components'

const Divider = styled.span`
  color: #d7d7d7;
  padding: 0 0.3em;
`

const Breadcrumb = styled.span`
  color: var(--color-steel-blue);
  font-weight: 700;
`

const Subheader = styled.div`
  color: #454545;
  font-size: 14px;
`

const Header = styled.h1`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  a {
    color: var(--color-dark-blue);
  }
`

const HeaderText = styled.div`
  color: #484848;
  p {
    line-height: 1.65em;
  }
`

export default function PageHeader({
  breadcrumb,
  text,
  icon,
  subheader,
  children,
  ...rest
}) {
  return (
    <div style={{ marginBottom: '1em' }} {...rest}>
      <Header>
        {icon && <span style={{ paddingRight: '.5em', lineHeight: '24px' }}>{icon}</span>}
        {text}
        {breadcrumb && (
          <>
            <Divider>/</Divider>
            <Breadcrumb>{breadcrumb}</Breadcrumb>
          </>
        )}
      </Header>
      {subheader && <Subheader>{subheader}</Subheader>}
      {children && <HeaderText>{children}</HeaderText>}
    </div>
  )
}
