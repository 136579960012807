import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter, useHistory } from 'react-router-dom'
import isemail from 'isemail'
import isempty from 'lodash.isempty'

import { useAppContext } from '../../contexts/app'
import Api from '../../lib/Api'
import { ReactComponent as Logo } from '../shared/logo.svg'
import { ShadowCard } from '../shared/Card'

import { Button, TextField } from '../shared'
import { query } from '../../graphql/client'
import { HomeQuery } from '../../graphql/queries/Home'

const LoginWrap = styled.div`
  width: 400px;
  margin: 0 auto;
  transform: translateY(30%);
`

function validateLogin({ email, password }) {
  const errors = {}
  if (isempty(email)) {
    errors.email = 'Required'
  } else if (!isemail.validate(email)) {
    errors.email = 'Invalid email address'
  }

  if (isempty(password)) errors.password = 'Required'

  return errors
}

function LoginHome() {
  const history = useHistory()
  const context = useAppContext()

  const fetchHome = async () => {
    const res = await query(HomeQuery)
    context.setAppContextData(res)
    return
  }

  const onSubmit = (values, formik) => {
    const { setSubmitting, setFieldError } = formik
    Api.Utils.post('/users/sign_in', { user: values })
      .then(({ body }) => {
        setSubmitting(false)
        fetchHome().then(() => history.replace('/'))
      })
      .catch((err) => {
        setSubmitting(false)
        if (err.response.status === 401) {
          const error = err.response.body.error
          if (error) {
            return setFieldError('email', error)
          }
        }
        setFieldError('email', 'An unexpected error occurred')
      })
  }

  return (
    <LoginWrap>
      <div style={{ textAlign: 'center' }}>
        <Logo style={{ marginBottom: 40 }} />
      </div>
      <ShadowCard style={{ paddingTop: '3em', paddingBottom: '3em' }}>
        <div className="login-cont">
          <p style={{ color: 'var(--color-primary)', fontWeight: 500, marginTop: 0 }}>
            Sign in to your account
          </p>
          <Formik
            onSubmit={onSubmit}
            validate={validateLogin}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ email: '', password: '' }}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="user@example.com"
                  autoFocus
                />
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  placeholder="••••••••"
                />
                <Button text="Sign In" type="submit" style={{ width: '100%' }} />
              </Form>
            )}
          </Formik>
        </div>
      </ShadowCard>
    </LoginWrap>
  )
}

export default withRouter(LoginHome)
