import React from 'react'
import styled from 'styled-components'

const SectionHeaderText = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 1em;
  margin: 0;
  color: #3a4c5e;
`

const SectionHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
`

const SectionHeaderIcon = styled.span`
  margin-right: 6px;
  display: flex;
  align-items: center;
  svg {
    stroke: var(--color-primary);
    strokewidth: 1px;
  }
`

export default function SectionHeader({ icon, text, ...rest }) {
  let Icon = null
  if (icon) {
    Icon = require(`react-feather/dist/icons/${icon}`).default
  }
  return (
    <SectionHeaderWrap {...rest}>
      {icon && (
        <SectionHeaderIcon>
          <Icon size={16} />
        </SectionHeaderIcon>
      )}
      <SectionHeaderText>{text}</SectionHeaderText>
    </SectionHeaderWrap>
  )
}
