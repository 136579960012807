import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { Link, useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Card,
  Loader,
  TextField,
  Button,
  Checkbox,
  SimpleSelect,
  FormField,
  PageHeader,
} from '../../shared'
import { useQuery, mutation } from '../../../graphql/client'
import {
  FetchCampaign,
  CreateCampaign,
  UpdateCampaign,
} from '../../../graphql/queries/Campaigns'
import AppContext from '../../../contexts/app'

const Schema = Yup.object().shape({
  campaignListId: Yup.number().positive('List is required'),
  name: Yup.string().required('Name is required'),
  default: Yup.boolean(),
})

export default function CampaignForm() {
  const { id } = useParams()

  if (id) return <EditForm id={id} />
  return <NewForm />
}

function EditForm({ id }) {
  const { loading, data } = useQuery(FetchCampaign, { id })
  if (loading) return <Loader />

  return <ListForm data={data.campaign} id={id} />
}

const NewForm = () => <ListForm />

function ListForm({ id, data = { name: '', default: false, campaignListId: null } }) {
  const history = useHistory()
  const { lists } = useContext(AppContext)

  const payload = (vals) => {
    return {
      name: vals.name,
      default: vals.default,
      campaignListId: vals.campaignListId,
    }
  }

  const onSubmit = (values, actions) => {
    const tpl = id ? UpdateCampaign : CreateCampaign
    mutation(tpl, { attributes: payload(values), id: parseInt(id) }).then(({ payload }) => {
      if (!id) {
        history.replace('/campaigns')
      }
    })
  }

  const editLabel = id ? 'Edit' : 'New'

  return (
    <div className="campaign-form">
      <PageHeader
        text={<Link to={`/campaigns`}>Campaigns</Link>}
        breadcrumb={id ? 'Edit Campaign' : 'New Campaign'}
      />
      <Card>
        <h1>{editLabel} Campaign</h1>
        <Formik
          onSubmit={onSubmit}
          initialValues={data}
          validationSchema={Schema}
          validateOnBlur
        >
          {(props) => {
            return (
              <Form>
                <TextField
                  name="name"
                  label="Campaign Name"
                  placeholder="Campaign Name"
                  note=" "
                />
                <Checkbox name="default" label="Default" />
                <FormField
                  name="campaignListId"
                  label="Default List"
                  note="The list messages in this campaign will send to."
                >
                  <SimpleSelect
                    name="campaignListId"
                    items={lists.map((list) => ({ value: list.id, text: list.name }))}
                  />
                </FormField>
                <Button
                  text="Save"
                  type="submit"
                  style={{ marginTop: '1em' }}
                  disabled={!props.isValid}
                />
              </Form>
            )
          }}
        </Formik>
      </Card>
    </div>
  )
}
