import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import { Check } from 'react-feather'
import styled from 'styled-components'

import { FormErrors } from './FormField'
import FormNote from '../shared/FormNote'
import Label from './Label'
import Disabled from '../shared/Disabled'

const BoxOutline = styled.div`
  width: 20px;
  height: 20px;
  background-color: #e5e8ec;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;

  &.checked {
    background-color: #5b7288;

    svg {
      color: white;
      width: 16px;
      height: 16px;
    }
  }
`

const CheckboxWrap = styled.div``

function Checkbox({
  onCheck,
  submitOnCheck,
  labelPosition = 'right',
  disabled,
  note,
  label,
  labelStyle,
  name,
  ...rest
}) {
  const [field, meta, helpers] = useField({ name, ...rest })
  const formik = useFormikContext()
  const [state, setState] = useState({
    checked: rest.checked === undefined ? field.value === true : rest.checked,
  })
  const { checked } = state

  const clickedCheckbox = (e) => {
    if (disabled) return

    const newVal = !checked

    setState({ checked: newVal })
    helpers.setValue(newVal)
    onCheck && onCheck(newVal, e)
    if (submitOnCheck) {
      formik.submitForm()
    }
  }

  let classes = 'replii-checkbox'
  if (checked) classes += ' checked'

  let rootClass = `checkbox-field ${labelPosition}`
  if (meta.error) {
    rootClass += ' error'
  }

  return (
    <Disabled disabled={disabled}>
      <CheckboxWrap className={rootClass} onClick={clickedCheckbox} {...rest}>
        <input type="hidden" name={name} value={checked} {...field} />
        <Label style={labelStyle}>
          {label && labelPosition === 'left' && <span>{label}</span>}
          <BoxOutline className={classes}>{checked && <Check />}</BoxOutline>
          {label && labelPosition === 'right' && <span>{label}</span>}
        </Label>
        {note && <FormNote className="note">{note}</FormNote>}
        {meta.error && <FormErrors>{meta.error}</FormErrors>}
      </CheckboxWrap>
    </Disabled>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  labelPosition: PropTypes.oneOf(['left', 'right']),
}

export default Checkbox
