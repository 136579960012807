/* eslint-disable global-require */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = styled.button`
  color: ${(props) => (props.purple ? '#5653E3' : 'black')};
  box-shadow: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 0em;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  display: flex;
  align-items: center;
  width: auto;
  transition: 0.3s all;

  svg {
    ${(props) => props.purple && `stroke: #5653e3;`}
  }

  &[disabled] {
    transition: none;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      color: black;
    }
  }

  &:active {
    outline: none;
    background-color: transparent;
  }

  &:hover,
  svg:hover {
    background-color: transparent;
    color: ${(props) => (props.purple ? '#7673EB' : 'rgba(0, 0, 0, 0.6)')};
    stroke: ${(props) => (props.purple ? '#7673EB' : 'rgba(0, 0, 0, 0.6)')};
  }
`

export default function IconButton({
  name,
  iconPosition = 'left',
  text,
  onClick,
  iconProps,
  ...rest
}) {
  const Icon = require(`react-feather/dist/icons/${name}`).default
  return (
    <Button type="button" className="icon-button" onClick={onClick} {...rest}>
      {text && iconPosition === 'right' && text && <span>{text}</span>}
      <Icon {...iconProps} />
      {text && iconPosition === 'left' && <span>{text}</span>}
    </Button>
  )
}

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
