import { gql } from 'apollo-boost'

export const UpdateCustomer = gql`
  mutation UpdateCustomer($attributes: CustomerInput!) {
    updateCustomer(input: { attributes: $attributes }) {
      customer {
        name
      }
      errors
    }
  }
`
