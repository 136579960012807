import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Settings } from 'react-feather'

import useCurrentUser from '../../hooks/useCurrentUser'
import { Card, PageHeader, SubmitButton, TextField } from '../shared'
import { mutation } from '../../graphql/client'
import { UpdateUser } from '../../graphql/queries/Users'
import { SuccessNotice } from '../shared/Notice'

export default function UserSettings() {
  const currentUser = useCurrentUser()
  const [showSuccess, setShowSuccess] = useState(false)

  const handleSubmit = (values, formik) => {
    const { email, password } = values
    const attributes = { email, password }
    mutation(UpdateUser, { attributes })
      .then((res) => {
        setShowSuccess(true)
        formik.setSubmitting(false)
      })
      .catch((err) => {
        formik.setSubmitting(false)
        console.log('error', err)
        formik.setErrors(err)
      })
  }

  if (!currentUser) return null

  console.log('current user', currentUser)

  return (
    <div>
      <PageHeader
        text="Settings"
        breadcrumb="User"
        icon={<Settings size={20} />}
      />

      <Card>
        {showSuccess && (
          <SuccessNotice style={{ marginBottom: '1em' }}>
            Your account details were successfully updated.
          </SuccessNotice>
        )}
        <Formik
          initialValues={{ ...currentUser, password: '' }}
          onSubmit={handleSubmit}
        >
          <Form>
            <TextField
              name="email"
              placeholder="user@somewhere.com"
              label="Email Address"
            />
            <TextField
              name="password"
              type="password"
              label="Password"
              value=""
              bottomNote="Enter a new password to update your login credentials"
            />
            <SubmitButton text="Save Account Details" />
          </Form>
        </Formik>
      </Card>
    </div>
  )
}
