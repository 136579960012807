import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'

import { FormErrors } from './FormField'
import Label from './Label'

import Input from './Input'

export const FieldRoot = styled.div`
  margin-bottom: 1em;
`

export const FieldRow = styled.div`
  display: grid;
  column-gap: 0.7em;
  row-gap: 0.7em;
`

export const FieldWrap = styled.div`
  position: relative;
  width: 100%;
`

export const IconWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #6b6b6b;
`

export const Note = styled.div`
  font-size: 13px;
  color: #6b6b6b;
`

export default function TextField({
  note,
  label,
  icon,
  hideErrorMessage,
  rootStyle,
  bottomNote,
  validate,
  onBlur,
  ...rest
}) {
  const [field, meta, helpers] = useField({ name: rest.name, validate })
  let classes = 'field text-field'
  if (meta.error && meta.touched) classes += ' error'
  if (icon) classes += ' icon'

  let cols = '100%'
  if (note) cols = '60% auto'
  else if (bottomNote) cols = '100%'

  return (
    <FieldRoot className={classes} style={rootStyle}>
      {label && <Label htmlFor={rest.name}>{label}</Label>}
      <FieldRow cols={cols} className="field-row" style={{ gridTemplateColumns: cols }}>
        <FieldWrap className="field-wrap">
          <TextInput {...field} {...rest} error={meta.touched && meta.error} />
          {icon && <IconWrap className="inline-icon">{icon}</IconWrap>}
        </FieldWrap>
        {(note || bottomNote) && <Note className="note">{note || bottomNote}</Note>}
      </FieldRow>
      {meta.touched && meta.error && !hideErrorMessage && (
        <FormErrors>{meta.error}</FormErrors>
      )}
    </FieldRoot>
  )
}

export function TextInput({ ...rest }) {
  const [field] = useField(rest.name)

  if (!rest.name) {
    throw new Error('name is a required property')
  }

  return <Input id={rest.name} type="text" {...field} {...rest} value={field.value || ''} />
}
