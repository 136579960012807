import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Settings } from 'react-feather'
import * as yup from 'yup'

import { useAppContext } from '../../contexts/app'
import {
  SubmitButton,
  NumberField,
  FormField,
  MainWrap,
  PageHeader,
  LastSaved,
  Card,
  Flex,
} from '../shared'
import { updateCustomer } from './actions'

export const Schema = yup.object().shape({
  salesTaxRate: yup.number().nullable(yup.number()),
})

export default function CommerceSettings() {
  const appContext = useAppContext()
  const [lastSaved, setLastSaved] = useState(null)

  const handleSubmit = (attributes, formik) => {
    const values = Schema.cast(attributes)
    console.log('values', values)
    updateCustomer(values, formik).then((res) => {
      setLastSaved(new Date())
    })
  }

  return (
    <MainWrap>
      <PageHeader text="Settings" breadcrumb="Commerce" icon={<Settings size={20} />} />

      <Card>
        <Formik
          initialValues={{ salesTaxRate: appContext.customer.salesTaxRate }}
          validationSchema={Schema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormField
              name="salesTaxRate"
              label="Sales Tax Rate"
              note="A flat sales tax rate used for all customers."
            >
              <NumberField suffix="%" prefix="" name="salesTaxRate" placeholder="8.025" />
            </FormField>
            <Flex>
              <SubmitButton text="Save Commerce Settings" />
              {lastSaved && <LastSaved time={lastSaved} style={{ marginLeft: '1em' }} />}
            </Flex>
          </Form>
        </Formik>
      </Card>
    </MainWrap>
  )
}
