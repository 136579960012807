import React from 'react'
import { Formik, Form } from 'formik'
import { SubmitButton, TextField } from '../shared'

import Modal from '../shared/Modal'
import { updateApiKeyAuthorization } from './actions'

export default function ShipstationModal({ onSubmit, onClose, integration }) {
  const handleSubmit = async (values, formik) => {
    try {
      await updateApiKeyAuthorization('shipstation', values)
      onClose()
    } catch (err) {
      console.log('error', err)
    } finally {
      formik.setSubmitting(false)
    }
  }
  return (
    <div>
      <Modal onClose={onClose} open showClose title="Configure Shipstation Account">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ ...(integration && integration.authorization) }}
        >
          <Form>
            <p>
              Enter your Shipstation API key and secret to connect your Shipstation account
              to Replii.
            </p>
            <p>
              In your Shipstation account, you can find your API key and secret in Settings
              -&gt; Account -&gt; API Settings.
            </p>
            <TextField name="apiKey" label="Shipstation API Key" />
            <TextField name="apiSecret" label="Shipstation API Secret" />
            <SubmitButton fullWidth text="Save Shipstation Details" />
          </Form>
        </Formik>
      </Modal>
    </div>
  )
}
