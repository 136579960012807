import React from 'react'
import styled from 'styled-components'
import { useSelect } from 'downshift'
import { useField } from 'formik'
import { ChevronDown } from 'react-feather'

import Label from './Label'

export const SelectWrap = styled.div`
  background-color: var(--color-field-bg);
  border: ${(props) =>
    props.error
      ? '1px solid var(--color-field-border-error)'
      : '1px solid var(--color-field-border)'};
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  width: 100%;
  font-family: var(--font-family);
  font-size: var(--base-font-size);
  box-shadow: none;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s all;
  position: relative;

  &:active,
  &:focus {
    border: 1px solid #8fa7bd;
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px rgba(68, 155, 236, 0.25);
  }

  &:focus {
    outline: none;
    background-color: #f5f5f5;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #a8a8a8;
    opacity: 1;
  }
`

export const SelectMenu = styled.ul`
  max-height: 250px;
  background-color: var(--color-field-bg);
  border-radius: 4px;
  border: 1px solid var(--color-field-border);
  display: none;
  position: absolute;
  padding-left: 0;
  overflow-y: auto;
  outline: none;
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
  top: 40px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: visibility 0.3s ease-in-out;

  &.open {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  li {
    list-style: none;
    background-color: var(--color-field-bg);
    display: block;
    line-height: 1em;
    padding: 1em;
  }
`

export const SelectInputWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1em;
  width: 100%;

  .icon-wrap {
    position: absolute;
    right: 10px;
    top: 12px;
  }
`

const InputText = styled.span`
  color: ${(props) =>
    props.defaultText ? 'var(--color-field-placeholder)' : 'var(--input-color)'};
`

const SelectInner = styled.div`
  padding: 1.2em 1em;
`

export default function SimpleSelect({
  name,
  items,
  value,
  onChange,
  label,
  placeholder = 'Pick an option',
  validate,
  ...rest
}) {
  const [field, _, helpers] = useField({ name, value, validate })
  const handleChange = ({ selectedItem: { value } }, e) => {
    helpers.setValue(value, true)
    onChange && onChange(value)
  }

  const initial =
    field.value && items.find((item) => item.value.toString() === field.value.toString())

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: handleChange,
    initialSelectedItem: initial,
  })

  return (
    <SelectWrap className="simple-select" {...rest}>
      <SelectInner {...getToggleButtonProps({ className: 'select-input' })}>
        {label && <Label {...getLabelProps()}>{label}</Label>}
        <SelectInputWrap>
          <InputText defaultText={!selectedItem}>
            {selectedItem ? selectedItem.text : placeholder}
          </InputText>
          <div className="icon-wrap">
            <ChevronDown color="#76787b" />
          </div>
        </SelectInputWrap>
      </SelectInner>
      <SelectMenu
        {...getMenuProps({
          className: `select-menu${isOpen ? ' open' : ''}`,
        })}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              style={
                highlightedIndex === index
                  ? { backgroundColor: 'var(--color-menu-item-highlight)' }
                  : {}
              }
              key={item.value}
              {...getItemProps({ item, index })}
            >
              {item.text}
            </li>
          ))}
      </SelectMenu>
    </SelectWrap>
  )
}
