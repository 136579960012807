import React, { useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Formik, Form, useField } from 'formik'
import pick from 'lodash.pick'

import { Card, Loader, TextField, FormField } from '../shared/'
import PhoneField from '../../shared/PhoneField'
import { mutation, useQuery } from '../../graphql/client'
import {
  FetchContact,
  CreateRecipient,
  UpdateRecipient,
} from '../../graphql/queries/Contacts'
import SubmitButton from '../shared/SubmitButton'
import { validatePhone, validateEmail } from '../../lib/utils'
import ContactPaymentForm from './ContactPaymentForm'
import PageHeader from '../shared/PageHeader'

export default function ContactFormHome() {
  const { id, listId } = useParams()
  if (id) {
    return <EditContact id={id} listId={listId} />
  }

  return <ContactForm listId={listId} />
}

function PhoneInput(props) {
  const [field, meta, helpers] = useField(props)
  const { label } = props

  return (
    <PhoneField name="phone" label="Phone Number" placeholder="123-456-7890" {...field} />
  )
}

function EditContact({ listId, id }) {
  const { loading, data } = useQuery(FetchContact, { id })
  if (loading) return <Loader />

  return <ContactForm contact={data.contact} listId={listId} />
}

function ContactForm({ contact }) {
  const history = useHistory()
  const { listId } = useParams()
  const [editCard, setEditCard] = useState(false)

  const onSubmit = async (values, props) => {
    const mut = contact && contact.id ? UpdateRecipient : CreateRecipient
    mutation(mut, {
      attributes: values,
      listId,
      id: contact && contact.id,
    })
      .then(({ payload }) => {
        props.setSubmitting(false)
        history.replace(`/lists/${listId}/contacts/${payload.id}/edit`)
      })
      .catch((err) => {
        // console.log('err', err)
        for (const [field, errors] of Object.entries(err)) {
          // console.log('field', field, errors)
          props.setFieldError(field, errors[0])
        }
      })
  }

  const validate = (values) => {
    const errors = {}
    const { name, email, phone } = values
    console.log('validate', values)

    // name validation
    if (!name || name === '' || name.split(' ').length === 1) {
      errors.name = 'First and last name are required.'
    }

    // validate email if provided
    if (email && email !== '' && !validateEmail(email)) {
      errors.email = 'Email address is invalid'
    }

    // validate phone number
    if (!validatePhone(phone)) {
      errors.phone = 'Enter a valid phone number'
    }

    return errors
  }

  const sources = contact?.externalCustomer?.paymentSources
  const source = sources && sources[0]

  return (
    <div>
      <PageHeader
        text={`${contact ? 'Edit' : 'New'} Contact`}
        breadcrumb={contact && contact.name}
      />

      <Card>
        <p>
          Enter contact details below. Only add people who have explicitly opted in to
          receiving messages from you.
        </p>
        <Formik
          validateOnChange={false}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={contact || {}}
        >
          {(props) => {
            return (
              <Form style={{ maxWidth: '50%' }}>
                <TextField name="name" label="Full Name" placeholder="Full Name" />
                <PhoneInput name="phone" label="Phone Number" />
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  placeholder="user@example.com"
                />

                <SubmitButton text="Save Contact" type="submit" />
              </Form>
            )
          }}
        </Formik>

        <hr />

        {source && !editCard && (
          <div>
            <h3 style={{ marginTop: 0 }}>Payment Details</h3>
            <span style={{ paddingRight: '1em' }}>
              <strong>{source.brand}</strong> ending in {source.last4}
            </span>
            <Link to="#" onClick={() => setEditCard(true)}>
              Update Card
            </Link>
          </div>
        )}
        {contact && (!source || editCard) && <ContactPaymentForm contact={contact} />}
      </Card>
    </div>
  )
}
