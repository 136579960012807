import { gql } from 'apollo-boost'

export const UpdateCampaignSender = gql`
  mutation UpdateCampaignSender($attributes: CampaignSenderInput!) {
    updateCampaignSender(input: { attributes: $attributes }) {
      campaignSender {
        id
        confirmationThankYou
        orderConfirmMessage
        settings
      }
    }
  }
`
