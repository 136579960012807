import React from 'react'

import { Card, Header } from '../shared'
import { useAppContext } from '../../contexts/app'

export default function SettingsHome() {
  const { customer, campaignSender } = useAppContext()

  if (!customer) return null
  return (
    <div>
      <Header content='Settings' />
      <Card>
        <div>
          <strong>Customer Public ID: </strong> {customer.publicId}
        </div>
        <div>
          <strong>Customer Stripe ID: </strong> {customer.stripeId}
        </div>
        <div>
          {campaignSender.incomingNumbers.map(number => (
            <div key={number.id}>
              <strong>Number: </strong> {number.number}
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}
