// this is loaded by a snippet of javascript initiated by client code
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-hot-loader'
import amplitude from 'amplitude-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from './components/shared/ErrorBoundary'
import WidgetRoot from './components/Widget/WidgetRoot'
import { track } from './lib/sdk/analytics'

import './sdk.css'

const APP_ROOT = '__replii_root'
// const SDK_HOST = 'sdk.replii.co'

const findOrCreateAppRoot = () => {
  const existing = document.getElementById(APP_ROOT)
  if (existing) return existing

  const root = document.createElement('div')
  root.id = APP_ROOT
  document.body.appendChild(root)
  return root
}

export const REPLII_VERSION = '1.0.1'

const RepliiSDK = {
  loadWidget: (widgetID) => {
    // load the widget
    if (!widgetID || widgetID === '') return

    const root = findOrCreateAppRoot()
    amplitude.getInstance('replii').init('f2170803e0faa319fe4f9bc733c212d5')

    try {
      track('SDK Loaded')
    } catch (err) {
      console.error('Error tracking widget loaded event: ', err)
    }

    return ReactDOM.render(
      <ErrorBoundary>
        <WidgetRoot widgetID={widgetID} />
      </ErrorBoundary>,
      root
    )
  },
  version: REPLII_VERSION,
  commit: process.env.COMMIT_REF,
}

window.RepliiSDK = RepliiSDK
export default RepliiSDK
