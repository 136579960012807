import React from 'react'
import styled from 'styled-components'
import produce from 'immer'
import NumberFormat from 'react-number-format'
import { useField, useFormikContext } from 'formik'
import DeleteButton from '../shared/DeleteButton'
import Input from '../shared/Input'
import Label from '../shared/Label'
import { ErrorPopover } from '../shared/Popover'

const TierGrid = styled.div`
  display: inline-grid;
  grid-template-columns: [label-1] 100px [min] 80px [max] 80px [price] 100px [delete] 30px;
  align-items: center;
  text-align: center;
`

const GridHeader = styled.div`
  background-color: rgba(139, 151, 162, 0.58);
  color: #3b4a5b;
  font-size: 13px;
  font-weight: 600;
  padding: 0.6em 0.8em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GridLeftLabel = styled(GridHeader)`
  background-color: rgba(139, 151, 162, 0.33);
  color: #3b4a5b;
  font-size: 13px;
  font-weight: 500;
`

const GridCell = styled.div`
  background-color: rgba(139, 151, 162, 0.19);
  padding: 4px;

  input[disabled] {
    background-color: #dfe2e4;
  }
`

const DeleteCell = styled(GridHeader)`
  background-color: white;
`

export function TieredPricingForm(props) {
  const { errors } = props
  const { submitForm } = useFormikContext()
  const [field, _, helpers] = useField('priceTiers')
  const tiers = field.value

  const handleAddTier = (e) => {
    e.preventDefault()

    const nextState = produce(tiers, (draft) => {
      const prevTier = tiers[tiers.length - 2]
      let startingVal = ''
      if (prevTier.max !== '') {
        startingVal = parseInt(prevTier.max) + 1
      }

      draft[tiers.length - 1].min = startingVal + 2
      draft.splice(tiers.length - 1, 0, {
        min: startingVal,
        max: startingVal + 1,
        unit_price: '',
      })
    })
    helpers.setValue(nextState)
  }

  const deleteTier = (i, e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    const nextState = produce(tiers, (draft) => {
      const prevMax = tiers[i - 1].max
      draft[i + 1].min = parseInt(prevMax) + 1
      draft.splice(i, 1)
    })
    helpers.setValue(nextState)
    submitForm()
  }

  const handleChange = (i, prop, value) => {
    const nextState = produce(tiers, (draft) => {
      draft[i][prop] = value
      if (prop === 'max' && i !== tiers.length - 1) {
        draft[i + 1].min = parseInt(value) + 1
        if (draft[i + 1].max <= draft[i + 1].min) {
          draft[i + 1].max = draft[i + 1].min + 1
        }
      }
    })

    helpers.setValue(nextState)
  }

  const priceErrors = errors?.priceTiers ?? []

  return (
    <div style={{ marginBottom: '1em' }}>
      <Label>Tiered Pricing</Label>

      <TierGrid>
        <GridHeader></GridHeader>
        <GridHeader>First Unit</GridHeader>
        <GridHeader>Last Unit</GridHeader>
        <GridHeader>Unit Price</GridHeader>
        <div></div>
        {field.value.map((tier, i) => (
          <React.Fragment key={`tier-${i}`}>
            <GridLeftLabel>{i === 0 ? 'For the first' : 'For the next'}</GridLeftLabel>
            <TieredField
              name={`priceTiers[${i}].min`}
              onFocus={() => {}}
              onChange={handleChange.bind(null, i, 'min')}
              disabled
              error={priceErrors[i]?.min}
              value={i === 0 ? '1' : tier.min}
              placeholder="1"
            />
            <TieredField
              name={`priceTiers[${i}].max`}
              error={priceErrors[i]?.max}
              onChange={handleChange.bind(null, i, 'max')}
              placeholder={i === tiers.length - 1 ? '∞' : ''}
              value={i === tiers.length - 1 ? '' : tier.max}
              disabled={i === tiers.length - 1}
            />
            <TieredField
              name={`priceTiers[${i}].unit_price`}
              onChange={handleChange.bind(null, i, 'unit_price')}
              value={tier.unit_price}
              prefix="$"
              error={priceErrors[i]?.unit_price}
              placeholder="$20"
              isNumericString
            />
            <DeleteCell style={{ backgroundColor: 'white', height: '100%' }}>
              {i > 0 && i < tiers.length - 1 && (
                <DeleteButton
                  name="x"
                  iconProps={{ size: 14 }}
                  onClick={deleteTier.bind(null, i)}
                />
              )}
            </DeleteCell>
          </React.Fragment>
        ))}
        <div></div>
        <div></div>
        <div></div>
        <div>
          <a
            href="#"
            onClick={handleAddTier}
            style={{ padding: '8px 0', display: 'block' }}
          >
            + Add Tier
          </a>
        </div>
      </TierGrid>
    </div>
  )
}
function TieredField({ onChange, error, ...opts }) {
  const handleValueChange = ({ value }) => {
    onChange(value)
  }

  return (
    <GridCell style={{ position: 'relative' }}>
      <ErrorPopover error={error} open={error} place="below">
        <NumberFormat
          error={error}
          onValueChange={handleValueChange}
          {...opts}
          customInput={Input}
        />
      </ErrorPopover>
    </GridCell>
  )
}
