import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { Truck, User } from 'react-feather'
import * as yup from 'yup'

import { IconButton, SubmitButton, SimpleSelect, FormField } from '../shared'
import NumberField from '../shared/NumberField'

import states from './us_states.json'
import { FieldsWrap, FieldSectionHeader, textField } from './StripeForm'
import { ZIP_EXP, validateZip } from '../../lib/utils'
import { UpdateCustomerAddress } from '../../graphql/queries/Collect'
import { mutation } from '../../graphql/client'

const fields = {
  name: '',
  email: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
}

const Schema = yup.object().shape({
  street: yup.string().required(),
  street2: yup.string().nullable(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().matches(ZIP_EXP),
  name: yup.string().required(),
  email: yup.string().email().required(),
})

export default function CustomerAddressForm({ recipient, onSaveAddress }) {
  const externalCustomer = recipient.externalCustomer || {}
  const [address, setAddress] = useState(externalCustomer?.defaultShippingAddress || {})

  const id = address?.id
  const { street, street2, city, state, zip } = address
  const addressParams = {
    ...fields,
    street,
    street2,
    city,
    state,
    zip,
    name: recipient.name || externalCustomer.name,
    email: recipient.email || externalCustomer.email,
  }
  const [editing, setEditing] = useState(!address.id)
  const initialData = {
    ...addressParams,
  }

  const handleSubmit = async (values) => {
    const vars = {
      attributes: { ...values, addressType: 'shipping' },
      customerId: recipient.customer.publicId,
      recipientId: recipient.publicId,
      id,
    }
    const { name, email } = values
    const result = await mutation(UpdateCustomerAddress, vars)
    setAddress(result.payload)
    onSaveAddress(result.payload, { name, email })
    setEditing(false)
  }

  const validatePostalCode = (zip) => {
    if (!validateZip(zip)) {
      return 'Enter a valid zip code.'
    }
  }

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialData}
        validateOnBlur
        validateOnChange={false}
        validationSchema={Schema}
      >
        {(props) => {
          return (
            <Form>
              <FieldsWrap>
                <div style={{ display: 'grid', rowGap: '.5em' }}>
                  <FieldSectionHeader style={{ paddingBottom: 0 }}>
                    <User size={16} />
                    1. Customer Details
                  </FieldSectionHeader>
                  {textField('name', 'Full Name', 'text', {
                    autoComplete: 'name',
                    tabIndex: 0,
                  })}
                  {textField('email', 'Email Address', 'email', {
                    autoComplete: 'email',
                    tabIndex: 1,
                    bottomNote:
                      'Receipt and shipping information will be sent to this email address.',
                  })}
                </div>

                <div style={{ display: 'grid', rowGap: '.5em' }}>
                  <FieldSectionHeader
                    style={{ justifyContent: 'space-between', paddingBottom: 0 }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Truck size={16} />
                      2. Shipping Details
                    </div>
                    {id && (
                      <IconButton
                        purple
                        name="edit"
                        text="Edit"
                        iconProps={{ size: 16 }}
                        onClick={() => setEditing(!editing)}
                      />
                    )}
                  </FieldSectionHeader>
                  {editing ? (
                    <>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '60% auto',
                          columnGap: '.4em',
                          rowGap: '.5em',
                        }}
                      >
                        {textField('street', 'Street Address', 'text', {
                          autoComplete: 'address-line1',
                          placeholder: '123 Main St',
                          tabIndex: 4,
                        })}
                        {textField('street2', 'Apt #', 'text', {
                          autoComplete: 'address-line2',
                          placeholder: 'Apt, Suite, etc',
                          required: false,
                          tabIndex: 5,
                        })}
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '35% 40% auto',
                          gridColumnGap: '.4em',
                        }}
                      >
                        {textField('city', 'City', 'text', {
                          autoComplete: 'shipping address-level2',
                          tabIndex: 6,
                        })}
                        <FormField name="state" hideErrorMessage cols={1}>
                          <SimpleSelect
                            name="state"
                            placeholder="State"
                            items={states}
                            tabIndex={7}
                          />
                        </FormField>
                        <FormField
                          name="zip"
                          cols={1}
                          hideErrorMessage
                          validate={validatePostalCode}
                        >
                          <NumberField
                            type="tel"
                            thousandSeparator={false}
                            tabIndex={8}
                            name="zip"
                            autoComplete="shipping postal-code"
                            prefix=""
                            placeholder="Zip Code"
                          />
                        </FormField>
                      </div>
                      <SubmitButton fullWidth text="Save Shipping Info" type="submit" />
                    </>
                  ) : (
                    <AddressSummary {...props.values} />
                  )}
                </div>
              </FieldsWrap>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const SummaryWrap = styled.div`
  line-height: 1.6em;
  color: #202020;
  font-size: 15px;
`

function AddressSummary({ city, state, zip, street, street2, name }) {
  const streets = [street, street2].filter((s) => s && s !== '').join(', ')
  return (
    <SummaryWrap>
      <div>{name}</div>
      <div>{streets}</div>
      <div style={{ color: '#8F8F8F' }}>
        {city}, {state} {zip}
      </div>
    </SummaryWrap>
  )
}
