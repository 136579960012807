import React from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'


export default function PhoneNumber({ phone }) {
  if (!phone) return null

  const parsed = parsePhoneNumberFromString(phone, 'US')

  return (
    <span className='phone-number'>
      {parsed.formatNational()}
    </span>
  )
}
