import React from 'react'

import { DataTable, PhoneNumber } from '../shared'

function name(contact) {
  return (contact && (contact.name || contact.externalCustomer?.name)) || '-'
}

export default function ContactsTable({ contacts, actions }) {
  return (
    <div>
      <DataTable
        data={contacts}
        headers={['Name', 'Email', 'Phone']}
        values={(contact) => [
          name(contact),
          contact.externalCustomer?.email || contact.email || '--',
          <PhoneNumber phone={contact.phone} />,
        ]}
        actions={actions}
      />
    </div>
  )
}
