import React from 'react'

export const DISCLAIMER_ID = 'replii-purchase-disclaimer'

export default function PurchaseDisclaimer() {
  return (
    <div id={DISCLAIMER_ID}>
      <h4>Purchasing Agreement - Purchasing Over SMS</h4>
      <p className="note purchase-note">
        You understand that your credit card will be charged for the amount shown. Your
        payment and shipping information will be saved securely so you can purchase in the
        future by replying to a SMS message.
      </p>
      <p>
        You understand and agree that your payment and shipping information will be stored
        securely by a third party payment provider. You understand and agree that your
        credit card will be used for future purchases initiated by you in response to SMS or
        MMS promotional messages. If you indicate your willingness to make a purchase by
        replying to a SMS or MMS promotional offer with an approved keyword or order
        quantity, you agree to have your credit card charged for the total amount of each
        order. Approved keywords may be but are not limited to 'YES', 'CONFIRM', or a
        numeric order quantity (e.g. 1, 2, 3). To cancel an order, reach out to
        support@replii.co.
      </p>
    </div>
  )
}
