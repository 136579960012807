import React from 'react'
import { useImmer } from 'use-immer'
import { useHistory, Link } from 'react-router-dom'

import { mutation, useQuery } from '../../../graphql/client'
import {
  FetchCampaignLists,
  DeleteCampaignList,
} from '../../../graphql/queries/CampaignLists'
import {
  Button,
  Card,
  DataTable,
  Flex,
  Loader,
  DeleteButton,
  IconButton,
  PageHeader,
} from '../../shared'

function CampaignListLoader() {
  const { loading, data } = useQuery(FetchCampaignLists)
  if (loading) return <Loader />

  return <CampaignListHome lists={data.lists} />
}

function CampaignListHome({ lists }) {
  const history = useHistory()
  const [state, setState] = useImmer({ lists })

  const deleteCampaign = (id, index) => {
    if (!window.confirm('Are you sure?')) return

    return mutation(DeleteCampaignList, { id }).then(() => {
      setState((draft) => {
        draft.lists.splice(index, 1)
      })
    })
  }

  return (
    <div>
      <PageHeader text="Lists">
        <p>
          Contacts are added to a specific list via a widget. Campaigns are sent to a
          specific list.
        </p>
      </PageHeader>
      <Card>
        <Button onClick={() => history.push('/lists/new')} text="Add List" />
        <DataTable
          headers={['Name', 'Default', 'Default Campaign', 'Members']}
          values={(item) => [
            <Link to={`/lists/${item.id}`}>{item.name}</Link>,
            item.default,
            item.defaultCampaign && (
              <Link to={`/campaigns/${item.defaultCampaign.id}`}>
                {item.defaultCampaign.name}
              </Link>
            ),
            item.recipientCount,
          ]}
          data={state.lists}
          actions={(item, i) => (
            <Flex>
              <Link to={`/lists/${item.id}/edit`}>
                <IconButton name="edit" />
              </Link>
              {!item.default && <DeleteButton onClick={() => deleteCampaign(item.id, i)} />}
            </Flex>
          )}
        />
      </Card>
    </div>
  )
}

export default CampaignListLoader
