import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { useLoadStripe } from '../../hooks/useStripe'
import './TextRegistration.less'
import PaymentForm from './PaymentForm'
import Loader from '../shared/Loader'
import PurchaseDisclaimer, { DISCLAIMER_ID } from './PurchaseDisclaimer'
import { mutation } from '../../graphql/client'
import { UpdateCustomerData } from '../../graphql/queries/Collect'
import { SuccessNotice } from '../shared/Notice'
import Button from '../shared/Button'

import { ADULT_CONSENT_ID } from './StripeForm'

const Header = styled.h2`
  font-size: 22px;
  font-weight: 800;
`

export default function TextRegistration({ recipient, ...rest }) {
  const stripe = useLoadStripe(recipient.customer.stripeId)
  const history = useHistory()
  const { customer } = recipient
  const pendingOrder = rest.pendingOrder

  const onSubmit = (payload, form, actions) => {
    const params = { ...payload, customerId: customer.publicId }
    const disclaimerElement = document.getElementById(DISCLAIMER_ID)
    if (disclaimerElement) {
      params.disclaimer = disclaimerElement.innerText
    }

    if (customer.adultConsentRequired) {
      const consentElement = document.getElementById(ADULT_CONSENT_ID)
      if (consentElement) {
        params.adultConsent = consentElement.innerText
      }
    }

    mutation(UpdateCustomerData, params)
      .then(() => {
        actions.setSubmitting(false)
        history.replace('/success')
      })
      .catch((err) => {
        actions.setSubmitting(false)
        console.log('error', err)
      })
  }

  if (!stripe) return <Loader />

  return (
    <div className="text-registration">
      <div style={{ margin: '0 auto', maxWidth: 420 }}>
        <div>
          <Header style={{ marginBottom: 0 }}>
            {pendingOrder ? 'Complete Your Order' : 'Update Customer Profile'}
          </Header>
          <p style={{ marginTop: '.5em' }}>
            {pendingOrder
              ? 'Complete your billing and shipping information below to complete your order.'
              : 'Update your billing and shipping information below.'}
          </p>
          <PaymentForm
            stripe={stripe}
            onSubmit={onSubmit}
            recipient={recipient}
            {...rest}
          />
          <div className="note purchase-note" style={{ fontSize: 13, marginTop: '1em' }}>
            <PurchaseDisclaimer />
          </div>
        </div>
      </div>
    </div>
  )
}

export function RegistrationSuccess() {
  return (
    <div style={{ margin: '1em auto 0 auto', maxWidth: 420 }}>
      <SuccessNotice>
        Order complete! You'll receive a confirmation email shortly, with shipping details
        to follow.
      </SuccessNotice>
      <Button
        fullWidth
        text="You can safely close this window"
        style={{ marginTop: '1em' }}
      />
    </div>
  )
}
