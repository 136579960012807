import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, useField, useFormikContext } from 'formik'
import { Settings, ShoppingCart } from 'react-feather'
import { useParams, useHistory, Link } from 'react-router-dom'
import isempty from 'lodash.isempty'
import dayjs from 'dayjs'

import { useAppContext } from '../../contexts/app'
import FormErrorSummary from '../shared/FormErrorSummary'
import {
  Card,
  NumberField,
  Loader,
  FormField,
  Checkbox,
  TextField,
  Select,
  Flex,
  Disabled,
  LastSaved,
  PageHeader,
  SubmitButton,
} from '../shared'
import { dateString, isPast } from '../../lib/dateHelpers'
import { mutation, query, useQuery } from '../../graphql/client'
import {
  DeleteCampaignMessage,
  FetchCampaignMessageForEdit,
  FetchPriceTier,
} from '../../graphql/queries/CampaignMessages'
import DateTimeInput from '../shared/DatePicker/DateTimeInput'
import Modal from '../shared/Modal'
import PhoneField from '../../shared/PhoneField'
import ContextMenu from '../shared/ContextMenu'

import './CampaignMessageForm.scss'
import MessagePreview from './MessagePreview'
import FormSection from '../../shared/FormSection'
import InfoNotice from '../shared/Notice'
import TestMessageForm from './TestMessageForm'
import { saveCampaignMessage, Schema, transitionStatus, contextItems } from './actions'
import SMSBodyInput from './SMSBodyInput'
import ImageUploader from './ImageUploader'
import { TieredPricingForm } from './TieredPricingForm'

const DEFAULT_TIERS = [
  { min: '1', max: '2', unit_price: '25' },
  { min: '3', unit_price: '20' },
]

const ButtonBar = styled.div`
  position: sticky;
  background-color: white;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 1em;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1em;
  margin-top: -1em;
`

export default function CampaignMessageForm() {
  const { id } = useParams()

  if (id) return <EditForm id={id} />
  return <NewForm />
}

function EditForm({ id }) {
  const { loading, data } = useQuery(FetchCampaignMessageForEdit, { id })
  if (loading) return <Loader />

  return <MessageForm data={data.campaignMessage} s3SignUrl={data.s3SignUrl} id={id} />
}

const NewForm = () => <MessageForm />

export function ConnectedPhoneField({ ...props }) {
  const [field, _, helpers] = useField({ ...props })

  const handleChange = (e) => {
    helpers.setValue(e.target.value)
  }

  return (
    <PhoneField {...field} {...props} placeholder="123-456-7890" onChange={handleChange} />
  )
}

function MessageForm({
  id = null,
  s3SignUrl = null,
  data = {
    body: '',
    imagePath: '',
    orderable: false,
    recipientReplies: false,
    shippingAmount: '',
    availableInventory: '',
    maxPerCustomer: '',
    orderConfirmMessage: '',
  },
}) {
  const { currentUser } = useAppContext()
  const [showTestForm, setShowTestForm] = useState(false)
  const [state, setState] = useState({
    lastSaved: null,
  })
  const { campaignId } = useParams()
  const history = useHistory()
  const isEditing = !!id

  const onSubmit = (values, actions) => {
    saveCampaignMessage(values, id, campaignId)
      .then(({ payload }) => {
        setState((state) => ({ ...state, lastSaved: new Date() }))
        actions.setSubmitting(false)
        if (!isEditing) {
          history.replace(`/campaigns/${campaignId}/messages/${payload.id}/edit`)
        }
      })
      .catch((err) => {
        actions.setSubmitting(false)
        console.log('err', err)
      })
  }

  const deleteMessage = () => {
    if (!window.confirm('Are you sure?')) return

    mutation(DeleteCampaignMessage, { id }).then((res) => {
      history.replace(`/campaigns/${campaignId}`)
    })
  }

  const clickContextItem = (formik, item) => {
    switch (item.name) {
      case 'publish':
        return transitionStatus(false, id, formik)
      case 'pause':
        return transitionStatus(true, id, formik)
      case 'send_test':
        return setShowTestForm(true)
      case 'delete':
        return deleteMessage()
      default:
        break
    }
  }

  const finishedUploading = (formik, imageUrl) => {
    formik.submitForm()
  }

  const handleBlur = (props) => {
    props.submitForm()
  }

  const validateForm = (values) => {
    let errors = {}
    if (values.sendDate) {
      const now = dayjs()
      const sendDate = dayjs(values.sendDate)
      if (sendDate.isBefore(now)) {
        // errors.sendDate = 'Date is in the past.'
      }
    }
    if (
      values.orderable &&
      (!values.externalProductId || values.externalProductId === '')
    ) {
      errors.externalProductId = 'Please select a product'
    }
    if (values.useTieredPricing && values.priceTiers) {
      const priceTierErrors = []

      const isInvalidNumber = (val) => {
        return !val || val <= 0 || val === '' || isNaN(val)
      }

      // 1. max and unit prices required
      // 2. max is exactly 1 less than next min
      values.priceTiers.forEach((tier, i) => {
        const lastTier = i === values.priceTiers.length - 1
        const nextTier = values.priceTiers[i + 1]
        const curMax = parseInt(tier.max)
        const curMin = parseInt(tier.min)
        const curPrice = parseFloat(tier.unit_price)
        const curErrors = {}

        if (lastTier) {
          if (isInvalidNumber(curPrice)) {
            curErrors.unit_price = 'Please enter a valid price.'
            priceTierErrors[i] = curErrors
          }
          return
        }

        if (isInvalidNumber(curMax)) curErrors.max = 'Please enter a positive value.'
        if (isInvalidNumber(curPrice)) curErrors.unit_price = 'Please enter a valid price.'
        if (curMax <= curMin) curErrors.max = 'Last unit must be greater than first unit.'
        if (!isempty(curErrors)) priceTierErrors[i] = curErrors
      })

      if (priceTierErrors.length > 0) errors.priceTiers = priceTierErrors
    }

    return errors
  }

  let initial = { ...data }
  if (!initial.priceTiers) {
    initial.priceTiers = DEFAULT_TIERS
  }

  return (
    <div className="campaign-message-form">
      <Flex style={{ marginBottom: 10 }}>
        <PageHeader
          text={
            <Link to={`/campaigns/${campaignId}`}>
              Campaign{data.campaign ? ` / ${data.campaign?.name}` : ''}
            </Link>
          }
          breadcrumb={id ? 'Edit Message' : 'New Message'}
        />
      </Flex>

      <Formik
        initialValues={initial}
        onSubmit={onSubmit}
        validationSchema={Schema}
        validate={validateForm}
        validateOnBlur
        validateOnChange={false}
      >
        {(props) => {
          const { errors } = props
          const { body, sendDate, orderable } = props.values
          const isPublished = props.values.status === 'published'

          return (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '98% 375px',
                columnGap: '1em',
                marginBottom: 40,
              }}
            >
              {isEditing && (
                <Modal
                  title="Send Test Message"
                  open={showTestForm}
                  onClose={() => setShowTestForm(false)}
                >
                  <TestMessageForm
                    campaignMessageId={id}
                    onSent={() => {
                      setShowTestForm(false)
                    }}
                  />
                </Modal>
              )}
              <Card
                style={{
                  flexGrow: 2,
                  position: 'relative',
                  paddingTop: '1em',
                }}
              >
                {isPublished && (
                  <InfoNotice icon="info" style={{ marginBottom: '1em' }}>
                    <p>
                      This message is published. It {isPast(sendDate) ? 'was' : 'will be'}{' '}
                      delivered to recipients on <strong>{dateString(sendDate)}</strong>.
                    </p>
                    {!isPast(sendDate) && (
                      <p>Unpublish this message to edit this message.</p>
                    )}
                  </InfoNotice>
                )}
                <Form
                  onBlur={handleBlur.bind(null, props)}
                  style={{ display: 'grid', rowGap: '1em' }}
                >
                  <ButtonBar>
                    <div>
                      <LastSaved time={state.lastSaved} />
                    </div>
                    <SubmitButton
                      icon="save"
                      text="Save Message"
                      type="submit"
                      style={{ padding: '.6em 1.6em', fontSize: 15, fontWeight: 600 }}
                      iconProps={{
                        color: '#7D94A9',
                        strokeWidth: 1,
                        size: 24,
                        style: {
                          marginRight: '.5em',
                        },
                      }}
                    />
                    <ContextMenu
                      onClickItem={clickContextItem.bind(null, props)}
                      items={contextItems(isPublished)}
                      disabled={!id || (isPublished && isPast(sendDate))}
                    />
                  </ButtonBar>
                  <FormErrorSummary />
                  <Disabled disabled={isPublished}>
                    <DateTimeInput
                      name="sendDate"
                      label="Send Date"
                      filterPast
                      note="The day &amp; time the campaign will be sent. All times are Pacific Standard Time (PST)."
                    />
                    <FormField
                      name="imagePath"
                      label="Upload an Image"
                      note={
                        <>
                          <p>Upload an image or GIF to include with the message body.</p>
                          <p>
                            <strong>Note:</strong> Make sure the image is either portrait or
                            square, and less than 1mb.
                          </p>
                        </>
                      }
                    >
                      <ImageUploader
                        name="imagePath"
                        s3SignUrl={s3SignUrl}
                        onFinish={finishedUploading.bind(null, props)}
                      />
                    </FormField>
                    <div>
                      <SMSBodyInput
                        name="body"
                        label="SMS Body"
                        placeholder="SMS Message body"
                        note={
                          <>
                            <p>Body of the SMS Message. A segment is 160 characters.</p>
                            <p>
                              <strong>Note:</strong> If this is message is linked to a
                              product, be sure to include instructions in the message body.
                            </p>
                          </>
                        }
                        style={{ minHeight: 270 }}
                      />
                    </div>
                    <div>
                      <FormSection title="Options" icon={<Settings />}>
                        <Checkbox
                          disabled
                          name="recipientReplies"
                          submitOnCheck
                          label="Enable recipient replies?"
                          note="If checked, when recipients reply to a message, replies will be routed to an agent."
                        />

                        {currentUser.admin ? (
                          <Checkbox
                            name="demo"
                            submitOnCheck
                            label="Demo Message?"
                            note="If selected this message will be sent when a user is added to the campaign."
                          />
                        ) : null}

                        <SMSBodyInput
                          name="orderConfirmMessage"
                          placeholder="Got it, order received. Look out for an email receipt and delivery instructions in your inbox."
                          name="orderConfirmMessage"
                          label="Post-order confirmation message"
                          note="When a customer successfully places an order we'll respond with this message. Defaults to the order confirmation message in Settings / Messaging."
                          style={{ minHeight: 200 }}
                        />
                      </FormSection>
                    </div>
                    <div>
                      <FormSection title="Commerce Settings" icon={<ShoppingCart />}>
                        <Checkbox
                          name="orderable"
                          label="Connect to product"
                          submitOnCheck
                          note="Tie this message to a product to make it purchaseable."
                        />

                        <Checkbox
                          disabled={!orderable}
                          name="dynamicShipping"
                          label="Dynamic Shipping?"
                          submitOnCheck
                          note={
                            <div>
                              <p>
                                If checked, we'll use your configured shipping rates via the
                                Shipstation integration to dynamically calculate shipping
                                costs based on the quantity indicated in a customer order.
                                The least expensive option will be presented to the customer
                                before creating the order.
                              </p>
                              <p>
                                <em>
                                  Note: This adds an additional step to the customer
                                  checkout process.
                                </em>
                              </p>
                            </div>
                          }
                        />
                      </FormSection>

                      {orderable && (
                        <ProductSubform {...props.values} errors={props.errors} />
                      )}
                    </div>
                  </Disabled>
                </Form>
              </Card>
              {!isempty(body) && (
                <div className="">
                  {!isempty(props.values.body) && <MessagePreview {...props.values} />}
                </div>
              )}
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

const ProductSubform = ({
  orderable,
  dynamicShipping,
  useTieredPricing,
  priceTiers,
  ...rest
}) => {
  const { setFieldValue, submitForm } = useFormikContext()
  const { externalProducts, integrations } = useAppContext()
  const [existingPriceTiers, setExistingPriceTiers] = useState(null)

  const hasStripeIntegration =
    integrations &&
    integrations.find((i) => i.provider === 'stripe_connect' && i.configured)

  const checkExistingTieredPricing = (checked) => {
    if (!checked) return
    fetchExistingPriceTiers(rest.externalProductId)
  }

  const onPickProduct = (value) => {
    fetchExistingPriceTiers(value.value)
    submitForm()
  }

  const fetchExistingPriceTiers = async (productId) => {
    if (!useTieredPricing) return

    const results = await query(FetchPriceTier, {
      campaignMessageId: rest.id,
      productId,
    })
    if (results.priceTier) {
      setExistingPriceTiers(results.priceTier)
    } else {
      setExistingPriceTiers(null)
    }
  }

  const loadPreviousPricing = (e) => {
    e.preventDefault()
    setFieldValue('priceTiers', existingPriceTiers)
    setExistingPriceTiers(null)
    submitForm()
  }

  const dismissPreviousPricing = () => {
    setExistingPriceTiers(null)
  }

  return (
    <FormSection
      title="Product Info (optional)"
      initialExpanded={orderable}
      contentStyle={{ paddingLeft: '2em', borderLeft: '1px solid #fafafa' }}
    >
      {hasStripeIntegration ? (
        <div>
          <FormField
            label="Linked Product"
            name="externalProductId"
            note="Tie this message to a product in your inventory"
          >
            <Select
              onChange={onPickProduct}
              name="externalProductId"
              options={externalProducts.map((p) => ({
                value: p.id,
                label: `${p.name} - $${p.amount / 100}`,
              }))}
            />
          </FormField>

          <Checkbox
            name="useTieredPricing"
            label="Use Tiered Pricing"
            note="Define quantity-based price tiers that apply to the product attached to this
        campaign message."
            onCheck={checkExistingTieredPricing}
          />
          {existingPriceTiers && (
            <InfoNotice icon="info" style={{ marginBottom: '1em' }}>
              <p>
                A previous campaign message includes tiered pricing information for this
                product.
              </p>
              <p>
                <strong>Do you want to load that pricing info here?</strong>
                <a
                  href="#"
                  onClick={loadPreviousPricing}
                  style={{ marginLeft: '.5em', marginRight: '.5em' }}
                >
                  Yes
                </a>
                <a href="#" onClick={dismissPreviousPricing}>
                  No
                </a>
              </p>
            </InfoNotice>
          )}
          {useTieredPricing && <TieredPricingForm {...rest} />}

          <FormField
            name="shippingAmount"
            label="Shipping Amount"
            note="Flat dollar amount that the customer will be charged for shipping."
          >
            <NumberField
              name="shippingAmount"
              placeholder="$6"
              disabled={dynamicShipping}
            />
          </FormField>

          <TextField
            name="availableInventory"
            type="number"
            label="Total Available Inventory"
            placeholder="6"
            note="The maximum number of products available to purchase as part of this campaign message. Leave blank for unlimited."
          />

          <TextField
            name="maxPerCustomer"
            type="number"
            label="Max Quantity per Customer"
            placeholder="6"
            note="Max quantity each customer is allowed to buy."
          />
        </div>
      ) : (
        <InfoNotice>
          <Link to="/integrations">Click here</Link> to connect your e-commerce provider to
          sync your product catalog.
        </InfoNotice>
      )}
    </FormSection>
  )
}
