import { gql } from 'apollo-boost'

export const FetchExternalObjects = gql`
  query ExternalObjects($objectTypes: [String!]!, $provider: String) {
    externalObjects(objectTypes: $objectTypes, provider: $provider) {
      id
      externalId
      objectType
      provider
      meta
    }
  }
`
