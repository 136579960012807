import { wrapSchema, introspectSchema } from '@graphql-tools/wrap'
import { print } from 'graphql'

import { GQL_ENDPOINT } from './client'

let gqlSchema = null

export const executor = async ({ document, variables }) => {
  const query = print(document)
  const fetchResult = await fetch(GQL_ENDPOINT, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  })
  return fetchResult.json()
}

export const fetchRemoteSchema = async () => {
  const schema = wrapSchema({
    schema: await introspectSchema(executor),
    executor,
  })
  // console.log('schema', schema)
  return schema
}

/**
 * Fetch the remote graphql schema
 */
const fetchSchema = async () => {
  if (gqlSchema) {
    return Promise.resolve(gqlSchema)
  } else {
    const schema = await fetchRemoteSchema()
    gqlSchema = schema
    return Promise.resolve(gqlSchema)
  }
}

export const fetchType = async (type) => {
  const schema = await fetchSchema()
  if (schema) {
    return schema._typeMap[type]
  }
}
