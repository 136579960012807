import React from 'react'
import PropTypes from 'prop-types'

import './DataTable.scss'

function displayValue(val) {
  switch (typeof val) {
    case 'boolean':
      return val ? 'True' : 'False'
    default:
      return val
  }
}
/**
 * @param  {Array<Object>} data the array of data
 * @param  {Array<String>} columns array of property names to render for each item in data
 * @param  {Array<String>} headers static array of column headers
 * @param  {Function} actions a function that's passed each data item in the array
 * @param  {Function} values
 */
function DataTable({ data, columns, headers, actions, values }) {
  if (!data || data.length === 0) return <div>No data</div>

  return (
    <table cellPadding='0' cellSpacing='0' className='table data-table'>
      <thead>
        <tr>
          {(headers || columns).map(col => <th key={col}>{col}</th>)}
          {actions && <th></th>}
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, i) => {
          return <tr key={item.id}>
            {(values ? values(item) : columns).map((col, i) => (
              <td key={`${item.id}-${i}`}>
                {displayValue(values ? col : item[col])}
              </td>
            ))}
            {actions && <td className='actions'>{actions(item, i)}</td>}
          </tr>
        })}
      </tbody>
    </table>
  )
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.string),
  
  // Used as the last column
  actions: PropTypes.func,
  values: PropTypes.func,
}

export default DataTable