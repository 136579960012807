import React from 'react'
import { ShoppingBag } from 'react-feather'

import { money } from '../../lib/utils'
import { AnimatedLoader } from '../shared/Loader'
import { FieldSectionHeader } from './StripeForm'

import './OrderDetails.scss'

export default function OrderDetails({
  pendingOrder,
  shippingTotal,
  totalAmount,
  salesTaxAmount,
  loading,
}) {
  const { externalProduct, quantity, subtotal, dynamicShipping } = pendingOrder
  const { name, amount } = externalProduct

  return (
    <div className="order-details">
      <FieldSectionHeader
        style={{ paddingTop: 0, paddingBottom: '.5em', fontSize: 18, color: '#1B2732' }}
      >
        <ShoppingBag size={18} />
        Order Summary
      </FieldSectionHeader>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr className="product-summary">
            <td style={{ textAlign: 'left' }}>{name}</td>
            <td>
              {money(amount)} x {quantity}
            </td>
          </tr>
          <tr>
            <td style={{ width: '50%' }}>Subtotal</td>
            <td>{money(subtotal)}</td>
          </tr>
          <tr className="shipping">
            <td>Shipping</td>
            <td>
              {loading ? (
                <AnimatedLoader />
              ) : dynamicShipping && !shippingTotal ? (
                'Enter shipping address'
              ) : (
                money(shippingTotal)
              )}
            </td>
          </tr>
          {salesTaxAmount && (
            <tr className="sales-tax">
              <td>Sales Tax</td>
              <td>{money(salesTaxAmount)}</td>
            </tr>
          )}
          <tr className="total">
            <td>
              <strong>Total</strong>
            </td>
            <td>{money(totalAmount)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
