import { gql } from 'apollo-boost'

export const CreatePrivateShopifyShop = gql`
  mutation CreatePrivateShopifyShop(
    $shopifyDomain: String!
    $shopifyToken: String!
    $apiSecret: String!
    $sharedSecret: String!
  ) {
    createPrivateShopifyShop(
      input: {
        shopifyDomain: $shopifyDomain
        shopifyToken: $shopifyToken
        apiSecret: $apiSecret
        sharedSecret: $sharedSecret
      }
    ) {
      shop {
        id
      }
    }
  }
`

export const UpdateApiKeyAuthorization = gql`
  mutation UpdateApiKeyAuthorization(
    $provider: String!
    $apiKey: String!
    $apiSecret: String
  ) {
    updateApiKeyAuthorization(
      input: { provider: $provider, apiKey: $apiKey, apiSecret: $apiSecret }
    ) {
      authorization {
        id
        apiKey
        apiSecret
        provider
        meta
      }
    }
  }
`

export const IntegrationFragment = gql`
  fragment IntegrationFragment on Integration {
    configured
    id
    provider
    configurable
    fields

    authorization {
      uid
      apiKey
      apiSecret
    }
  }
`

export const FetchIntegrations = gql`
  query Integrations {
    integrations {
      ...IntegrationFragment
    }
  }
  ${IntegrationFragment}
`

export const FetchIntegration = gql`
  query Integration($id: ID!) {
    integration(id: $id) {
      ...IntegrationFragment
    }
  }
  ${IntegrationFragment}
`

export const UpdateIntegration = gql`
  mutation UpdateIntegration($id: ID!, $fields: JSON!) {
    updateIntegration(input: { id: $id, fields: $fields }) {
      integration {
        id
        fields
      }
    }
  }
`
