import React, { useState } from 'react'
import { ChevronRight, ChevronDown } from 'react-feather'

import { Flex, Header } from '../components/shared'

export default function FormSection({
  title,
  children,
  initialExpanded = true,
  icon,
  contentStyle,
}) {
  const [expanded, setExpanded] = useState(initialExpanded)

  const iconProps = {
    color: '#687A8A',
  }
  const cStyle = {
    paddingLeft: '1.5em',
    marginTop: '.8em',
    ...contentStyle,
  }

  return (
    <div>
      <Flex onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
        <Header
          size="h4"
          content={title}
          icon={icon}
          style={{
            margin: 0,
            color: '#687A8A',
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <div style={{ marginLeft: '10px', marginTop: 4 }}>
          {expanded ? <ChevronDown {...iconProps} /> : <ChevronRight {...iconProps} />}
        </div>
      </Flex>
      <div style={cStyle}>{expanded && children}</div>
    </div>
  )
}
