import React from 'react'
import { Link } from 'react-router-dom'
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { Formik, Form } from 'formik'

import useStripeHook from '../../hooks/useStripe'
import SubmitButton from '../shared/SubmitButton'
import { Loader } from '../shared'
import PaymentForm from '../Collect/PaymentForm'
import { mutation } from '../../graphql/client'
import { UpdateCustomerData } from '../../graphql/queries/Collect'
import { useAppContext } from '../../contexts/app'

export default function ContactPaymentForm({ contact }) {
  const stripe = useStripeHook()
  const { customer } = useAppContext()

  if (!stripe) return <Loader />

  const handleSubmit = (payload, form, actions) => {
    const params = {
      ...payload,
      customerId: customer.publicId,
    }
    mutation(UpdateCustomerData, params)
      .then((res) => {
        actions.setSubmitting(false)
        // onSuccess()
      })
      .catch((err) => {
        actions.setSubmitting(false)
        console.log('error', err)
      })
  }

  return (
    <div style={{ marginTop: '1em', maxWidth: '60%' }}>
      <h3>Payment Details</h3>

      {!stripe ? (
        <p>
          <Link to="/integrations">
            Configure the Stripe integration
          </Link>
        </p>
      ) : (
        <PaymentForm
          stripe={stripe}
          recipient={contact}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  )
}

function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()
  const handleSubmit = async (values, helpers) => {
    helpers.setSubmitting(true)
    const { paymentMethod, error } = await stripe.createPaymentMethod(
      {
        type: 'card',
        card: elements.getElement(CardElement),
      },
    )
    if (paymentMethod) {
    }
    console.log('submit')
  }
  return (
    <div>
      <Formik onSubmit={handleSubmit} initialValues={{}}>
        <Form>
          <CardElement />
          <SubmitButton
            text="Save Card"
            style={{ marginTop: '1em' }}
          />
        </Form>
      </Formik>
    </div>
  )
}
