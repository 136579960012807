import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function setCookie(key, value, exp) {
  if (!exp) {
    const d = new Date()
    d.setFullYear(d.getFullYear() + 1)
    exp = d
  }
  cookies.set(key, value, { expires: exp, path: '/' })
}

export function getCookie(key) {
  return cookies.get(key)
}
