import React from 'react'
import { Link } from 'react-router-dom'

import { useQuery } from '../../../graphql/client'
import { FetchWidgets } from '../../../graphql/queries/Widgets'
import {
  Button,
  Loader,
  Card,
  Flex,
  DataTable,
  IconButton,
  DeleteButton,
  PageHeader,
} from '../../shared'

export default function ListWidgets({}) {
  const { loading, data } = useQuery(FetchWidgets)

  if (loading) return <Loader />

  return (
    <div>
      <PageHeader text="Widgets">
        <p>
          Collection widgets can be placed on a page to collect customer phone numbers to
          sign up for SMS marketing. Widgets are linked to a list.
        </p>
      </PageHeader>
      <Card>
        <Link to="/widgets/new">
          <Button text="New Widget" />
        </Link>
        <DataTable
          data={data.widgets}
          values={(item) => [
            item.title,
            <Link to={`/widgets/${item.id}/edit`}>{item.campaignList.name}</Link>,
            item.widgetType,
          ]}
          headers={['Title', 'List', 'Type']}
          actions={(item, i) => (
            <Flex>
              <Link to={`/widgets/${item.id}/edit`}>
                <IconButton name="edit" />
              </Link>
            </Flex>
          )}
        />
      </Card>
    </div>
  )
}
