import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import isEmpty from 'lodash.isempty'
import { AlertTriangle } from 'react-feather'

import Label from './Label'
import FormNote from './FormNote'

const FieldRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.cols === 1 ? 'auto' : '60% auto')};
  column-gap: 0.7em;
`

export const FormErrorWrap = styled.div`
  padding: 1em;
  color: #d13939;
  line-height: 1.3em;
  align-items: center;
  padding: 0px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  display: flex;

  svg {
    margin-right: 0.5em;
  }
`

const FormFieldWrap = styled.div``

export function FormErrors({ children }) {
  return (
    <FormErrorWrap style={{ marginTop: '1em' }}>
      <AlertTriangle size={20} />
      <span>{children}</span>
    </FormErrorWrap>
  )
}

export default function FormField({
  label,
  note,
  children,
  cols,
  hideErrorMessage,
  ...rest
}) {
  const [field, meta, helpers] = useField(rest)
  const error = meta && meta.error
  let classes = 'field text-field'
  if (error) classes += ' error'

  return (
    <FormFieldWrap className={classes}>
      {label && <Label>{label}</Label>}
      <FieldRow cols={cols}>
        <div className="field-wrap">{children}</div>
        {note && <FormNote>{note}</FormNote>}
      </FieldRow>
      {meta.touched && !isEmpty(meta.error) && !hideErrorMessage && (
        <FormErrors>{meta.error}</FormErrors>
      )}
    </FormFieldWrap>
  )
}
