import { useAppContext } from '../contexts/app'

const useCurrentUser = () => {
  const ctx = useAppContext()

  if (ctx && ctx.currentUser) {
    return ctx.currentUser
  }
}

export default useCurrentUser
