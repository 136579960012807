import React, { useState } from 'react'
import Card from '../shared/Card'
import { Link } from 'react-router-dom'
import { IconButton } from '../shared'
import { CheckCircle } from 'react-feather'
import Button from '../shared/Button'

export function IntegrationCard({
  logo,
  onClick,
  modalContent,
  integration,
  url,
  disabled,
  onReconnect,
}) {
  const [showModal, setShowModal] = useState(false)
  const handleClick = (e) => {
    e.preventDefault()
    if (modalContent) {
      if (integration && url) {
        // reconnecting
        onReconnect && onReconnect(integration)
      } else {
        setShowModal(true)
      }
    } else {
      onClick(e)
    }
  }

  return (
    <Card
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {modalContent &&
        showModal &&
        React.cloneElement(modalContent, {
          onClose: () => setShowModal(false),
          integration,
        })}
      {integration && integration.configurable && (
        <Link to={`/integrations/${integration.id}/configure`}>
          <IconButton
            name="settings"
            iconProps={{
              size: 18,
              style: {
                position: 'absolute',
                top: 10,
                left: 10,
              },
            }}
          />
        </Link>
      )}
      {integration && (
        <CheckCircle
          color="green"
          size={18}
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        />
      )}
      <figure
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={logo} width={94} alt="Logo" style={{ marginBottom: 10 }} />
      </figure>
      <Button
        disabled={disabled}
        fullWidth
        text={disabled ? 'Coming Soon' : integration && url ? 'Reconnect' : 'Configure'}
        onClick={handleClick}
        style={{ margin: '0 auto', flex: '0 auto' }}
      />
    </Card>
  )
}
