import React from 'react'
import { Formik, Form } from 'formik'
import { gql } from 'apollo-boost'

import { Loader, SubmitButton, TextField } from '../shared'
import Modal from '../shared/Modal'
import { createPrivateShop } from './actions'
import Label from '../shared/Label'
import Flex from '../shared/Flex'
import { useQuery } from '../../graphql/client'

const query = gql`
  query Shop {
    shop {
      id
      shopHost
      shopifyToken
      apiSecret
      sharedSecret
    }
  }
`

export default function ShopifyPrivateModal({ onSubmit, onClose, integration }) {
  const { loading, data } = useQuery(query)

  const handleSubmit = async (values, formik) => {
    try {
      const { shopifyToken, shopifyDomain, apiSecret, sharedSecret } = values
      const params = {
        shopifyToken,
        shopifyDomain: `${shopifyDomain}.myshopify.com`,
        apiSecret,
        sharedSecret,
      }
      await createPrivateShop(params)
      onClose()
    } catch (err) {
      console.log('error', err)
    } finally {
      formik.setSubmitting(false)
    }
  }

  if (loading) return <Loader />
  const shop = data.shop

  return (
    <div>
      <Modal onClose={onClose} open showClose title="Configure Private Shopify App">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ ...shop, shopifyDomain: shop?.shopHost }}
        >
          <Form>
            <p>
              Enter your Shopify API key and password to connect your Shopify account to
              Replii.
            </p>
            <p>
              <a
                href="https://www.notion.so/Shopify-Private-App-Configuration-714619ab04bd48eca3990dd363de3cce"
                target="_blank"
              >
                Click here
              </a>{' '}
              to learn how to configure a Shopify private app.
            </p>
            <div className="field text-field">
              <Label>Shopify Store Domain</Label>
              <Flex>
                <TextField name="shopifyDomain" rootStyle={{ marginBottom: 0 }} />
                <div
                  style={{ marginLeft: 8, fontWeight: 600, color: '#6b6b6b', fontSize: 15 }}
                >
                  .myshopify.com
                </div>
              </Flex>
            </div>
            <TextField name="shopifyToken" label="Shopify API Key" />
            <TextField name="apiSecret" label="Shopify Password" />
            <TextField name="sharedSecret" label="Shared Secret" />
            <SubmitButton fullWidth text="Save Shopify Details" />
          </Form>
        </Formik>
      </Modal>
    </div>
  )
}
