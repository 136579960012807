import React from 'react'
import { useFormikContext } from 'formik'

import Button from './Button'

export default function SubmitButton({ onClick, ...props }) {
  const formikContext = useFormikContext()
  const errors = formikContext?.errors
  const isSubmitting = formikContext?.isSubmitting
  const buttonDisabled = props.disabled || isSubmitting

  return (
    <Button type="submit" {...props} disabled={buttonDisabled} loading={isSubmitting} />
  )
}

export const RefSubmitButton = ({ ref, ...props }) => {
  const handleClick = (e) => {
    e.preventDefault()
    return ref.current.submitForm()
  }

  const isSubmitting = ref?.current?.isSubmitting

  return (
    <Button
      type="submit"
      {...props}
      disabled={isSubmitting}
      loading={isSubmitting}
      onClick={handleClick}
    />
  )
}
