import React, { useState, useRef } from 'react'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { ChevronDown } from 'react-feather'

import './Select.scss'

import { SelectWrap, SelectMenu } from './SimpleSelect'

/**
 * Wrapper for react-select Select component to normalize callback format
 * to work nicely with Formik.
 * @param  {} props
 */
const Select = ({
  options,
  menu,
  label,
  name,
  placeholder,
  uncontrolled = false,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const { setFieldValue } = useFormikContext()
  const downshiftRef = useRef(null)
  const value = props.value || ''
  const [field, meta, helpers] = useField({ name, value, ...props })

  // function handleChange({ value, label }, fieldProps) {
  //   downshiftRef.current.blur()
  //   setFieldValue(name, value)
  //   onChange && onChange({ name, value })
  //   setOpen(false)
  // }

  const itemToString = (item) => {
    return item ? item.label || '' : ''
  }

  /**
   * the label value of the currently selected item
   */
  function selectedValueLabel() {
    const val = props.value || field.value
    return val && options.find((opt) => opt.value === val)?.label
  }

  function filterItems(inputValue, items) {
    const val = field.value || props.value // sometimes field.value isn't set

    // if we open the select box with a value selected, we should show all items
    if (val && selectedValueLabel() === inputValue) {
      return items
    }
    return items.filter(
      (item) =>
        !inputValue ||
        item.label.toString().toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const val = value || field.value
  const initialItem = value || field.value ? options.find((opt) => opt.value === val) : null

  const handleInputFocus = () => {
    setOpen(true)
    downshiftRef.current.select()
  }

  const onClick = () => {
    downshiftRef.current.focus()
    setOpen(!open)
  }

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      // handle a weird downshift case where hitting the excape key wants
      case Downshift.stateChangeTypes.keyDownEscape:
        setOpen(false)
        downshiftRef.current.blur()
        return {
          ...changes,
          ...state,
          isOpen: false,
          inputValue: state.selectedItem.label,
        }
      case Downshift.stateChangeTypes.clickItem:
        setOpen(false)
        if (!uncontrolled) {
          setFieldValue(name, changes.selectedItem.value, true)
        }
        onChange && onChange({ name, value: changes.selectedItem.value })
        downshiftRef.current.blur()
        return {
          ...state,
          ...changes,
          isOpen: false,
        }
      default:
        return changes
    }
  }

  const handleInputBlur = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Downshift
      initialSelectedItem={initialItem}
      itemToString={itemToString}
      onClick={onClick}
      onFocus={onClick}
      onOuterClick={() => setOpen(false)}
      isOpen={open}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        getToggleButtonProps,
        inputValue,
        isOpen,
        selectedItem,
        highlightedIndex,
      }) => (
        <SelectWrap className="downshift-select" {...getRootProps()}>
          {label && <label>{label}</label>}
          <div className="select-wrap" {...getToggleButtonProps()}>
            <input
              {...getInputProps({
                onFocus: handleInputFocus,
                ref: downshiftRef,
                onBlur: handleInputBlur,
                placeholder,
              })}
            />
            <ChevronDown
              style={{
                color: '#76787b',
                marginRight: 10,
                marginTop: 3,
                cursor: 'pointer',
              }}
              onClick={onClick}
            />
            <SelectMenu className={`menu ${isOpen && 'open'}`} {...getMenuProps()}>
              {isOpen &&
                menu &&
                menu({
                  getItemProps,
                  selectedItem,
                  highlightedIndex,
                  inputValue,
                  ...props,
                })}
              {isOpen && !menu
                ? filterItems(inputValue, options).map((item, index) => (
                    <li
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index
                              ? '#f3f3f3'
                              : 'var(--color-field-bg)',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}
                    >
                      <div className="label">{item.label}</div>
                    </li>
                  ))
                : null}
            </SelectMenu>
          </div>
        </SelectWrap>
      )}
    </Downshift>
  )
}

Select.propTypes = {
  menu: PropTypes.func, // optional menu render prop, to be rendered instead of options
  options: PropTypes.arrayOf(PropTypes.object), // array of form options
}

export default Select
