import { gql } from 'apollo-boost'

export const FetchCampaignList = gql`
  query CampaignList($id: ID!) {
    list(id: $id) {
      id
      name
      default
      defaultCampaignId
    }
  }
`

export const FetchContactsByList = gql`
  query Contacts($campaignListId: ID!) {
    list(id: $campaignListId) {
      id
      name
    }
    contacts(campaignListId: $campaignListId) {
      id
      name
      email
      phone
      externalCustomer {
        id
        name
        email
      }
    }
  }
`

export const FetchCampaignLists = gql`
  query CampaignLists {
    lists {
      id
      name
      default
      recipientCount
      createdAt
      defaultCampaign {
        id
        name
      }
    }
  }
`

export const CreateCampaignList = gql`
  mutation CreateCampaignList($attributes: CampaignListInput!) {
    createCampaignList(input: { attributes: $attributes }) {
      campaignList {
        id
        name
        default
      }
    }
  }
`

export const UpdateCampaignList = gql`
  mutation UpdateCampaignList($id: ID!, $attributes: CampaignListInput!) {
    updateCampaignList(input: { attributes: $attributes, id: $id }) {
      campaignList {
        id
        name
        default
      }
    }
  }
`

export const DeleteCampaignList = gql`
  mutation DeleteCampaignList($id: Int!) {
    deleteCampaignList(input: { id: $id }) {
      campaignList {
        id
      }
    }
  }
`
