import { CardElement } from '@stripe/react-stripe-js'

/**
 * Tokenize the stripe card.
 *
 * @param {Object} values formik values
 * @param {Formik} actions the formik bag
 */
export const tokenizeCard = (values, actions, elements, stripe) => {
  const { name, email } = values

  actions.setSubmitting(true)

  const cardElement = elements.getElement(CardElement)
  const attrs = { name, email, ...cardElement }

  return new Promise((resolve, reject) => {
    stripe.createToken(attrs).then((result) => {
      if (result.error) {
        actions.setSubmitting(false)
        return reject(result.error)
      }

      // doSubmit(result.token.id, values, actions)
      resolve(result)
    })
  })
}
