import { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'

import { useQuery } from '../graphql/client'
import { FetchIntegrations } from '../graphql/queries/Integrations'

const STRIPE_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_51IEKHdHeBxAynx3RfHq8rM4hdV8fGjVSfuDgfFVUQvddFxGiLHrT1CPNXUdy9vb1QuCP6vBaPo9YTNQnY681Bnk300eDM2HNE0'
    : 'pk_test_51IEKHdHeBxAynx3RrHMnyOZwxe0FWf9YYs6REOFheEiK7tI36eljIwdhjtR5sCaE4m3KnXUDlvFwlSG2Hv9WzQNY002quACfcb'

export const useLoadStripe = (stripeAccountId) => {
  const [stripe, setStripe] = useState(null)
  useEffect(() => {
    console.log('loading stripe', stripeAccountId)
    if (!stripeAccountId) return
    loadStripe(STRIPE_KEY, { stripeAccount: stripeAccountId }).then((res) => {
      setStripe(res)
    })
  }, [stripeAccountId])

  return stripe
}

const useStripe = (stripeId) => {
  const [stripe, setStripe] = useState(null)
  const [stripeAccountId, setStripeAccountId] = useState(stripeId)
  const { loading, data } = useQuery(FetchIntegrations)

  useEffect(() => {
    console.log('loading stripe', stripeAccountId)
    if (!stripeAccountId) return
    loadStripe(STRIPE_KEY, { stripeAccount: stripeAccountId }).then((res) => {
      setStripe(res)
    })
  }, [stripeAccountId])

  if (loading) return false

  if (!stripeAccountId) {
    const stripeIntegration = data.integrations.find((i) => i.provider === 'stripe_connect')
    if (stripeIntegration && !stripeAccountId) {
      setStripeAccountId(stripeIntegration.authorization.uid)
    }
  }

  return stripe
}

export default useStripe
