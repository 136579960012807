import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Settings } from 'react-feather'

import { Card, MainWrap, PageHeader, LastSaved, SubmitButton, Flex } from '../shared'
import { useAppContext } from '../../contexts/app'
import { mutation } from '../../graphql/client'
import { UpdateCampaignSender } from '../../graphql/queries/CampaignSenders'
import SMSBodyInput from '../CampaignMessage/SMSBodyInput'

export default function MessagingSettings() {
  const { campaignSender } = useAppContext()
  const [lastSaved, setLastSaved] = useState(null)

  const onSubmit = (values, formik) => {
    const {messageDefaultGreeting, ...vals} = values
    const submitVals = {...vals,
      settings: { messageDefaultGreeting }
    }
    mutation(UpdateCampaignSender, { attributes: submitVals })
      .then((res) => {
        formik.setSubmitting(false)
        setLastSaved(new Date())
      })
      .catch((err) => {
        formik.setSubmitting(false)
        console.log('err', err)
      })
  }

  return (
    <MainWrap>
      <PageHeader text="Settings" breadcrumb="Messaging" icon={<Settings size={20} />} />
      <Card>
        <Formik initialValues={{ ...campaignSender, ...campaignSender.settings }} onSubmit={onSubmit}>
          <Form>
            <SMSBodyInput
              name="confirmationThankYou"
              label="Post-Signup Confirmation Message"
              placeholder="Woohoo! You're signed up to receive messages from us! Get psyched!"
              note={
                <span>
                  A short message sent to new subscribers after they subscribe/opt-in.
                  <br />
                  <br />
                  <em>
                    Note: We will append the standard opt-out instructions to the end of
                    this message.
                  </em>
                </span>
              }
            />
            <SMSBodyInput
              name="orderConfirmMessage"
              label="Order Confirmation Message"
              placeholder="Got it, order received. Look out for an email receipt and delivery instructions in your inbox."
              note={
                <span>
                  When someone orders over SMS, we'll respond to the customer with this
                  message. You can override this in a campaign message.
                </span>
              }
            />

            <SMSBodyInput
              name="messageDefaultGreeting"
              label="Default Message Greeting"
              placeholder="Hi {{first_name | default: ''}}!"
              note={
                <span>
                  This is your default greeting to use in campaign messages. It's available as a template variable as <code dangerouslySetInnerHTML={{__html: "{{ default_greeting }}"}} />
                </span>
              }
            />
            <Flex>
              <SubmitButton text="Update Messaging Settings" style={{ maxWidth: 300 }} />
              {lastSaved && <LastSaved style={{ marginLeft: '1em' }} time={lastSaved} />}
            </Flex>
          </Form>
        </Formik>
      </Card>
    </MainWrap>
  )
}
