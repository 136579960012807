import React, { useContext, useState } from 'react'
import { Formik, Form } from 'formik'
import { useParams, useHistory, Link } from 'react-router-dom'

import { useQuery, mutation } from '../../../graphql/client'
import {
  FetchWidgetById,
  CreateWidget,
  UpdateWidget,
} from '../../../graphql/queries/Widgets'
import {
  Loader,
  Card,
  PageHeader,
  TextField,
  FormField,
  TextArea,
  SubmitButton,
  Grid,
} from '../../shared'
import NumberField from '../../shared/NumberField'
import AppContext from '../../../contexts/app'
import SdkCode from './SdkCode'
import SelectField from '../../shared/SelectField'
import { PermanentWidget } from '../../Widget/PermanentWidget'
import SuccessMessage from '../../shared/SuccessMessage'
import SMSBodyInput from '../../CampaignMessage/SMSBodyInput'

const emailRequirementOpts = [
  { value: 'required', text: 'Required' },
  { value: 'optional', text: 'Optional' },
  { value: '', text: 'Not Required' },
]

const widgetTypes = [
  { value: 'delayed_modal', text: 'Delayed Modal' },
  { value: 'permanent', text: 'Permanent' },
]

export default function WidgetFormHome() {
  const { id } = useParams()
  if (id) {
    return <EditWidget id={id} />
  }

  return <WidgetForm widget={{ widgetType: 'permanent' }} />
}

function EditWidget({ id }) {
  const { loading, data } = useQuery(FetchWidgetById, { id })
  if (loading) return <Loader />
  return <WidgetForm widget={data.widget} />
}

function WidgetForm({ widget }) {
  const { lists } = useContext(AppContext)
  const history = useHistory()
  const [success, setSuccess] = useState(null)

  const handleSubmit = async (attributes, formik) => {
    const mut = widget && widget.id ? UpdateWidget : CreateWidget
    try {
      const { payload } = await mutation(mut, { attributes, id: widget && widget.id })
      setSuccess('Widget saved successfully.')
      if (!widget?.id) {
        history.replace(`/widgets/${payload.id}/edit`)
      }
    } catch (err) {}

    formik.setSubmitting(false)
  }

  return (
    <div>
      <PageHeader text="Widgets" breadcrumb={widget ? 'Edit' : 'New'} />

      <Formik onSubmit={handleSubmit} initialValues={widget || {}}>
        {({ values }) => {
          return (
            <Grid cols={2} columnGap="1em" style={{ gridTemplateColumns: '75% auto' }}>
              <Card>
                {success && (
                  <div style={{ marginBottom: '1em' }}>
                    <SuccessMessage
                      showDismiss
                      onDismiss={() => setSuccess(null)}
                      title="Success"
                      message={success}
                    />
                  </div>
                )}
                <Form>
                  <SelectField
                    name="campaignListId"
                    label="List"
                    note="The list signups will be added to"
                    items={lists.map((list) => ({ value: list.id, text: list.name }))}
                  />
                  <SelectField
                    name="widgetType"
                    label="Widget Type"
                    note="The type of widget. Permanent lives on a page permanently. Delayed Modals take over the page after a delay."
                    items={widgetTypes}
                  />
                  <FormField
                    name="delayInSeconds"
                    label="Delay (in seconds)"
                    note="Number of seconds a delayed modal will delay before showing to the user."
                  >
                    <NumberField
                      coerceNumber
                      disabled={values.widgetType !== 'delayed_modal'}
                      name="delayInSeconds"
                      placeholder="3"
                      prefix=""
                    />
                  </FormField>
                  <TextField
                    name="title"
                    label="Widget Title"
                    note="Will be shown as the top of your widget."
                  />
                  <TextField
                    name="buttonText"
                    label="Button Text"
                    note="Text shown on the subscribe button. Defaults to 'Subscribe'"
                  />
                  <TextArea
                    name="bodyHtml"
                    label="Widget Body"
                    note="You can use HTML in this section."
                  />
                  {values.widgetType !== 'delayed_modal' && (
                    <TextField
                      required={values.widgetType !== 'delayed_modal'}
                      name="domTargetSelector"
                      label="Target Selector"
                      note="A DOM selector the widget will be inserted into."
                    />
                  )}

                  <SelectField
                    name="emailRequirement"
                    items={emailRequirementOpts}
                    label="Email Required?"
                    note="If none, email address will not be collected. If optional, customers have the option to enter it but are not required."
                  />

                  <SMSBodyInput
                    name="afterOptinMessage"
                    label="After Opt-In Message"
                    placeholder="Woohoo! You're signed up to receive messages from us! Get psyched!"
                    note={
                      <span>
                        Message users receive after they signup and opt-in through this
                        widget. This overrides the{' '}
                        <Link to="/settings/messaging">global confirmation message</Link>.
                        <br />
                        <br />
                        <em>
                          Note: We will append the standard opt-out instructions to the end
                          of this message.
                        </em>
                      </span>
                    }
                  />

                  <SubmitButton
                    text="Save Widget"
                    type="submit"
                    style={{ marginTop: '2em' }}
                  />
                </Form>
              </Card>
              {widget && widget.id && <WidgetPreview widget={values} />}
            </Grid>
          )
        }}
      </Formik>

      {widget && widget.id && (
        <div style={{ marginTop: '2em' }}>
          <Grid cols={2} style={{ gridTemplateColumns: '75% auto' }}>
            <div>
              <PageHeader text="Code Install Instructions" />
              <Card style={{ marginTop: '1em' }}>
                <p>
                  Copy the below code into the <code>&lt;head&gt;&lt;/head&gt;</code>{' '}
                  section of your website.{' '}
                  <a
                    href="https://www.notion.so/Install-Replii-Collection-Widgets-74066b03f8454ba0b1742b8d09c8e8c8"
                    target="_blank"
                  >
                    Learn more about installing widgets
                  </a>
                </p>
                <SdkCode widget={widget} />
              </Card>
            </div>
          </Grid>
        </div>
      )}
    </div>
  )
}

function WidgetPreview({ widget }) {
  return (
    <Card>
      <div style={{ pointerEvents: 'none' }}>
        <PermanentWidget widget={widget} />
      </div>
    </Card>
  )
}
