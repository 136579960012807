import React, { useState } from 'react'
import ReactPopover from 'react-popover'
import styled from 'styled-components'
import { AlertTriangle } from 'react-feather'

// const PopContainer = styled.div`
//   position: absolute;
//   right: ${(props) => -(props.from.width + props.from.x + 10)};
//   background-color: white;
//   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
// `

const PopContainer = styled.div`
  position: relative;
`

export const ErrorPopContainer = styled(PopContainer)`
  display: grid;
  grid-template-columns: 18px auto;
  align: center;
  column-gap: 1em;
  svg {
    color: var(--color-input-error-text);
  }
`

const modalRoot = document.getElementById('modal-root')

const style = {
  backgroundColor: '#647DD6',
  boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.3)',
  padding: '1.2em 1.2em',
  color: 'white',
  fontWeight: 500,
  borderRadius: 4,
  zIndex: 100,
}

const errorStyle = {
  ...style,
  backgroundColor: 'white',
  boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.15)',
  border: '1px solid #CFCFCF',
  color: 'var(--color-input-error-text)',
}

const rootStyle = {
  position: 'absolute',
}

// function PopContainer({ from, children }) {
//   const right = from.width + from.x + 10

//   return ReactDOM.createPortal(<div style={style}>{children}</div>, modalRoot)
// }

export default function Popover({
  open,
  children,
  body,
  disabled,
  rootStyle,
  place = 'right',
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(open)
  if (disabled) {
    return children
  }

  const onMouseOver = () => {
    open && setIsOpen(true)
  }

  return (
    <ReactPopover isOpen={isOpen} place={place} style={style} body={body} {...rest}>
      <PopContainer
        style={rootStyle}
        onMouseOver={onMouseOver}
        onMouseOut={() => setIsOpen(false)}
      >
        {children}
      </PopContainer>
    </ReactPopover>
  )
}

export function ErrorPopover({ place = 'below', children, error, ...rest }) {
  const [isOpen, setIsOpen] = useState(!!error)

  const onMouseOver = () => {
    error && setIsOpen(true)
  }

  const body = (
    <ErrorPopContainer>
      {error && <AlertTriangle size={18} />}
      {error}
    </ErrorPopContainer>
  )

  return (
    <ReactPopover
      isOpen={isOpen}
      place={place}
      style={errorStyle}
      body={error && body}
      {...rest}
    >
      <div onMouseOver={onMouseOver} onMouseOut={() => setIsOpen(false)}>
        {children}
      </div>
    </ReactPopover>
  )
}

function Pop({ popover, target }) {
  const [showPopover, setShowPopover] = React.useState(false)
  const [from, setFrom] = useState(null)

  const onHover = (e) => {
    setFrom(e.target.getBoundingClientRect())
    setShowPopover(true)
  }
  const onMouseOut = () => {
    setShowPopover(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      {showPopover && <PopContainer from={from}>{popover}</PopContainer>}
      {React.cloneElement(target, {
        onMouseOver: onHover,
        onMouseOut: onMouseOut,
      })}
    </div>
  )
}
