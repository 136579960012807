import styled from 'styled-components'

const StatsBar = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.cols || 3}, 1fr)`};
  column-gap: 1px;
  background-color: #d7d7d7;
  margin: 1em 0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  grid-auto-columns: min-content;
`

export default StatsBar
