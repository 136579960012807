import { gql } from 'apollo-boost'

export const HomeQuery = gql`
  query CurrentUser {
    customer {
      id
      name
      publicId
      stripeId
      featureFlags
      salesTaxRate
    }

    integrations {
      configured
      id
      provider
      fields
    }

    campaignSender {
      confirmationThankYou
      orderConfirmMessage
      settings
      incomingNumbers {
        id
        number
      }
    }

    currentUser {
      id
      email
      admin
      customerId
    }

    lists {
      id
      name
      default
    }

    externalProducts {
      id
      name
      amount
      externalId
    }
  }
`
