import React from 'react'
import { useFormikContext } from 'formik'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components'

const Container = styled.div`
  border-radius: 6px;
  background-color: var(--color-input-error-background);
  color: var(--color-field-border-error);
  border: 1px solid var(--color-field-border-error);
  padding: 1em;
  display: flex;
  font-weight: 500;
  align-items: center;

  svg {
    color: var(--color-field-border-error);
  }

  ul {
    margin: 0;
  }
`

const FormErrorSummary = () => {
  const formik = useFormikContext()
  const { errors } = formik

  if (!Object.keys(errors).length) return null

  return (
    <Container>
      <AlertTriangle />
      <ul>
        {Object.entries(errors).map(([key, err]) => (
          <li key={key}>{err}</li>
        ))}
      </ul>
    </Container>
  )
}

export default FormErrorSummary
