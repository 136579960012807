import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Settings } from 'react-feather'

import { useAppContext } from '../../contexts/app'
import {
  SubmitButton,
  TextField,
  MainWrap,
  PageHeader,
  LastSaved,
  Card,
  Flex,
} from '../shared'
import { UpdateCustomer } from '../../graphql/queries/Customers'
import { mutation } from '../../graphql/client'
import { updateCustomer } from './actions'

export default function OrganizationSettings() {
  const appContext = useAppContext()
  const [lastSaved, setLastSaved] = useState(null)

  const handleSubmit = (attributes, formik) => {
    updateCustomer(attributes, formik).then((res) => {
      setLastSaved(new Date())
    })
  }

  return (
    <MainWrap>
      <PageHeader text="Settings" breadcrumb="Organization" icon={<Settings size={20} />} />

      <Card>
        <Formik initialValues={{ ...appContext.customer }} onSubmit={handleSubmit}>
          <Form>
            <TextField
              name="name"
              placeholder="Company Name"
              label="Organization Name"
              note="This will be used anytime your organization name is shown, such as in the legal disclaimer shown with collection widgets."
            />
            <Flex>
              <SubmitButton text="Save Account Details" />
              {lastSaved && <LastSaved time={lastSaved} style={{ marginLeft: '1em' }} />}
            </Flex>
          </Form>
        </Formik>
      </Card>
    </MainWrap>
  )
}
