import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useField, useFormikContext } from 'formik'
import { useDropzone } from 'react-dropzone'
import { Upload } from 'react-feather'

import { uploadImage } from './actions'
import { SmallButton } from '../shared/Button'
import { Flex } from '../shared'

const SIZE_LIMIT = 525000 // 525kb

const ERROR_MESSAGES = {
  'file-too-large':
    'The file you selected is too large. Please select a file smaller than 525kb.',
}

const UploadWrap = styled.div`
  background-color: #fafafa;
  border: ${(props) => (props.error ? '1px solid #a52f2f' : '1px solid #C9CDDA')};
  border-radius: 6px;
  text-align: center;
  padding: 1em;
  display: grid;
  grid-column-rows: 1fr 1fr 1fr;
  row-gap: 0.8em;
`

export default function ImageUploader({ s3SignUrl, onFinish, name }) {
  const { setFieldValue, submitForm, values } = useFormikContext()
  const [field, meta, helpers] = useField(name)

  const doUpload = async (file) => {
    const key = `${s3SignUrl.key}${file.name}`
    uploadImage(file, key, s3SignUrl, {
      onProgress: (file, e) => {
        console.log('progress', e.percent)
      },
    }).then((res) => {
      helpers.setValue(key)
      helpers.setTouched(true)
      onFinish && onFinish(file)
    })
  }
  const onDrop = useCallback((files) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')

    reader.onload = (e) => {
      // Do whatever you want with the file contents
      // const binaryStr = reader.result
      const file = files[0]
      const dataURL = e.target.result
      const img = new Image()
      img.src = dataURL

      img.onload = () => {
        if (img.width > img.height) {
          helpers.setTouched(true)
          return helpers.setError('Please use a portrait or square image.')
        }

        setFieldValue('imageUrl', dataURL)
        doUpload(file)
      }
    }
    reader.readAsDataURL(files[0])
  }, [])

  const onDropRejected = (events) => {
    console.log('rejected', events)
    events.forEach((event) => {
      const error = event.errors && event.errors[0]
      if (error) {
        helpers.setTouched(true)
        return helpers.setError(ERROR_MESSAGES[error.code])
      }
    })
  }

  const removeImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    helpers.setValue(null)
    setFieldValue('imageUrl', null)
    helpers.setTouched(true)
    submitForm()
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: onDropRejected,
    accept: 'image/*',
    multiple: false,
    maxFiles: 1,
    maxSize: SIZE_LIMIT,
  })

  return (
    <UploadWrap {...getRootProps()}>
      <input {...getInputProps()} />

      <div style={{ color: 'var(--color-primary)', fontWeight: 600, lineHeight: '1em' }}>
        {isDragActive ? <div>Drop the file here...</div> : <div>Drag a file to upload</div>}
      </div>
      <span style={{ fontSize: 12, lineHeight: '1em', color: '#747C83' }}>or</span>

      <Flex style={{ margin: '0 auto', justifyContent: 'center' }}>
        <SmallButton
          icon="image"
          iconProps={{ size: 14, style: { marginRight: 10 } }}
          text="Select Image"
        />
        {values.imageUrl && (
          <SmallButton
            text="Remove Image"
            onClick={removeImage}
            style={{
              marginLeft: '1em',
              fontWeight: 500,
              backgroundColor: 'var(--color-steel-blue)',
            }}
          />
        )}
      </Flex>
    </UploadWrap>
  )
}
