import React from 'react'
import NumberFormat from 'react-number-format'
import { useField } from 'formik'

import Input from './Input'
import Disabled from '../shared/Disabled'

export default function NumberField({
  value,
  name,
  disabled,
  placeholder = '$ ...',
  onBlur,
  coerceNumber,
  ...rest
}) {
  const [field, meta, helpers] = useField({ name, value, ...rest })

  function handleChange({ formattedValue, value, floatValue }) {
    const val = coerceNumber ? floatValue : value
    helpers.setValue(val)
  }

  const handleBlur = (e) => {
    field.onBlur(e)
    onBlur && onBlur(e)
  }

  const opts = {
    placeholder,
    value: field.value,
    thousandSeparator: true,
    // customInput: TextField,
    name,
    error: meta.touched && meta.error,
    prefix: '$',
    onValueChange: handleChange,
    customInput: Input,
    onBlur: handleBlur,
    ...rest,
  }

  return (
    <Disabled disabled={disabled}>
      <NumberFormat disabled={disabled} {...opts} />
    </Disabled>
  )
}
