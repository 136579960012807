import React from 'react'

const SEGMENT_LENGTH = 160
const EMOJI_REGEX = /\p{Emoji_Presentation}/gu

const countEmojis = (str) => {
  return ((str || '').match(EMOJI_REGEX) || []).length
}

export default function SegmentCounter({ message }) {
  const numEmojis = countEmojis(message)
  const lengthWithoutEmoji = message.replace(EMOJI_REGEX, '').length
  const numSegments = Math.ceil(lengthWithoutEmoji / SEGMENT_LENGTH) + numEmojis
  return (
    <div>
      <strong>Segments: {numSegments}</strong>
      <br />
      <strong>Message Length: {lengthWithoutEmoji}</strong>
      <br />
      {numEmojis > 0 && <strong>Emoji Count: {numEmojis}</strong>}
    </div>
  )
}
