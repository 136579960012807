import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'

import { FormErrors } from './FormField'
import Label from './Label'

import Input from './Input'

export const FieldRoot = styled.div``

export const FieldRow = styled.div`
  display: grid;
  column-gap: 0.7em;
`

export const FieldWrap = styled.div`
  position: relative;
  width: 100%;
`

export const IconWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #6b6b6b;
`

export const Note = styled.div`
  font-size: 13px;
  color: #6b6b6b;
`

export default function TextField({
  note,
  label,
  icon,
  hideErrorMessage,
  bottomNote,
  ...rest
}) {
  const [field, meta] = useField(rest.name)
  let classes = 'field text-field'
  if (meta.error) classes += ' error'
  if (icon) classes += ' icon'

  let cols = '100%'
  if (note) cols = '60% auto'
  else if (bottomNote) cols = '100%'

  return (
    <FieldRoot className={classes}>
      {label && <Label>{label}</Label>}
      <FieldRow className="field-row" style={{ gridTemplateColumns: cols }}>
        <FieldWrap className="field-wrap">
          <TextInput {...field} {...rest} />
          {icon && <IconWrap className="inline-icon">{icon}</IconWrap>}
        </FieldWrap>
        {(note || bottomNote) && <Note className="note">{note || bottomNote}</Note>}
      </FieldRow>
      {meta.touched && meta.error && !hideErrorMessage && (
        <FormErrors>{meta.error}</FormErrors>
      )}
    </FieldRoot>
  )
}

export function TextInput({ ...rest }) {
  const [field] = useField(rest.name)

  if (!rest.name) {
    throw new Error('name is a required property')
  }

  return <Input type="text" {...rest} {...field} value={field.value || ''} />
}
