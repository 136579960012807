import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { useQuery } from '../../../graphql/client'
import { FetchCampaignWithMessages } from '../../../graphql/queries/Campaigns'
import {
  Loader,
  DataTable,
  Button,
  StatsBar,
  StatCard,
  Card,
  PageHeader,
} from '../../shared'
import IconLink from '../../shared/IconLink'
import { dateString } from '../../../lib/dateHelpers'
import { money, percent } from '../../../lib/utils'

export default function CampaignDashboard() {
  const { id } = useParams()
  const { loading, data } = useQuery(FetchCampaignWithMessages, {
    id,
  })
  if (loading) {
    return <Loader />
  }
  const { campaign } = data
  const { numPurchases, revenueGenerated, subscriberCount, purchaseRate } = campaign

  return (
    <div>
      <PageHeader text="Campaigns" breadcrumb={campaign.name} />

      <StatsBar>
        <StatCard
          icon="shopping-cart"
          title="Purchases Made"
          num={numPurchases}
          subnum={percent(purchaseRate * 100)}
        />
        <StatCard
          icon="dollar-sign"
          title="Revenue Generated"
          num={money(revenueGenerated)}
          subnum=""
        />
        <StatCard icon="user-plus" title="Subscribers" num={subscriberCount} subnum="" />
      </StatsBar>

      <Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
          <Link to={`/campaigns/${id}/messages/new`}>
            <Button text="New Campaign Message" />
          </Link>
        </div>
        <DataTable
          data={campaign.campaignMessages}
          headers={['Send Date', 'Product', 'Status']}
          values={(item) => [
            <Link to={`/campaigns/${id}/messages/${item.id}`}>
              {dateString(item.sendDate)}
            </Link>,
            item.externalProduct && item.externalProduct.name,
            item.status,
          ]}
          actions={(item) => (
            <div>
              <IconLink
                to={`/campaigns/${id}/messages/${item.id}`}
                icon="bar-chart"
                text="Stats"
                style={{ marginRight: 8 }}
              />
              <IconLink
                to={`/campaigns/${id}/messages/${item.id}/edit`}
                icon="edit"
                text="Edit"
              />
            </div>
          )}
        />
      </Card>
    </div>
  )
}
