import React from 'react'
import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9ef4bd9359e64858a7fa1371c4741bfd@o261864.ingest.sentry.io/1878482',
  })
}

export function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={'An error has occured'}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
