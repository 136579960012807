import React, {useState} from 'react'
import stripe from './stripe.png'
import mailchimp from './mailchimp.png'
import shopify from './shopify.png'
import klaviyo from './klaviyo.png'
import shipstation from './shipstation.png'
import useCurrentUser from '../../hooks/useCurrentUser'
import {useQuery} from '../../graphql/client'
import {FetchIntegrations} from '../../graphql/queries/Integrations'
import {Loader} from '../shared'
import IntegrationConfiguration from './IntegrationConfiguration'
import {useAppContext} from '../../contexts/app'
import Modal from '../shared/Modal'

import ShopifyModal from './ShopifyModal'
import ShopifyPrivateModal from './ShopifyPrivateModal'
import ShipstationModal from './ShipstationModal'
import {IntegrationCard} from "./IntegrationCard";

const HOST = process.env.NODE_ENV === 'production' ? 'https://api.replii.co' : ''

export default function IntegrationsLoader() {
  const { loading, data } = useQuery(FetchIntegrations)
  if (loading) {
    return <Loader />
  }

  return <IntegrationsHome integrations={data.integrations} />
}

function IntegrationsHome({ integrations }) {
  const currentUser = useCurrentUser()
  const { customer } = useAppContext()

  const [activeIntegration, setActiveIntegration] = useState(
    integrations.find((i) => !i.configured)
  )

  const handleIntegrationClick = (redirectTo) => {
    window.location.href = `${HOST}${redirectTo}`
  }

  const shopifyRedirect = (shop) => {
    window.location.href = `${HOST}/auth/shopify?shop=${shop}.myshopify.com`
  }

  const shopifyPrivate = customer.featureFlags.includes('shopify_private')

  const auths = [
    {
      provider: 'stripe_connect',
      url: () => '/auth/stripe_connect',
      logo: stripe,
    },
    {
      provider: 'mailchimp',
      url: (user) =>
        process.env.NODE_ENV === 'production'
          ? '/auth/mailchimp'
          : `/auth/mailchimp?user_id=${user.id}`,
      logo: mailchimp,
    },
    !shopifyPrivate && {
      provider: 'shopify',
      url: () => '/auth/shopify',
      logo: shopify,
      onReconnect: (integration) => {
        window.location.href = `${HOST}/shopify/auth/shopify?shop=${integration.authorization.uid}`
      },
      modalContent: <ShopifyModal onSubmit={shopifyRedirect} />,
    },
    shopifyPrivate && {
      provider: 'shopify',
      logo: shopify,
      modalContent: <ShopifyPrivateModal />,
    },
    {
      provider: 'shipstation',
      logo: shipstation,
      modalContent: <ShipstationModal />,
    },
    {
      provider: 'klaviyo',
      url: () => '/auth/klaviyo',
      logo: klaviyo,
      disabled: true,
    },
  ].filter(Boolean)

  return (
    <div className="integrations">
      <h1>Integrations</h1>

      {activeIntegration && (
        <Modal open title={`Configure Integration`}>
          <IntegrationConfiguration
            onSuccess={() => setActiveIntegration(null)}
            {...activeIntegration}
          />
        </Modal>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          columnGap: '1em',
          rowGap: '1em',
        }}
      >
        {auths.map(({ provider, url, ...rest }) => (
          <IntegrationCard
            key={provider}
            onClick={handleIntegrationClick.bind(null, url && url(currentUser))}
            integration={integrations.find((a) => a.provider === provider)}
            url={url}
            {...rest}
          />
        ))}
      </div>
    </div>
  )
}

