import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import TextRegistration from './TextRegistration'
import { useQuery } from '../../graphql/client'
import { CollectQuery } from '../../graphql/queries/Collect'
import { Loader } from '../shared'

import './CollectHome.scss'

export default function CollectHomeLoader() {
  const history = useHistory()
  const params = useLocation()
  const search = new URLSearchParams(params.search)
  const rawPayload = search.get('pl')

  if (rawPayload) {
    window.localStorage.setItem('payload', JSON.stringify(rawPayload))
    history.replace(history.location.pathname)
    return null
  }
  const payload = JSON.parse(window.localStorage.getItem('payload'))

  return <CollectHome payload={payload} />
}

function CollectHome({ payload }) {
  const { loading, data } = useQuery(CollectQuery, { payload })

  if (loading) return <Loader />

  return (
    <div className="collect-home">
      <TextRegistration
        recipient={data.publicRecipient}
        campaignMessageId={data.publicCampaignMessageId}
        pendingOrder={data.pendingOrder}
      />
    </div>
  )
}
