import styled from 'styled-components'

const Card = styled.div`
  padding: 2em 1.5em;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: white;
`

export const ShadowCard = styled(Card)`
  border: none;
  box-shadow: 0px 3px 14px rgba(60, 66, 87, 0.12), 0px 0px 6px rgba(0, 0, 0, 0.12);
`

export default Card
