export function identify(user) {
  if (!user) return

  window.analytics &&
    window.analytics.identify(user.id, {
      email: user.email,
      customerId: user.customerId,
    })

  if (window.FS) {
    // This is an example script - don't forget to change it!
    window.FS.identify(`${user.id}`, {
      displayName: user.email,
      email: user.email,
      customerId: user.customerId,
    })
  }
}
