import React from 'react'
import { Loader } from 'react-feather'
import styled from 'styled-components'

const StyledButton = styled.button`
  letter-spacing: -0.02em;
  background-color: #1b2732;
  border: none;
  border-radius: 6px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 1em;
  outline: none;
  padding: 1em 2.5em;
  transition: 0.3s all;

  &:active {
    outline: none;
    background-color: black;
  }

  &:hover {
    background-color: #2a3845;
  }

  &.full-width {
    width: 100%;
  }

  &.loading {
    opacity: 0.5;
  }

  &.loading:hover {
    cursor: not-allowed;
  }

  &.loading svg {
    line-height: 1em;
    font-size: 14px;

    @keyframes spinner {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: spinner 0.8s ease-in-out infinite;
  }
`

export default function Button({ text, loading, fullWidth, className, onClick, ...rest }) {
  let classes = 'btn'
  if (loading) classes += ' loading'
  if (className) classes += ` ${className}`
  if (fullWidth) classes += ' full-width'

  const handleClick = (e) => {
    if (loading) {
      e.preventDefault()
      return
    }
    onClick && onClick(e)
  }

  return (
    <StyledButton onClick={handleClick} className={classes} {...rest}>
      {loading ? <Loader size={18} /> : text}
    </StyledButton>
  )
}

export const FlatButton = styled(Button)`
  border-radius: 0;
`

export const UppercaseButton = styled(FlatButton)`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 18px;
  font-size: 17px;
  text-transform: uppercase;
`

export const BlueButton = styled(Button)`
  background-color: #5292e8;
`

export const SmallButton = styled(Button)`
  padding: 0.7em 2em;
  font-size: 13px;
`
