import React from 'react'
import styled from 'styled-components'

const NoteWrap = styled.div`
  color: var(--color-mid-grey);
  font-size: 13px;
  line-height: 1.5em;
`

export default function MyNote({ children, ...rest }) {
  return (
    <NoteWrap className="note" {...rest}>
      {children}
    </NoteWrap>
  )
}
