import { Formik, Form } from 'formik'
import { Link, useParams, useHistory } from 'react-router-dom'
import React from 'react'
import ReactModal from 'react-modal'

import { mutation, useQuery } from '../../graphql/client'
import { FetchExternalObjects } from '../../graphql/queries/ExternalObjects'
import { FetchIntegration, UpdateIntegration } from '../../graphql/queries/Integrations'
import Loader from '../shared/Loader'
import Select from '../shared/Select'
import FormField from '../shared/FormField'
import SelectField from '../shared/SelectField'
import ShipstationConfiguration from './ShipstationConfiguration'
import SubmitButton from '../shared/SubmitButton'
import Card from '../shared/Card'
import { PageHeader } from '../shared'

ReactModal.setAppElement('#root')

const configurations = {
  mailchimp: {
    title: 'Mailchimp',
  },
  shopify: {
    title: 'Shopify',
  },
  shipstation: {
    title: 'Shipstation',
  },
}

function carrierName(name) {
  switch (name) {
    case 'dhl_express':
      return 'DHL'
    case 'ups_shipping':
      return 'UPS'
    case 'usps':
      return 'USPS'
    default:
      return name
  }
}

function ShopifyConfiguration({ fields }) {
  const { loading, data } = useQuery(FetchExternalObjects, {
    provider: 'shopify',
    objectTypes: ['carrier_service', 'shipping_zone'],
  })

  if (loading) return <Loader />

  const carrierOptions = data.externalObjects
    .filter((o) => o.objectType === 'carrier_service')
    .map((o) => ({
      value: o.externalId,
      text: carrierName(o.meta.name),
    }))

  const providers = data.externalObjects
    .filter((o) => o.objectType === 'shipping_zone' && o.name === 'Domestic')
    .map((o) => o.meta.carrier_shipping_rate_providers)

  return (
    <div>
      <p>
        Select the shipping carrier Replii orders will use to calculate weight-based
        shipping charges.
      </p>
      <SelectField
        name="shippingCarrier"
        items={carrierOptions}
        label="Shipping Provider"
      />
    </div>
  )
}

function MailchimpConfiguration({ fields }) {
  const { loading, data } = useQuery(FetchExternalObjects, {
    provider: 'mailchimp',
    objectTypes: ['list'],
  })

  if (loading) return <Loader />

  const options =
    data &&
    data.externalObjects.map((l) => ({
      value: l.externalId,
      label: l.meta.name,
    }))

  return (
    <div>
      <p>Contacts in Mailchimp will be updated when certain actions occur in Replii.</p>

      <ul>
        <li>
          When a user opts in to SMS marketing, we'll update the contact record in Mailchimp
          and a tag to the contact indicating they're subscribed to SMS.
        </li>
        <li>
          We'll log events like purchases and messages sent to the Mailchimp contact record.
        </li>
      </ul>
      <hr style={{ marginBottom: '1em' }} />
      <FormField
        name="listId"
        label="Mailchimp Audience"
        note="Choose the Mailchimp Audience/List Replii will sync with."
      >
        <Select name="listId" value={fields && fields.listId} options={options} />
      </FormField>
    </div>
  )
}

export default function IntegrationConfigLoader({ ...rest }) {
  const params = useParams()
  if (params.id) {
    return <LoadIntegration id={params.id} />
  } else {
    return <IntegrationConfiguration integration={rest} />
  }
}

function LoadIntegration({ id }) {
  const { loading, data } = useQuery(FetchIntegration, { id })
  if (loading) return <Loader />
  return <IntegrationConfiguration integration={data.integration} />
}

export function IntegrationConfiguration({ onSuccess, integration }) {
  const history = useHistory()

  const { id } = integration
  const { provider, fields } = integration

  const submitForm = (values) => {
    mutation(UpdateIntegration, { id: id, fields: values })
      .then((res) => {
        history.push('/integrations')
        onSuccess && onSuccess()
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  const config = configurations[provider]

  return (
    <div>
      <PageHeader
        text={<Link to="/integrations">Integrations</Link>}
        breadcrumb={`${config.title} Configuration`}
      />

      <Card>
        <Formik initialValues={fields || {}} onSubmit={submitForm}>
          <Form>
            {provider === 'mailchimp' && <MailchimpConfiguration fields={fields} />}
            {provider === 'shopify' && <ShopifyConfiguration fields={fields} />}
            {provider === 'shipstation' && <ShipstationConfiguration fields={fields} />}
            <SubmitButton text="Save Integration" fullWidth />
          </Form>
        </Formik>
      </Card>
    </div>
  )
}
