import { GraphQLClient } from 'graphql-request'
import { useState, useEffect } from 'react'
import { print } from 'graphql'
import pick from 'lodash.pick'
import * as Sentry from '@sentry/react'
import { fetchType } from './schema'

const graphqlHost =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_GRAPHQL_HOST || 'https://api.replii.co'
    : 'http://localhost:3001'

export const GQL_ENDPOINT = `${graphqlHost}/graphql`

const gqlClient = (clientOpts = {}) =>
  new GraphQLClient(GQL_ENDPOINT, {
    ...clientOpts,
    credentials: 'include', // use cookies for auth
  })

const DEBUG = process.env.NODE_ENV === 'development'

const successHandler = (success) => {
  return (res) => {
    DEBUG && console.log('[GRAPHQL] SUCCESS: ', res)

    try {
      if (res.error_messages) {
        throw new Error(res.error_messages)
      } else {
        success && success(res)
      }
    } catch (err) {
      errorHandler(err)
    }
  }
}

const ERROR_SEP = '🚫🚫🚫🚫🚫🚫'

function printError(message) {
  if (DEBUG) {
    console.log(ERROR_SEP)
    console.log(message)
  }
}

function printGqlErrors(errors) {
  if (DEBUG) {
    console.group(ERROR_SEP)
    console.log('GQL ERRORS:', errors)
    // errors.forEach(error => {
    //   console.error(error.message)
    //   console.dir(error)
    // })
    console.groupEnd()
  }
}

function printMutationResults(results) {
  if (DEBUG) {
    console.group('MUTATION RESULTS:')
    // console.dir(results)
    console.table(results)
    console.groupEnd()
  }
}

const errorHandler = (err, callback, query, variables) => {
  const { response } = err
  Sentry.setContext('gql_query', {
    query: print(query),
    variables: console.dir(variables, { depth: 4 }),
  })
  Sentry.captureException(err)

  if (response) {
    const { error } = response
    if (error && error.message) {
      printError(error.message)
    } else if (response.errors) {
      // graphql formatted errors object
      printGqlErrors(response.errors)
      return callback && callback(response.errors)
    } else {
      printError(error)
    }
  } else {
    printError(err)
  }
  // if (err.error_messages) {
  callback && callback(err)
  // }
}

/**
 * @param {String} query a graphql query string
 * @param {Object} vars
 * @param {Object} opts={}
 */
export const query = (query, vars, opts = {}) => {
  const { success, error, ...clientOpts } = opts
  const q = print(query)
  // console.log('QUERY', q)

  // Wrap with a promise
  return new Promise((resolve, reject) => {
    const client = gqlClient(clientOpts)
    let req = client.request(q, vars)
    req = req.then(successHandler(resolve || success))

    req.catch((err) => {
      errorHandler(err, reject, query, vars)
      error && error(err)
      reject(err)
    })
  })
}

/**
 * Find the Input type for a mutation and the attributes hash.
 * If found, filter values with only the keys present/allowed on the input object.
 *
 * If there's no definition found, we just return the original values.
 * @param  {} mutation
 * @param  {} values
 */
export const sanitizeAttributes = async (mutation, values) => {
  const opDef = mutation.definitions.find((def) => def.kind === 'OperationDefinition')
  const variableDef = opDef.variableDefinitions.find(
    (v) => v.variable.name.value === 'attributes'
  )
  let vals = { ...values }
  if (variableDef) {
    const inputType = variableDef.type.type.name.value
    const { _fields } = await fetchType(inputType)
    vals = pick(values, Object.keys(_fields))
  }

  return vals
}

/**
 * @param {Object} query a graphql query wrapped in gql tags
 * @param {Object} variables
 * @param {Object} opts={}
 */
export const mutation = async (query, variables, opts = {}) => {
  const { success, error, attributes, ...clientOpts } = opts
  // console.log('query', query)
  const q = print(query)
  const client = gqlClient(clientOpts)

  DEBUG && console.log('[GRAPHQL] VARS: ', variables)
  const body = { ...variables }

  if (body.attributes) {
    body.attributes = await sanitizeAttributes(query, body.attributes)
  }

  return new Promise((resolve, reject) => {
    client
      .request(q, body)
      .then((data) => {
        let payload = null

        // the payload from a gql mutation has the following structure:
        //   { mutationName: { fieldName: { ...actualPayload } } }
        // In this very common case, let's return the payload as the second argument
        // making it easier to work with
        if (Object.keys(data).length === 1) {
          const field = Object.values(data)[0]
          if (Object.keys(field).length === 1) {
            // payload = Object.values(field)[0]
            ;[payload] = Object.values(field)
          }
        }
        printMutationResults(payload || data)
        successHandler(resolve({ data, payload }))
      })
      .catch((err) => {
        errorHandler(err, reject, query, variables)
        // reject(err)
      })
  })
}

export const useQuery = (gqlQuery, variables, opts = {}) => {
  const [{ loading, data, error }, setData] = useState({
    loading: true,
    data: {},
    error: null,
  })

  const strQuery = print(gqlQuery)
  const stringifiedOpts = JSON.stringify(variables)
  useEffect(() => {
    setData({ loading: true })
    query(gqlQuery, variables)
      .then((res) => {
        setData({ loading: false, data: res })
        opts.onSuccess && opts.onSuccess(res)
      })
      .catch((err) => {
        setData({ loading: false, error: err })
      })
  }, [strQuery, stringifiedOpts]) // eslint-disable-line react-hooks/exhaustive-deps

  return { loading, data, error }
}

export default gqlClient
