import styled from 'styled-components'

const Label = styled.label`
  display: block;
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 6px;
`

export default Label
