import React from 'react'
import { Formik, Form, Field } from 'formik'
import { useParams, useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'

import { mutation, useQuery } from '../../../graphql/client'
import {
  CreateCampaignList,
  FetchCampaignList,
  UpdateCampaignList,
} from '../../../graphql/queries/CampaignLists'
import {
  Button,
  FormField,
  SimpleSelect,
  Checkbox,
  TextField,
  Card,
  Loader,
  PageHeader,
} from '../../shared'

function CampaignListForm() {
  const { id } = useParams()

  if (id) return <EditForm id={id} />
  return <NewForm />
}

function EditForm({ id }) {
  const { loading, data } = useQuery(FetchCampaignList, { id })
  if (loading) return <Loader />
  return <ListForm data={data.list} id={id} />
}

function NewForm() {
  return <ListForm />
}

const ListCampaigns = gql`
  query Campaigns {
    campaigns {
      id
      name
    }
  }
`

function ListForm({ id, data = { name: '', default: false } }) {
  const history = useHistory()
  const { loading, data: campaignData } = useQuery(ListCampaigns)

  if (loading) return <Loader />
  const campaigns = campaignData.campaigns

  const onSubmit = (values, actions) => {
    const tpl = id ? UpdateCampaignList : CreateCampaignList
    const attributes = {
      name: values.name,
      default: values.default,
      defaultCampaignId: values.defaultCampaignId,
    }
    mutation(tpl, { attributes, id }).then(({ payload }) => {
      history.replace('/lists')
    })
  }

  return (
    <div className="campaign-list-form">
      <PageHeader text="Lists" breadcrumb={`${id ? 'Edit List' : 'New List'}`} />
      <Card>
        <Formik onSubmit={onSubmit} initialValues={data}>
          <Form>
            <TextField
              name="name"
              label="List Name"
              note="The name of the list"
              placeholder="List Name"
            />
            <FormField
              label="Default Campaign"
              name="defaultCampaignId"
              note="This campaign will be triggered when a user is added to this list."
            >
              <SimpleSelect
                name="defaultCampaignId"
                items={campaigns.map((p) => ({ value: p.id, text: p.name }))}
              />
            </FormField>
            <Field name="default">
              {({ field, form, meta }) => {
                return (
                  <Checkbox
                    name="default"
                    label="Default"
                    checked={field.value}
                    {...field}
                  />
                )
              }}
            </Field>
            <Button text="Save" type="submit" />
          </Form>
        </Formik>
      </Card>
    </div>
  )
}

export default CampaignListForm
