import pick from 'lodash.pick'
import * as yup from 'yup'
import request from 'superagent'
import { Send, Trash2, Pause, Play } from 'react-feather'

import { mutation } from '../../graphql/client'
import {
  CreateCampaignMessage,
  UpdateCampaignMessage,
  UpdateCampaignMessageStatus,
} from '../../graphql/queries/CampaignMessages'

export const Schema = yup.object().shape({
  body: yup.string(),
  imagePath: yup.string().nullable(),
  sendDate: yup.mixed(),
  shippingAmount: yup.mixed().nullable(yup.number()),
  externalProductId: yup.string().nullable(),
  availableInventory: yup.mixed().nullable(yup.number()),
  maxPerCustomer: yup.mixed().nullable(yup.number()),
})

export const WHITELISTED_ATTRS = [
  'recipientReplies',
  'body',
  'orderable',
  'imagePath',
  'sendDate',
  'externalProductId',
  'availableInventory',
  'maxPerCustomer',
  'shippingAmount',
  'demo',
  'dynamicShipping',
  'useTieredPricing',
  'priceTiers',
]

export function saveCampaignMessage(values, id, campaignId) {
  const isEditing = !!id
  const gqlQuery = isEditing ? UpdateCampaignMessage : CreateCampaignMessage
  const whitelisted = { ...pick(values, WHITELISTED_ATTRS) }
  whitelisted.shippingAmount = parseFloat(values.shippingAmount)
  whitelisted.availableInventory = parseInt(values.availableInventory)
  whitelisted.maxPerCustomer = parseInt(values.maxPerCustomer)
  whitelisted.orderConfirmMessage = values.orderConfirmMessage

  let variables = { attributes: whitelisted }

  if (isEditing) {
    variables.id = id
  } else {
    variables.campaignId = campaignId
  }

  return mutation(gqlQuery, variables)
}

export function transitionStatus(isPublished, id, formik) {
  formik.setSubmitting(true)
  return mutation(UpdateCampaignMessageStatus, {
    id,
    status: isPublished ? 'unpublish' : 'publish',
  })
    .then(({ payload }) => {
      formik.setSubmitting(false)
      // console.log('res', res)
      formik.setFieldValue('status', payload.status)
    })
    .catch((err) => {
      formik.setSubmitting(false)
    })
}

export function contextItems(isPublished) {
  return [
    !isPublished && {
      name: 'publish',
      title: 'Publish',
      Icon: Play,
    },
    isPublished && {
      name: 'pause',
      title: 'Unpublish',
      Icon: Pause,
    },
    { name: 'send_test', title: 'Send Test', Icon: Send },
    { name: 'delete', title: 'Delete', Icon: Trash2 },
  ].filter(Boolean)
}

export const uploadImage = (file, key, signUrl, { onProgress }) => {
  const req = request.post(signUrl.url)

  for (let [field, value] of Object.entries(signUrl.fields)) {
    req.field(field, value)
  }
  req.field('key', key)
  req.field('Content-Type', file.type)
  req.attach('file', file)

  if (onProgress) req.on('progress', onProgress.bind(null, file))

  return req
}
