import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import isEmpty from 'lodash.isempty'

const FieldRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.cols === 1 ? 'auto' : '60% auto')};
  column-gap: 0.7em;
`

export const FormErrors = styled.div`
  padding: 0.8em 0 0;
  color: #e75656;
  font-size: 13px;
  line-height: 1.3em;
  font-weight: 500;
`

export default function FormField({
  label,
  note,
  children,
  cols,
  hideErrorMessage,
  ...rest
}) {
  const [field, meta, helpers] = useField(rest)
  const error = meta && meta.error
  let classes = 'field text-field'
  if (error) classes += ' error'

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <FieldRow cols={cols}>
        <div className="field-wrap">{children}</div>
        {note && <div className="note">{note}</div>}
      </FieldRow>
      {meta.touched && !isEmpty(meta.error) && !hideErrorMessage && (
        <FormErrors>{meta.error}</FormErrors>
      )}
    </div>
  )
}
