import accounting from 'accounting'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import emailValidator from 'email-validator'

export function money(cents) {
  return accounting.formatMoney(cents / 100)
}

export function validatePhone(phone) {
  if (!phone || phone === '') return false
  const parsed = parsePhoneNumberFromString(phone, 'US')
  return parsed && parsed.isValid()
}

export function validateEmail(email) {
  return emailValidator.validate(email)
}

export const ZIP_EXP = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export function validateZip(zip) {
  return ZIP_EXP.test(zip)
}

export function percent(val) {
  return `${Math.round(val * 100) / 100}%`
}
