import { createPortal } from 'react-dom'
import React from 'react'
import * as Sentry from '@sentry/react'

import { useQuery } from '../../graphql/client'
import { FetchWidget } from '../../graphql/queries/Widgets'
import DelayedModalWidget from './DelayedModalWidget'
import { PermanentWidget } from './PermanentWidget'
// import { setCookie } from '../../lib/cookieHelper'
import { REPLII_VERSION } from '../../sdk'

const PERMANENT_TYPE = 'permanent'

export default function WidgetRoot({ widgetID }) {
  const { loading, data, error } = useQuery(FetchWidget, { publicId: widgetID })

  if (loading) return null
  if (error) {
    Sentry.captureException(error, (scope) => {
      scope.setContext('widgetID', widgetID)
      scope.setContext('widget', {
        id: widgetID,
        version: REPLII_VERSION,
      })
    })
    return null
  }

  const { domTargetSelector, widgetType } = data.widget

  if (widgetType === PERMANENT_TYPE) {
    const target = document.querySelector(domTargetSelector)
    if (!target) {
      console.warn(
        `Could not find target selector: '${domTargetSelector}'. Please check your widget configuration`
      )
      return null
    }

    if (!data.widget) {
      console.warn(
        `Could not find widget with id='${widgetID}'. Please check your plugin configuration.`
      )
      return null
    }

    return createPortal(
      <div className="replii-widget-root">
        <Widget widget={data.widget} />
      </div>,
      target
    )
  }

  return (
    <div className="replii-widget-root">
      <Widget widget={data.widget} />
    </div>
  )
}

function Widget({ widget }) {
  switch (widget.widgetType) {
    case 'permanent':
      return <PermanentWidget widget={widget} />
    case 'delayed_modal':
      return <DelayedModalWidget widget={widget} />
    default:
      break
  }
}
