import React, { useState } from 'react'
import { useField } from 'formik'
import {
  parsePhoneNumberFromString,
  AsYouType,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js'

import Input from './Input'
import FormField from './FormField'
import Label from './Label'

function formatNumber(num) {
  return new AsYouType('US').input(num)
}

function formatNumberPretty(num) {
  const parsed = parsePhoneNumberFromString(num, 'US')
  return parsed ? parsed.format('NATIONAL') : ''
}

export default function PhoneField({ label, cols = 1, ...props }) {
  const [field] = useField(props)
  const [phone, setPhone] = useState(formatNumberPretty(field.value || ''))

  const changeField = (e) => {
    const { name, value } = e.target
    let formatted = formatNumber(value)

    if (formatted === phone) {
      // backspace detection
      formatted = formatted.slice(0, -1)
    }

    setPhone(formatted)
    field.onChange({
      target: {
        name,
        value: parseIncompletePhoneNumber(formatted),
      },
    })
  }

  const { disabled, loading, confirmed, ...rest } = props

  return (
    <FormField className="phone-field" name={props.name} cols={cols}>
      {label && <Label htmlFor="phone">{label}</Label>}
      <Input
        id="phone"
        name="phone"
        {...field}
        {...rest}
        type="tel"
        error={field.error}
        value={phone}
        onChange={changeField}
        autoComplete="tel"
      />
    </FormField>
  )
}
