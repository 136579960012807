import dayjs from 'dayjs'

export function dateString(
  date,
  opts = { format: 'MM/DD/YY hh:mm a ZZ', timezone: '' },
) {
  return dayjs(date).format(opts.format)
}

export function dateStringShort(
  date,
  opts = { format: 'MM/DD/YY hh:mmA' },
) {
  return dateString(date, { format: opts.format })
}

/**
 * Is date in the past?
 *
 * @param  {} date
 * @return {Boolean}
 */
export const isPast = (date) => dayjs().isAfter(dayjs(date))
