import styled from 'styled-components'

const Label = styled.label`
  display: block;
  font-weight: 800;
  font-size: 14px;
  padding-bottom: 10px;
  color: #323232;
`

export default Label
