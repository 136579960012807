import React from 'react'
import { Settings } from 'react-feather'

import { mutation } from '../../graphql/client'
import { Button, Card, PageHeader } from '../shared'
import { CreateStripeBillingPortalSession } from '../../graphql/queries/StripeBillingSessions'

export default function BillingSettings() {
  const handleClick = () => {
    mutation(CreateStripeBillingPortalSession).then(({ payload }) => {
      window.location.href = payload
    })
  }
  return (
    <div>
      <PageHeader text="Settings" breadcrumb="Billing" icon={<Settings size={20} />} />
      <Card>
        <Button onClick={handleClick} text="Update Billing Settings" />
      </Card>
    </div>
  )
}
