import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useInViewEffect } from 'react-hook-inview'

import CollectPhone from './CollectPhone'
import Disclaimer from '../Collect/Disclaimer'

import { trackWidgetShown } from '../../lib/sdk/analytics'

export const DEFAULT_TITLE = `Let's Keep in Touch`

export const WidgetWrap = styled.div`
  width: 375px;
  margin: 0 auto;

  @media only screen and (max-device-width: 480px) {
    width: 100%;
  }
`

export const WidgetHeader = styled.div`
  text-align: center;
  padding: 1em;
  font-size: 18px;
  font-weight: 700;
  color: #1b2732;
`

export function PermanentWidget({ widget, onSuccess, onClose }) {
  const { title } = widget

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        trackWidgetShown(widget)
        observer.unobserve(entry.target)
      }
    },
    { threshold: 0.5 }
  )

  return (
    <WidgetWrap className="widget-wrap" ref={ref}>
      <WidgetHeader className="widget-header">{title || DEFAULT_TITLE}</WidgetHeader>
      <div>
        <CollectPhone onClose={onClose} onSuccess={onSuccess} widget={widget} />
        <Disclaimer style={{ marginTop: '1em' }} name={widget.customerName} />
      </div>
    </WidgetWrap>
  )
}
