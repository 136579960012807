import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { query } from '../../graphql/client'
import { FetchContacts } from '../../graphql/queries/Contacts'
import { Card, DataTable } from '../shared/'

export default function ContactsHome() {
  const [{ loading, data }, setData] = useState({
    loading: true,
    data: null,
  })
  useEffect(() => {
    query(FetchContacts).then(({ contacts }) => {
      setData({ loading: false, data: contacts })
    })
  }, [])

  return (
    <div>
      <h1>Contacts</h1>
      <Card>
        {loading && <div>Loading...</div>}
        {data && (
          <DataTable
            data={data}
            headers={['Phone', 'Confirmed']}
            columns={['phone', 'confirmedAt']}
            actions={(c) => (
              <Link to={`/contacts/${c.id}/edit`}>Edit</Link>
            )}
          />
        )}
      </Card>
    </div>
  )
}
