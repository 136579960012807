import React from 'react'

import FormField from './FormField'
import SimpleSelect from './SimpleSelect'

export default function SelectField({ name, label, note, items, ...rest }) {
  return (
    <FormField name={name} label={label} note={note}>
      <SimpleSelect name={name} items={items} {...rest} />
    </FormField>
  )
}
