import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Input = styled.input`
  background-color: ${(props) =>
    props.error ? 'var(--color-input-error-background)' : 'var(--color-field-bg)'};
  border: ${(props) =>
    props.error
      ? '1px solid var(--color-field-border-error)'
      : '1px solid var(--color-field-border)'};
  border-radius: 6px;
  box-shadow: none;
  color: var(--input-color);
  display: block;
  font-family: var(--font-family);
  font-weight: normal;
  font-size: var(--base-font-size);
  line-height: 1em;
  outline: none;
  padding: 1.1em 1em;
  transition: 0.3s all;
  width: 100%;

  &:active,
  &:focus {
    border: 1px solid #8fa7bd;
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px rgba(68, 155, 236, 0.25);
  }

  &:focus {
    outline: none;
    background-color: #f5f5f5;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #a8a8a8;
    opacity: 1;
  }

  &[disabled] {
    color: #6c6c6c;
    border-color: #dfdfdf;
    cursor: not-allowed;
  }
`

export default Input

Input.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
}
