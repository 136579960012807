import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import {
  User,
  Box,
  List,
  GitMerge,
  Grid,
  Settings,
  MessageSquare,
  CreditCard,
  Briefcase,
  DollarSign
} from 'react-feather'

import AppContext from '../../contexts/app'
import { IconButton } from '../shared'
import './Sidebar.scss'

const routes = [
  // { to: '/dashboard', text: 'Dashboard', Icon: BarChart },
  { to: '/campaigns', text: 'Campaigns', Icon: GitMerge },
  { to: '/lists', text: 'Lists', Icon: List },
  { to: '/integrations', text: 'Integrations', Icon: Grid },
  { to: '/widgets', text: 'Collection Widgets', Icon: Box },
  {
    text: 'Settings',
    Icon: Settings,
    subnav: [
      { to: '/settings/organization', text: 'Organization', Icon: Briefcase },
      { to: '/settings/commerce', text: 'Commerce', Icon: DollarSign },
      {
        to: '/settings/messaging',
        text: 'Messaging',
        Icon: MessageSquare,
      },
      { to: '/settings/user', text: 'User', Icon: User },
      { to: '/settings/billing', text: 'Billing', Icon: CreditCard },
    ],
  },
]

const ExpandWrap = styled.div`
  position: absolute;
  top: 20px;
  right: ${(props) => (props.expanded ? '10px' : 'auto')};
  left: ${(props) => (props.expanded ? 'auto' : '50%')};
  transform: ${(props) => (props.expanded ? 'auto' : 'translate(-50%, -50%)')};
`

export default function Sidebar() {
  const cont = useContext(AppContext)
  const expanded = cont.expandedSidebar
  const { pathname } = useLocation()

  const toggleExpanded = () => cont.toggleSidebar()
  const classes = ['sidebar', !expanded && 'collapsed'].filter(Boolean).join(' ')

  return (
    <div className={classes}>
      <div className="sidebar-inner">
        <ExpandWrap expanded={expanded}>
          <IconButton
            iconProps={{ color: 'white' }}
            name={expanded ? 'chevrons-left' : 'chevrons-right'}
            onClick={toggleExpanded}
          />
        </ExpandWrap>
        {false && <IconButton name="moon" onClick={cont.toggleDarkMode} />}
        {expanded && <h3>Navigation</h3>}
        <ul>
          {routes.map((item) => (
            <NavItem key={item.text} pathname={pathname} expanded={expanded} {...item} />
          ))}
        </ul>
      </div>
    </div>
  )
}

const LinkText = styled.span`
  font-size: ${(props) => (props.expanded ? '16' : '11')}px;
  text-align: center;
`

function NavItem({ to, text, Icon, subnav, expanded, pathname }) {
  return (
    <li>
      <Link to={to || '#'} className={pathname === to ? 'active' : ''}>
        {Icon && <Icon size={expanded ? 16 : 20} />}
        <LinkText className="" expanded={expanded}>
          {text}
        </LinkText>
      </Link>

      {subnav && (
        <ul className="subnav">
          {subnav.map((item) => (
            <NavItem key={item.to} expanded={expanded} pathname={pathname} {...item} />
          ))}
        </ul>
      )}
    </li>
  )
}
