import { hot } from 'react-hot-loader/root'
import React, { useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import './App.scss'
import LoginHome from './components/Session/LoginHome'
import { HomeQuery } from './graphql/queries/Home'
import { useQuery } from './graphql/client'
import DashboardHome from './components/Dashboard/DashboardHome'
import AppContext from './contexts/app'
import { getItem, setItem } from './lib/localStorage'
import Loader from './components/shared/Loader'
import CollectHome from './components/Collect/CollectHome'
import { identify } from './lib/analytics'

function App() {
  const [data, setData] = useState({
    currentUser: null,
    lists: [],
    externalProducts: [],
    darkMode: getItem('darkMode') === 'true' ? true : false,
    setCurrentUser,
    toggleDarkMode,
    expandedSidebar: getItem('expandedSidebar') === 'true' ? true : false,
    toggleSidebar,
    setAppContextData,
  })
  const history = useHistory()
  const location = useLocation()

  const { loading } = useQuery(
    HomeQuery,
    {},
    {
      onSuccess: (res) => {
        if (!res.currentUser) {
          return history.replace('/login')
        }

        setAppContextData(res)

        if (location.pathname === '/') {
          return history.replace('/campaigns')
        }
      },
    }
  )

  function setAppContextData(res) {
    if (res.currentUser) {
      identify(res.currentUser)
      Sentry.setUser(res.currentUser)
    }
    setData((data) => ({ ...data, ...res }))
  }

  function setCurrentUser(user) {
    setData((data) => ({ ...data, currentUser: user }))
  }

  function toggleSidebar() {
    setData((data) => {
      const newVal = !data.expandedSidebar
      setItem('expandedSidebar', newVal)
      return { ...data, expandedSidebar: newVal }
    })
  }

  function toggleDarkMode() {
    setData((data) => {
      const newVal = !data.darkMode
      setItem('darkMode', newVal)
      return { ...data, darkMode: newVal }
    })
  }

  if (loading) return <Loader />

  if (data.currentUser) {
    let classes = 'App'
    if (data.darkMode) classes += ' dark'

    return (
      <div className={classes}>
        <AppContext.Provider value={data}>
          <DashboardHome />
        </AppContext.Provider>
      </div>
    )
  }

  return (
    <div className="App">
      <AppContext.Provider value={data}>
        <Switch>
          <Route path="/collect" component={CollectHome} />
          <Route path="/login" component={LoginHome} />
        </Switch>
      </AppContext.Provider>
    </div>
  )
}

export default hot(App)
