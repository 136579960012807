import React from 'react'
import { Formik, Form } from 'formik'
import { Button, Flex, Grid, TextField } from '../shared'

import Modal from '../shared/Modal'
import Label from '../shared/Label'

export default function ShopifyModal({ onSubmit, onClose }) {
  const handleSubmit = (values) => onSubmit(values.shopifyDomain)

  return (
    <div>
      <Modal
        onClose={onClose}
        open
        showClose
        title="Connect Shopify Account"
        style={{ maxWidth: 450 }}
      >
        <Formik onSubmit={handleSubmit} initialValues={{}}>
          <Form style={{ margin: '0 auto', width: '350px' }}>
            <Grid rows={3} style={{ rowGap: '1em', gridTemplateRows: 'auto' }}>
              <Label style={{ paddingBottom: 0 }}>Shopify Store Domain</Label>
              <Flex style={{ alignItems: 'center' }}>
                <TextField name="shopifyDomain" rootStyle={{ marginBottom: 0 }} />
                <div
                  style={{ marginLeft: 8, fontWeight: 600, color: '#6b6b6b', fontSize: 15 }}
                >
                  .myshopify.com
                </div>
              </Flex>
              <Button style={{ marginTop: '2em' }} text="Connect Shopify" />
            </Grid>
          </Form>
        </Formik>
      </Modal>
    </div>
  )
}
