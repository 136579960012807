import { UpdateCustomer } from '../../graphql/queries/Customers'
import { mutation } from '../../graphql/client'

export const updateCustomer = async (attributes, formik) => {
  try {
    const res = await mutation(UpdateCustomer, { attributes })
    formik.setSubmitting(false)
    return res
  } catch (e) {
    formik.setSubmitting(false)
    formik.setErrors(e)
    return e
  }
}
