import { gql } from 'apollo-boost'

export const CampaignMessageFragment = gql`
  fragment CampaignMessageFragment on CampaignMessage {
    id
    body
    imageUrl
    orderable
    campaignId
    status
    sendDate
    maxPerCustomer
    shippingAmount
    availableInventory
    externalProductId
    recipientReplies
    orderConfirmMessage
    demo
    dynamicShipping
    useTieredPricing
    priceTiers
    campaignList {
      id
      name
    }
  }
`

export const FetchPriceTier = gql`
  query PriceTier($campaignMessageId: ID!, $productId: ID!) {
    priceTier(campaignMessageId: $campaignMessageId, productId: $productId)
  }
`

export const FetchCampaignMessage = gql`
  query CampaignMessage($id: ID!) {
    campaignMessage(id: $id) {
      ...CampaignMessageFragment

      campaign {
        id
        name
      }
    }
  }
  ${CampaignMessageFragment}
`

export const SentMessages = gql`
  query SentMessages($campaignMessageId: ID!, $page: Int) {
    sentMessages(campaignMessageId: $campaignMessageId, page: $page) {
      offset
      more
      totalCount
      data {
        id
        createdAt
        status
        recipient {
          id
          name
          phone
        }
      }
    }
  }
`

export const FetchDetailedCampaignMessage = gql`
  query CampaignMessage($id: ID!, $page: Int) {
    campaignMessage(id: $id) {
      ...CampaignMessageFragment

      subscriberCount
      numPurchases
      revenueGenerated
      numMessagesSent
      purchaseRate
      linksClicked
      clickThroughRate
      unsubscribes
      unsubRate
      delivered
      failed

      campaign {
        id
        name
      }

      externalProduct {
        id
        name
      }
    }

    sentMessages(campaignMessageId: $id, page: $page) {
      offset
      totalCount
      more
      data {
        id
        createdAt
        status
        recipient {
          id
          name
          phone
        }
      }
    }
  }
  ${CampaignMessageFragment}
`

export const FetchCampaignMessageForEdit = gql`
  query CampaignMessage($id: ID!) {
    campaignMessage(id: $id) {
      ...CampaignMessageFragment
      useTieredPricing

      campaign {
        id
        name
      }

      externalProduct {
        id
        priceTiers
      }
    }
    s3SignUrl(campaignMessageId: $id)
  }
  ${CampaignMessageFragment}
`

export const SendTestMessage = gql`
  mutation SendTestMessage($to: String!, $campaignMessageId: ID!) {
    sendTestMessage(input: { to: $to, campaignMessageId: $campaignMessageId }) {
      success
    }
  }
`

export const CreateCampaignMessage = gql`
  mutation CreateCampaignMessage($campaignId: ID!, $attributes: CampaignMessageInput!) {
    createCampaignMessage(input: { attributes: $attributes, campaignId: $campaignId }) {
      campaignMessage {
        ...CampaignMessageFragment
      }
    }
  }
  ${CampaignMessageFragment}
`

export const DeleteCampaignMessage = gql`
  mutation DeleteCampaignMessage($id: ID!) {
    deleteCampaignMessage(input: { id: $id }) {
      campaignMessage {
        id
      }
    }
  }
`

export const UpdateCampaignMessageStatus = gql`
  mutation UpdateCampaignMessageStatus($id: ID!, $status: String!) {
    updateCampaignMessageStatus(input: { id: $id, status: $status }) {
      campaignMessage {
        ...CampaignMessageFragment
      }
    }
  }
  ${CampaignMessageFragment}
`

export const UpdateCampaignMessage = gql`
  mutation UpdateCampaignMessage($id: ID!, $attributes: CampaignMessageInput!) {
    updateCampaignMessage(input: { attributes: $attributes, id: $id }) {
      campaignMessage {
        ...CampaignMessageFragment
      }
    }
  }
  ${CampaignMessageFragment}
`
