import React from 'react'
import { Link } from 'react-router-dom'
import { Loader } from 'react-feather'
import { useImmer } from 'use-immer'

import { useQuery, mutation } from '../../../graphql/client'
import {
  Button,
  Header,
  Card,
  Flex,
  DataTable,
  IconButton,
  DeleteButton,
  PageHeader,
} from '../../shared'
import { FetchCampaigns, DeleteCampaign } from '../../../graphql/queries/Campaigns'

export default function CampaignLoader({ children }) {
  const { loading, data } = useQuery(FetchCampaigns)
  if (loading) return <Loader />

  return <CampaignHome campaigns={data.campaigns} />
}

function CampaignHome({ campaigns }) {
  const [state, setState] = useImmer({ campaigns })

  const clickedDelete = (id, index) => {
    if (!window.confirm('Are you sure?')) return

    mutation(DeleteCampaign, { id }).then((res) => {
      setState((draft) => {
        draft.campaigns.splice(index, 1)
      })
    })
  }

  return (
    <div>
      <PageHeader text="Campaigns">
        <p>A campaign is a series of messages sent to the associated list.</p>
      </PageHeader>
      <Card>
        <Link to="/campaigns/new">
          <Button text="Add Campaign" />
        </Link>
        <DataTable
          data={state.campaigns}
          values={(item) => [
            <Link to={`/campaigns/${item.id}`}>{item.name}</Link>,
            item.default,
            <Link to={`/lists/${item.campaignList.id}/edit`}>
              {item.campaignList.name}
            </Link>,
          ]}
          headers={['Campaign', 'Default', 'List']}
          actions={(item, i) => (
            <Flex>
              <Link to={`/campaigns/${item.id}/edit`}>
                <IconButton name="edit" />
              </Link>
              <DeleteButton onClick={() => clickedDelete(item.id, i)} />
            </Flex>
          )}
        />
      </Card>
    </div>
  )
}
