import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

import IconButton from '../shared/IconButton'

import './Modal.css'

ReactModal.setAppElement('#modal-root')

const ModalHeader = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: -0.02em;
  color: var(--color-primary);
`

const ModalWrap = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
`

const HeaderWrap = styled.div`
  position: relative;
`

const ModalFooter = styled.div`
  height: 100px;
`

const ModalBody = styled.div``

export default function Modal({
  children,
  title,
  showClose = true,
  open,
  onClose,
  shouldCloseOnOverlayClick,
  footer,
}) {
  const [originalBodyOverflowY, setOriginalBodyOverflowY] = useState(null)
  useEffect(() => {
    if (!originalBodyOverflowY) {
      setOriginalBodyOverflowY(document.body.style.overflowY)
    }
    if (open) {
      document.body.style.overflowY = 'hidden'
    }

    return () => {
      document.body.style.overflowY = originalBodyOverflowY
      setOriginalBodyOverflowY(null)
    }
  }, [open])
  const onRequestClose = () => {
    onClose && onClose()
  }

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName="rm-overlay"
      className="rm-content"
    >
      <ModalWrap>
        <ModalHeader>
          {title}
          {showClose && (
            <IconButton
              name="x"
              onClick={onRequestClose}
              style={{ position: 'absolute', right: 10, top: 16 }}
            />
          )}
        </ModalHeader>

        <ModalBody className="modal-body">{children}</ModalBody>
      </ModalWrap>
    </ReactModal>
  )
}
