import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import Cookies from 'universal-cookie'

import { log } from '../../lib/log'
import { PermanentWidget } from './PermanentWidget'

const cookies = new Cookies()

const COOKIE_KEY = '__replii_sdk_modal'
const TRIGGER_CLASS = '.__replii-open'

export default function DelayedModalWidgetLoader({ widget }) {
  const cookie = cookies.get(COOKIE_KEY)
  const cookieVal = cookie ? parseInt(cookie) : 0 // # times modal has been shown
  const [showWidget, setShowWidget] = useState(false) // changed via event handlers

  const updateCookie = (exp) => {
    const timesShown = cookieVal + 1
    cookies.set(COOKIE_KEY, timesShown, { expires: exp })
  }

  const onShowModal = () => {
    const d = new Date()
    d.setMonth(d.getMonth() + 1)
    updateCookie(d)
  }

  const onSuccess = () => {
    log('in onSuccess. Setting cookie exp to a year from now.')
    const d = new Date()
    d.setFullYear(d.getFullYear() + 1)
    updateCookie(d)
  }

  const onClickShowWidget = useCallback((e) => {
    e.preventDefault()
    setShowWidget(true)
  })

  useEffect(() => {
    document.querySelectorAll(TRIGGER_CLASS).forEach((el) => {
      el.addEventListener('click', onClickShowWidget)
    })
  }, [onClickShowWidget])

  const props = {
    onSuccess,
    widget,
  }

  // if user has clicked a button that should open a modal
  if (showWidget) {
    return <ModalWidget {...props} onClose={() => setShowWidget(false)} />
  }

  // this may be something we want to allow the user to customize.
  if (cookieVal >= 1) {
    log('cookie present. returning.')
    return null
  }

  return <DelayedModalWidget {...props} onShowModal={onShowModal} />
}

function ModalWidget({ widget, onSuccess, onClose }) {
  const [open, setOpen] = useState(true)

  const closeModal = () => {
    log('closing modal')
    setOpen(false)

    // reset body scroll
    document.body.style.overflowY = ''
    onClose && onClose()
  }

  if (!open) return null

  return (
    <Modal onClose={closeModal}>
      <PermanentWidget widget={widget} onSuccess={onSuccess} onClose={closeModal} />
    </Modal>
  )
}

function DelayedModalWidget({ widget, onShowModal, onSuccess }) {
  const [open, setOpen] = useState(false)
  const [shown, setShown] = useState(false)
  const timeout = useRef()
  const { delayInSeconds } = widget
  const millis = delayInSeconds * 1000

  const showModal = useRef(() => {
    log('setting open to true')
    // disable body scroll
    document.body.style.overflowY = 'hidden'

    setOpen(true)
    setShown(true)
    onShowModal()
  })

  const closeModal = () => {
    log('closing modal')
    setOpen(false)

    // reset body scroll
    document.body.style.overflowY = ''
  }

  const clear = useCallback(() => {
    if (timeout.current) {
      log('clearing timeout')
      clearTimeout(timeout.current)
    }
  })

  // if we haven't shown the modal, schedule it to load after a delay
  useEffect(() => {
    log('in useEffect')
    if (shown) {
      log('already shown. exiting...')
      return
    }

    timeout.current = setTimeout(() => {
      showModal.current()
    }, millis)
    return clear
  }, [open])

  // show nothing if modal is closed
  if (!open) {
    return null
  }

  return (
    <Modal onClose={closeModal}>
      <PermanentWidget widget={widget} onSuccess={onSuccess} onClose={closeModal} />
    </Modal>
  )
}

function Modal({ children, onClose }) {
  return (
    <ModalContainer className="__replii-modal-container">
      <Wrapper>
        <ModalBackdrop className="__replii-modal-backdrop" />
        <ModalContent className="__replii-modal-backdrop">
          <CloseButton className="__replii-modal-close-btn" onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <ModalBody className="__replii-modal-body">{children}</ModalBody>
        </ModalContent>
      </Wrapper>
    </ModalContainer>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  animation-duration: 500ms;
  animation-delay: 0;
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn};
    animation-fill-mode: backwards;
  }
`

const ModalBody = styled.div``

const ModalContainer = styled.div`
  * {
    box-sizing: border-box;
  }
`

const ModalContent = styled.div`
  min-width: 350px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0px;
  padding: 0 18px 18px 18px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001;

  @media only screen and (max-device-width: 480px) {
    width: 100%;
  }
`

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)

const CloseButton = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 18px;
  color: #6f6f6f;
`
