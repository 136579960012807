import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Formik, Form } from 'formik'
import { CreditCard } from 'react-feather'

import { SubmitButton, TextField, Checkbox } from '../shared'
import { ReactComponent as StripeLogo } from './poweredByStripePurple.svg'
import OrderDetails from './OrderDetails'
import { tokenizeCard } from '../../lib/stripe'
import FormNote from '../shared/FormNote'
import { mutation } from '../../graphql/client'
import { CalculateShippingAmount } from '../../graphql/queries/Collect'
import CustomerAddressForm from './CustomerAddressForm'
import { FormErrors } from '../shared/FormField'

export const ADULT_CONSENT_ID = 'adult-consent'

export const FieldsWrap = styled.div`
  display: grid;
  row-gap: 1.2em;
`

export const FieldSectionHeader = styled.h3`
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: var(--color-primary);
  display: flex;
  align-items: center;

  svg {
    color: #686c88;
    margin-right: 0.5em;
  }
`

function StripeForm({ recipient, pendingOrder, campaignMessageId, onSubmit }) {
  const formikRef = useRef()
  const externalCustomer = recipient.externalCustomer
  const [cardComplete, setCardComplete] = useState(false)
  const [shippingAddress, setShippingAddress] = useState(
    externalCustomer?.defaultShippingAddress
  )
  const [customerDetails, setCustomerDetails] = useState({
    name: recipient.name || externalCustomer?.name,
    email: recipient.email || externalCustomer?.email,
  })
  const [shippingTotal, setShippingTotal] = useState(pendingOrder?.shippingTotal)
  const [totalAmount, setTotalAmount] = useState(pendingOrder?.totalAmount)
  const [salesTaxAmount, setSalesTaxAmount] = useState(pendingOrder?.salesTaxAmount)
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const customer = recipient.customer

  const initialData = {
    acceptTerms: false,
    sameAsBilling: true,
    adultConsent: false,
  }

  const doTokenize = (values, actions) => {
    if (!cardComplete) return false

    tokenizeCard(values, actions, elements, stripe)
      .then((result) => {
        doSubmit(result.token.id, values, actions)
      })
      .catch((err) => {
        // actions.setFieldError('card', )
        console.log('stripe error', err)
      })
  }

  const calculateShipping = async (address) => {
    setLoading(true)
    const { zip, state } = address

    const result = await mutation(CalculateShippingAmount, {
      zip,
      state,
      orderId: pendingOrder.id,
      customerId: recipient.customer.publicId,
    })

    const {
      shippingAmount,
      totalAmount,
      salesTaxAmount,
    } = result.data.calculateShippingAmount
    setShippingTotal(shippingAmount)
    setTotalAmount(totalAmount)
    setSalesTaxAmount(salesTaxAmount)
    setLoading(false)
  }

  const validateFields = (values) => {
    const errs = {}

    if (!shippingAddress) {
      errs.shippingAddress = 'is required'
    }

    if (!values.acceptTerms) {
      errs.acceptTerms = 'Please agree to the terms of the purchase agreement.'
    }

    if (!cardComplete) errs.card = 'Please enter your credit card information.'

    if (customer.adultConsentRequired) {
      if (!values.adultConsent) {
        errs.adultConsent = 'Please acknowledge you are at least 21 years old.'
      }
    }
    return errs
  }

  const handleSavedAddress = (address, { name, email }) => {
    calculateShipping(address)
    setCustomerDetails({ name, email })
    setShippingAddress(address)
  }

  /**
   * Send the data to the server
   * @param {String} token stripe card token
   * @param {Object} form
   * @param {Object} actions
   */
  function doSubmit(token, form, actions) {
    const { name, email } = customerDetails
    const payload = {
      token,
      campaignMessageId,
      recipientId: recipient.publicId,
      attributes: { name, email },
    }
    onSubmit(payload, form, actions)
  }

  const cardChanged = (formik, { brand, complete, error }) => {
    if (error) {
      formik.setFieldErorr('card', error.message)
      return
    }
    if (complete) {
      setCardComplete(true)
    }
  }

  return (
    <div>
      <CustomerAddressForm recipient={recipient} onSaveAddress={handleSavedAddress} />
      <Formik
        onSubmit={doTokenize}
        validate={validateFields}
        initialValues={initialData}
        validateOnBlur
        innerRef={formikRef}
        validateOnChange={false}
      >
        {(props) => {
          const { errors } = props
          return (
            <Form className="collect-form">
              <FieldsWrap>
                <div style={{ display: 'grid', rowGap: '.5em' }}>
                  <FieldSectionHeader style={{ justifyContent: 'space-between' }}>
                    <CreditCard size={16} />
                    <div>3. Payment Details</div>
                    <div style={{ marginLeft: 'auto' }}>
                      <StripeLogo width={120} />
                    </div>
                  </FieldSectionHeader>

                  <CardElement
                    onChange={cardChanged.bind(null, props)}
                    options={{
                      iconStyle: 'solid',
                      style: {
                        base: {
                          backgroundColor: 'var(--color-field-bg)',
                          fontFamily: 'Inter,sans-serif',
                          fontSize: 14,
                          fontWeight: 400,
                          '::placeholder': {
                            fontSize: 14,
                            opacity: 1,
                            color: '#a8a8a8',
                          },
                        },
                        invalid: {
                          color: '#D13939',
                        },
                      },
                    }}
                    tabIndex={2}
                  />
                  {errors?.card && <FormErrors>{errors.card}</FormErrors>}
                </div>

                {pendingOrder && (
                  <div>
                    <OrderDetails
                      loading={loading}
                      totalAmount={totalAmount}
                      shippingTotal={shippingTotal}
                      salesTaxAmount={salesTaxAmount}
                      pendingOrder={pendingOrder}
                    />
                  </div>
                )}
                <div style={{ display: 'grid', rowGap: '.5em' }}>
                  <Checkbox
                    name="acceptTerms"
                    style={{ marginBottom: 6 }}
                    label="I have read and accept the terms of the purchase agreement below"
                    labelStyle={{
                      alignItems: 'start',
                      fontWeight: 600,
                      lineHeight: '1.5em',
                    }}
                  />

                  {customer?.adultConsentRequired && (
                    <Checkbox
                      style={{ marginBottom: 0 }}
                      id={ADULT_CONSENT_ID}
                      name="adultConsent"
                      label="I confirm that I am at least 21 years of age"
                    />
                  )}
                </div>

                <SubmitButton
                  disabled={loading}
                  text={pendingOrder ? 'Complete Purchase' : 'Update Customer Details'}
                  type="submit"
                />

                {pendingOrder && (
                  <FormNote className="note">
                    Your information will be stored securely so you can order via SMS in the
                    future.
                  </FormNote>
                )}
              </FieldsWrap>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export const textField = (name, label, type = 'text', opts) => {
  const required = opts.required === null ? true : opts.required
  return (
    <div className="vv-field">
      <TextField
        name={name}
        type={type}
        className={name}
        hideErrorMessage
        placeholder={label}
        required={required}
        rootStyle={{ marginBottom: 0 }}
        {...opts}
      />
    </div>
  )
}

export default StripeForm
