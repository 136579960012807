import React, { useState } from 'react'
import { useField } from 'formik'
import produce from 'immer'
import groupby from 'lodash.groupby'

import Loader from '../shared/Loader'
import { useQuery } from '../../graphql/client'
import Checkbox from '../shared/Checkbox'
import { FetchExternalObjects } from '../../graphql/queries/ExternalObjects'
import { FormField, Grid, SimpleSelect } from '../shared'

const confirmationOpts = [
  { value: 'none', text: 'None' },
  { value: 'delivery', text: 'Delivery' },
  { value: 'signature', text: 'Signature' },
  { value: 'adult_signature', text: 'Adult Signature' },
  { value: 'direct_signature', text: 'Direct Signature (Fedex only)' },
]

const carrierFromCode = (code) => {
  switch (code) {
    case 'stamps_com':
      return 'USPS'
    case 'ups_walleted':
      return 'UPS'
    default:
      return code
  }
}

export default function ShipstationConfiguration({ fields, authorization }) {
  const [field, meta, helpers] = useField('services')
  const [state, setState] = useState((fields && fields.services) || [])
  const { loading, data } = useQuery(FetchExternalObjects, {
    provider: 'shipstation',
    objectTypes: ['carrier', 'carrier_package', 'carrier_service'],
  })

  if (loading) return <Loader />

  const external = data.externalObjects
  const carriers = external.filter((d) => d.objectType === 'carrier')
  const packages = external.filter((d) => d.objectType === 'carrier_package')
  const services = external.filter((d) => d.objectType === 'carrier_service')
  const grouped = groupby(services, 'meta.carrierCode')

  const handleChecked = (val, checked, e) => {
    let res = null
    if (checked) {
      res = produce(state, (draft) => {
        draft.push(val)
      })
    } else {
      res = produce(state, (draft) => {
        const idx = state.findIndex((i) => i === val)
        draft.splice(idx, 1)
      })
    }

    helpers.setValue(res)
    setState(res)
  }

  return (
    <div>
      <p>
        Choose the confirmation option you want to include when calculating shipping rates.
      </p>
      <FormField name="confirmation" label="Confirmation type (optional)">
        <SimpleSelect name="confirmation" items={confirmationOpts} />
      </FormField>

      <hr />

      <label>Carrier Services</label>
      <p>
        Choose the carrier services you want Replii to consider when using dynamic shipping.
      </p>
      {Object.entries(grouped).map(([carrierCode, services]) => {
        return (
          <div key={`${carrierCode}-services`}>
            <h4>{carrierFromCode(carrierCode)}</h4>
            <Grid cols={2}>
              {services.map((svc) => (
                <Checkbox
                  labelStyle={{ fontWeight: 'normal' }}
                  key={svc.externalId}
                  name="services"
                  checked={state && state.includes(svc.externalId)}
                  label={`${svc.meta.name}`}
                  onCheck={handleChecked.bind(null, svc.externalId)}
                />
              ))}
            </Grid>
          </div>
        )
      })}
    </div>
  )
}
