import React, { useState } from 'react'
import ReactDOM from 'react-dom/server'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import validator from 'email-validator'
import * as yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import PhoneField from './PhoneField'
import TextField from './TextField'
import { SmallButton, UppercaseButton } from './Button'
import Api from '../../lib/Api'
import Checkbox from './Checkbox'
import { disclaimerText } from '../Collect/Disclaimer'

const Schema = yup.object().shape({
  phone: yup.string().required('Please enter a valid US phone number.'),
  disclaimer: yup.boolean().oneOf([true], 'You must agree to the terms of service.'),
})

const WidgetBody = styled.div`
  margin-bottom: 10px;
`

const FormBody = styled.div`
  display: grid;
  row-gap: 1em;
`

const DEFAULT_BUTTON_TEXT = 'Subscribe'

export default function CollectPhone({ widget, onSuccess, onClose }) {
  const [{ success, alreadyExists, error }, setState] = useState({
    success: false,
    alreadyExists: false,
    error: null,
  })
  const { emailRequirement, campaignListId, bodyHtml, buttonText } = widget
  const isEmailRequired = emailRequirement && emailRequirement === 'required'
  const btnText = buttonText && buttonText !== '' ? buttonText : DEFAULT_BUTTON_TEXT

  const disclaimer = ReactDOM.renderToString(disclaimerText(widget.customerName))

  const submit = (values, formik) => {
    const params = { ...values, disclaimer, source_widget_id: widget.publicId }
    Api.Utils.post('/api/recipients', params)
      .set('X-Requested-On', window.location.href)
      .then((res) => {
        formik.setSubmitting(false)
        setState((state) => ({ ...state, success: true }))
        onSuccess && onSuccess(res.body)
      })
      .catch((err) => {
        const { status, body } = err.response
        formik.setSubmitting(false)
        if (status === 409) {
          // user already exists
          setState((state) => ({ ...state, alreadyExists: true }))
          onSuccess && onSuccess(body)
        } else {
          setState((state) => ({ ...state, error: body.error }))
        }
      })
  }

  const validate = (values) => {
    const errors = {}
    const { phone } = values
    const parsed = parsePhoneNumberFromString(phone, 'US')
    if (parsed && !parsed.isValid()) errors.phone = 'Please enter a valid US phone number.'

    if (emailRequirement && isEmailRequired) {
      if (!values.email || values.email === '' || !validator.validate(values.email)) {
        errors.email = 'Please provide a valid email address.'
      }
    }

    return errors
  }

  if (success) {
    return (
      <div>
        <PostRegistration onClose={onClose} />
      </div>
    )
  } else if (alreadyExists) {
    return (
      <div>
        <AlreadyExists />
      </div>
    )
  }

  return (
    <div>
      {(!success || !alreadyExists) && (
        <WidgetBody
          className="widget-body"
          dangerouslySetInnerHTML={{ __html: bodyHtml }}
        ></WidgetBody>
      )}
      <div className="widget-form">
        <Formik
          onSubmit={submit}
          initialValues={{
            phone: '',
            pending_list_id: campaignListId,
            email: '',
            disclaimer: false,
          }}
          validationSchema={Schema}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(props) => {
            return (
              <Form>
                <FormBody>
                  <PhoneField
                    label="Mobile Number"
                    name="phone"
                    error={error}
                    required
                    placeholder="(111) 222-3344"
                    onChange={(e) => props.setFieldValue('phone', e.target.value)}
                  />
                  {emailRequirement && (
                    <TextField
                      label="Email Address"
                      name="email"
                      placeholder="Email Address"
                      type="email"
                    />
                  )}
                  <Checkbox
                    name="disclaimer"
                    label="I agree to the terms of service described below."
                    labelStyle={{
                      display: 'grid',
                      fontWeight: 400,
                      gridTemplateColumns: '20px auto',
                      columnGap: '0.4em',
                    }}
                    required
                  />
                  <UppercaseButton
                    className="__replii-collect-submit-btn"
                    loading={props.isSubmitting}
                    text={btnText}
                  />
                </FormBody>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

function AlreadyExists() {
  return <p style={{ marginTop: '1em' }}>You're already on the list!</p>
}

function PostRegistration({ onClose }) {
  return (
    <div>
      <p style={{ color: '#238a30', fontWeight: 500 }}>
        Great! We're sending you a confirmation message now.
      </p>

      <SmallButton onClick={onClose} text="Close" />
    </div>
  )
}
