import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkWrap = styled(Link)`
  display: inline-flex;
  align-items: center;
  line-height: 1em;
`

export default function IconLink({ to, text, icon, ...rest }) {
  const Icon = require(`react-feather/dist/icons/${icon}`).default
  return (
    <LinkWrap to={to} {...rest}>
      <span style={{ marginRight: 2 }}>{<Icon size={16} />}</span>
      {text}
    </LinkWrap>
  )
}
