import { gql } from 'apollo-boost'

export const WidgetFragment = gql`
  fragment WidgetFragment on CustomerWidget {
    id
    widgetType
    emailRequirement
    title
    bodyHtml
    imageUrl
    campaignListId
    disclaimer
    domTargetSelector
    publicId
    delayInSeconds
    customerName
    buttonText
  }
`

export const CreateWidget = gql`
  mutation CreateWidget($attributes: CustomerWidgetInput!) {
    createCustomerWidget(input: { attributes: $attributes }) {
      widget {
        ...WidgetFragment
      }
    }
  }
  ${WidgetFragment}
`

export const UpdateWidget = gql`
  mutation UpdateWidget($id: ID!, $attributes: CustomerWidgetInput!) {
    updateCustomerWidget(input: { attributes: $attributes, id: $id }) {
      widget {
        ...WidgetFragment
      }
    }
  }
  ${WidgetFragment}
`

export const FetchWidgets = gql`
  query Widget {
    widgets {
      ...WidgetFragment
      campaignList {
        id
        name
      }
    }
  }
  ${WidgetFragment}
`

export const FetchWidgetById = gql`
  query Widget($id: ID!) {
    widget(id: $id) {
      ...WidgetFragment
      afterOptinMessage
    }
  }
  ${WidgetFragment}
`

export const FetchWidget = gql`
  query Widget($publicId: String!) {
    widget(publicId: $publicId) {
      ...WidgetFragment
    }
  }
  ${WidgetFragment}
`
