import request from 'superagent'

const HOST =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_HOST || 'https://api.replii.co'
    : 'http://localhost:3001'

const Api = {
  Utils: {
    post: (url, data, headers) =>
      request
        .post(HOST + url)
        .send(data)
        .accept('json')
        .type('json')
        .withCredentials(),
    get: (url, params) =>
      request
        .get(HOST + url)
        .query(params || {})
        .accept('json')
        .withCredentials(),
    patch: (url, data) =>
      request
        .patch(HOST + url)
        .send(data)
        .accept('json')
        .withCredentials(),
    delete: (url, data) =>
      request
        .delete(HOST + url)
        .send(data)
        .accept('json')
        .withCredentials(),
  },
}

export default Api
