const STATE_PREFIX = 'replii_'

export function setItem(key, value) {
  return window.localStorage.setItem(`${STATE_PREFIX}_${key}`, value)
}

export function getItem(key) {
  try {
    return window.localStorage.getItem(`${STATE_PREFIX}_${key}`)
  } catch (error) {
    console.log('error', error)
  }
}