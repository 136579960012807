import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'

import Label from './Label'
import FormNote from './FormNote'

const Textarea = styled.textarea`
  background-color: var(--color-field-bg);
  border: ${(props) =>
    props.error
      ? '1px solid var(--color-field-border-error)'
      : '1px solid var(--color-field-border)'};
  border-radius: 6px;
  outline: none;
  padding: 0.8em 0.8em;
  width: 100%;
  font-family: var(--font-family);
  box-shadow: none;
  display: block;
  transition: 0.3s all;

  &:active,
  &:focus {
    border: 1px solid #8fa7bd;
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px rgba(68, 155, 236, 0.25);
  }

  &:focus {
    outline: none;
    background-color: #f5f5f5;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #a8a8a8;
    opacity: 1;
  }
`
export default function TextAreaField(props) {
  const [field, meta] = useField(props.name)
  let classes = 'field text-field'
  if (meta.error) classes += ' error'
  return (
    <TextArea
      {...field}
      {...props}
      error={meta.error && meta.touched ? meta.error : null}
      className={classes}
    />
  )
}

export function TextArea({ className, label, note, error, belowNote, ...props }) {
  return (
    <div className={className}>
      <Label>{label}</Label>
      <div className="field-row">
        <div className="field-wrap">
          <Textarea {...props} />
        </div>
        {note && (
          <FormNote className="note">
            <div className="note-body">{note}</div>
            {belowNote && <div className="below-note">{belowNote}</div>}
          </FormNote>
        )}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  )
}
