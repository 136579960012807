import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { useQuery } from '../../../graphql/client'
import { FetchContactsByList } from '../../../graphql/queries/CampaignLists'
import { PageHeader, Loader, Header, Card } from '../../shared'
import ContactsTable from '../../Contacts/ContactsTable'
import LinkButton from '../../shared/LinkButton'

export default function ShowCampaignList() {
  const { id } = useParams()
  const { loading, data } = useQuery(FetchContactsByList, {
    campaignListId: id,
  })

  if (loading) return <Loader />
  const contacts = data.contacts

  return (
    <div>
      <PageHeader
        text={<Link to={`/lists`}>Lists</Link>}
        breadcrumb={`${data.list.name} - Contacts`}
      />
      <Card>
        <div style={{ textAlign: 'right' }}>
          <LinkButton to={`/lists/${id}/contacts/new`}>New Contact</LinkButton>
        </div>
        <ContactsTable
          contacts={contacts}
          actions={(item) => (
            <div>
              <Link to={`/lists/${id}/contacts/${item.id}/edit`}>Edit</Link>
            </div>
          )}
        />
      </Card>
    </div>
  )
}
