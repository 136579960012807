import React from 'react'
import styled from 'styled-components'
import { CheckCircle } from 'react-feather'
import Grid from './Grid'

const Title = styled.div`
  color: rgb(6, 95, 70);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1em;
`

const Body = styled.div`
  color: rgb(4, 120, 87);
  font-weight: normal;
`

const SuccessMessageContainer = styled.div`
  padding: 1em;
  background-color: rgb(236, 253, 245);
  border-radius: 8px;
`

const DismissButton = styled(Title)`
  cursor: pointer;
  display: inline-block;
`

export default function SuccessMessage({ title, message, showDismiss, onDismiss }) {
  return (
    <SuccessMessageContainer>
      <Grid cols={2} columnGap="1em" style={{ gridTemplateColumns: '20px auto' }}>
        <div>
          <CheckCircle stroke="white" fill="rgb(52, 211, 153)" />
        </div>
        <div>
          {title && <Title>{title}</Title>}
          {message && <Body>{message}</Body>}
          {showDismiss && (
            <div style={{ marginTop: '1em' }}>
              <DismissButton onClick={onDismiss}>Dismiss</DismissButton>
            </div>
          )}
        </div>
      </Grid>
    </SuccessMessageContainer>
  )
}
