import React from 'react'
import PropTypes from 'prop-types'

export default function Header({ size = 'h2', content, children, icon, ...rest }) {
  const Comp = size
  const props = {
    className: 'header',
    style: {
      display: 'flex',
      alignItems: 'center',
    },
    ...rest,
  }

  if (content) {
    return (
      <Comp {...props}>
        <span style={{ marginRight: '.3em' }}>
          {icon && React.cloneElement(icon, { size: 16, color: '#4B5C6B' })}
        </span>
        {content}
      </Comp>
    )
  }

  return <Comp {...props}>{children}</Comp>
}

Header.propTypes = {
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  children: PropTypes.array,
  content: PropTypes.string,
}
