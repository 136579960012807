import React, { useState } from 'react'
import { tomorrowNight as codeTheme } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import SyntaxHighlighter from 'react-syntax-highlighter'
import copy from 'copy-to-clipboard'

import { SmallButton } from '../../shared/Button'

const EMBED_HOST =
  process.env.NODE_ENV === 'production' ? '//sdk.replii.co' : 'http://localhost:8080/static'

export const pluginCode = ({ publicId }) => {
  return `
<script type="text/javascript">
  (function(id) {
    var js, fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById(id)) return;
    js = document.createElement('script');
    js.id = id; js.async = true; js.defer = true;
    js.src = '${EMBED_HOST}/sdk.js';
    js.onload = function() { 
      try {
        window.RepliiSDK.loadWidget('${publicId}');
      } catch (error)    {
        console.error('Replii loading failed', error);
      }
    }
    fjs.parentNode.insertBefore(js, fjs);
  }('replii-jssdk'));
</script>
`
}

const codeStyle = { padding: '1.5em', cursor: 'pointer' }

const CodeBlock = ({ widget }) => {
  const [buttonText, setButtonText] = useState('Copy Code')
  const renderedCode = pluginCode(widget)
  const copyCode = () => {
    copy(renderedCode)
    setButtonText('Copied!')
  }

  return (
    <div style={{ transition: 0.3, maxWidth: 800 }}>
      <div style={{ marginTop: 25, position: 'relative' }}>
        <SmallButton
          color="secondary"
          onClick={copyCode}
          icon="copy"
          style={{ position: 'absolute', top: 15, right: 15 }}
          text={buttonText}
        />

        <SyntaxHighlighter
          language="html"
          onClick={copyCode}
          style={codeTheme}
          wrapLines
          customStyle={codeStyle}
        >
          {renderedCode}
        </SyntaxHighlighter>
      </div>
    </div>
  )
}

export default CodeBlock
