import React, { useState } from 'react'
import styled from 'styled-components'

const DisclaimerRoot = styled.div`
  font-size: 11px;
  line-height: 1.5em;
  color: #252525;

  a {
    color: #7093f9;
  }
`

export const disclaimerText = (name) => (
  <>
    <p>
      You agree to receive recurring automated promotional and personalized marketing text
      (e.g. SMS and MMS) messages from {name} to the mobile telephone number you provide.
      Consent to receive automated marketing text messages is not a condition of any
      purchase. Messaging &amp; data rates may apply from your cellular service provider.
        If you provide your email address, you consent to receive automated email marketing messages from {name}.
    </p>

    <p>
      <strong>Unsubscribing</strong>
      <br />
      To stop receiving text messages, reply to any message with STOP, UNSUBSCRIBE, UNSUB,
      CANCEL, QUIT, or END. We will send one additional confirmation message after receiving
      an unsubscribe request to confirm your subscription cancellation. You acknowledge that
      attempting to unsubscribe by texting any other word than those listed here is not
      considered a valid unsubscribe request and will not be honored as such, and you agree
      that {name} will not be held liable for any invalid unsubscribe requests. To unsubscribe from email marketing
      communications, click the "unsubscribe" link at the bottom of any marketing email.
    </p>
  </>
)

export default function Disclaimer({ name, style }) {
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  return (
    <DisclaimerRoot id="replii-disclaimer" className="replii-disclaimer" style={style}>
      <p style={{ fontSize: 12, marginTop: 0 }}>
        By signing up you agree to the{' '}
        <a href="#" onClick={() => setShowDisclaimer(!showDisclaimer)}>
          terms of service
        </a>
        .
      </p>
      {showDisclaimer && disclaimerText(name)}
    </DisclaimerRoot>
  )
}
