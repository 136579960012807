import { mutation } from '../../graphql/client'
import {
  UpdateApiKeyAuthorization,
  CreatePrivateShopifyShop,
} from '../../graphql/queries/Integrations'

export const updateApiKeyAuthorization = (provider, values) => {
  return mutation(UpdateApiKeyAuthorization, {
    provider,
    ...values,
  })
}

export const createPrivateShop = (values) => mutation(CreatePrivateShopifyShop, values)
